import React, { useState, useEffect } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  createReviewModalOpenState,
  authUserState,
  authUserIdState,
  isAdminState,
  signUpModalOpenState,
  reviewSearchState,
} from "../../recoil/atoms";
import {
  AppBar,
  Avatar,
  Button,
  Toolbar,
  Typography,
  Grid,
  Menu,
  MenuItem,
  IconButton,
  Hidden,
  TextField,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
// import SearchIcon from "@material-ui/icons/Search";
import Skeleton from "@material-ui/lab/Skeleton";
import MenuIcon from "@material-ui/icons/Menu";
import SettingsIcon from "@material-ui/icons/Settings";
import HelpIcon from "@material-ui/icons/Help";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import DashboardIcon from "@material-ui/icons/Dashboard";
import CategoryDrawer from "./CategoryDrawer";
import MobileDrawer from "./MobileDrawer";
import CircularProgress from "@material-ui/core/CircularProgress";
import weWearLogo from "../../assets/images/logo/we-wear_logo_ww_primary_blush-black.png";

import { Auth, Hub, API, graphqlOperation, Storage } from "aws-amplify";
import { getUser, listReviews, listUsers } from "../../graphql/queries";

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: "#FFFFFF",
    paddingTop: 5,
    paddingBottom: 5,
    borderBottom: "solid 1px #000000",
    zIndex: theme.zIndex.drawer + 1,
  },
  logo: { height: 24 },
  toolbar: theme.mixins.toolbar,
  link: {
    textDecoration: "none",
    color: theme.palette.text.primary,
  },
  profileLink: {
    textDecoration: "none",
    color: theme.palette.text.primary,
    borderRadius: "40px",
    padding: 6,
    "&:hover": {
      backgroundColor: "#DFC6BF",
      color: "white",
    },
  },
  navItem: {
    fontWeight: 600,
    fontSize: "15px",
    "&:hover": {
      fontWeight: "bold",
      borderBottom: "3px solid #DFC6BF",
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  active: {
    fontWeight: "bold",
    borderBottom: "3px solid #DFC6BF",
  },
  navIcon: { marginRight: 8, color: "#DFC6BF" },
}));

const SiteNav = () => {
  const classes = useStyles();
  const history = useHistory();
  const [categoryDrawerOpen, setCategoryDrawerOpen] = useState(false);
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loading, setLoading] = useState(false);
  const open = Boolean(anchorEl);
  const setCreateReviewModalOpen = useSetRecoilState(
    createReviewModalOpenState
  );
  const setSignUpModalOpen = useSetRecoilState(signUpModalOpenState);
  const [authUser, setAuthUser] = useRecoilState(authUserState);
  const [authUserId, setAuthUserId] = useRecoilState(authUserIdState);
  const [isAdmin, setIsAdmin] = useRecoilState(isAdminState);
  const setSearch = useSetRecoilState(reviewSearchState);

  const [resultsOpen, setResultsOpen] = useState(false);
  const [results, setResults] = useState([]);
  const resultsLoading = open && results.length === 0;

  const onChangeHandle = async (value) => {
    if (!value) {
      setResultsOpen(false);
      return;
    }

    try {
      const userData = await API.graphql({
        query: listUsers,
        authMode: "API_KEY",
        variables: {
          filter: {
            searchField: { contains: value },
          },
        },
      });

      const usersArray = userData.data.listUsers.items;
      const signedUsersArray = await Promise.all(
        usersArray.map(async (item) => {
          const signedUrl = await Storage.get(item.profilePicture);
          item.imageUrl = signedUrl;
          return item;
        })
      );

      const reviewData = await API.graphql({
        query: listReviews,
        authMode: "API_KEY",
        variables: {
          filter: {
            searchField: { contains: value },
          },
        },
      });
      const reviewsArray = reviewData.data.listReviews.items;
      const signedReviewsArray = await Promise.all(
        reviewsArray.map(async (item) => {
          const signedUrls = await Promise.all(
            item.garmentImages.map(async (image) => {
              const signedUrl = await Storage.get(image);
              return signedUrl;
            })
          );
          item.imageUrls = signedUrls;
          if (item.author.profilePicture) {
            const signedProfilePicture = await Storage.get(
              item.author.profilePicture
            );
            item.author.profilePictureUrl = signedProfilePicture;
          }
          return item;
        })
      );

      setResults([...signedUsersArray, ...signedReviewsArray]);
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!resultsOpen) {
      setResults([]);
    }
  }, [resultsOpen]);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    Auth.signOut();
    history.push("/");
  };

  useEffect(() => {
    checkUser();
    checkAdminStatus();
    authListener();
  }, [authUserId]);

  // Check User
  // ==========================================================================
  const checkUser = async () => {
    setLoading(true);
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      setAuthUserId(currentUser.username);

      const dbUser = await API.graphql(
        graphqlOperation(getUser, { id: currentUser.username })
      );
      const userDetails = dbUser.data.getUser;

      const userPic = userDetails?.profilePicture;
      const signedPic = await Storage.get(userPic);

      userDetails.signedUrl = signedPic;

      setAuthUser(userDetails);
      setLoading(false);
    } catch (error) {
      if (error === "The user is not authenticated") {
        setLoading(false);
        return;
      }
      console.log("error checking user:", error);
      setLoading(false);
    }
  };

  // Check Admin Status
  // ==========================================================================
  const checkAdminStatus = async () => {
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      const adminStatus =
        currentUser.signInUserSession.idToken.payload["cognito:groups"];
      if (adminStatus?.includes("Admin")) {
        setIsAdmin(true);
      }
    } catch (error) {
      if (error === "The user is not authenticated") {
        return;
      }
      console.log("error checking admin status:", error);
    }
  };

  // Auth Listener
  // ==========================================================================
  const authListener = async () => {
    Hub.listen("auth", (data) => {
      switch (data.payload.event) {
        case "signOut":
          setAuthUser({});
          setAuthUserId("");
          setIsAdmin(false);
          break;
        case "signIn":
          setAuthUserId(data.payload.data.username);
          break;
        default:
          break;
      }
    });
  };

  return (
    <div>
      <AppBar position="relative" elevation={0} className={classes.appBar}>
        <Toolbar style={{ padding: "0 16px" }}>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid
              container
              justify="flex-start"
              alignItems="center"
              item
              xs={10}
              sm={2}
            >
              <Link to="/" className={classes.link}>
                <img
                  src={weWearLogo}
                  alt="WeWear logo"
                  className={classes.logo}
                />
              </Link>
            </Grid>
            <Hidden smDown>
              <Grid
                container
                item
                xs={9}
                sm
                justify="space-evenly"
                alignItems="center"
              >
                <Typography
                  className={classes.navItem}
                  style={{ cursor: "pointer" }}
                  variant="h6"
                  color="inherit"
                  noWrap
                  onClick={
                    authUserId
                      ? () => setCreateReviewModalOpen(true)
                      : () => setSignUpModalOpen(true)
                  }
                >
                  Post a Review
                </Typography>

                <Typography
                  className={classes.navItem}
                  style={{ cursor: "pointer" }}
                  variant="h6"
                  color="inherit"
                  noWrap
                  onClick={() => setCategoryDrawerOpen(!categoryDrawerOpen)}
                >
                  Browse by Category
                </Typography>

                <NavLink
                  activeClassName={classes.active}
                  to="/reviews"
                  className={classes.link}
                >
                  <Typography
                    className={classes.navItem}
                    variant="h6"
                    color="inherit"
                    noWrap
                  >
                    Browse Reviews
                  </Typography>
                </NavLink>

                <Grid item>
                  <Autocomplete
                    style={{ width: 200 }}
                    forcePopupIcon={false}
                    open={resultsOpen}
                    onOpen={() => {
                      setResultsOpen(true);
                    }}
                    onClose={() => {
                      setResultsOpen(false);
                    }}
                    getOptionSelected={(option, value) =>
                      option.searchField === value.searchField
                    }
                    getOptionLabel={(option) =>
                      option?.type === "Review"
                        ? `${option.garmentBrand} ${option.garmentName}`
                        : `${option.firstName} ${option.lastName}`
                    }
                    renderOption={(option) => {
                      return (
                        <>
                          {option?.type === "Review" ? (
                            <Grid
                              container
                              justify="center"
                              alignItems="flex-start"
                              onClick={() => {
                                setSearch(
                                  `${option.garmentBrand} ${option.garmentName}`
                                );
                                history.push("/reviews");
                              }}
                            >
                              <Grid item xs>
                                <Avatar
                                  alt={`${option.garmentBrand} ${option.garmentName}`}
                                  src={option.imageUrls[0]}
                                  style={{ height: 30, width: 30 }}
                                />
                              </Grid>
                              <Grid item xs>
                                <Typography variant="subtitle1" noWrap>
                                  {`${option.garmentBrand} ${option.garmentName}`}
                                </Typography>
                              </Grid>
                            </Grid>
                          ) : (
                            <Link
                              to={`/user/show/${option.id}`}
                              style={{ textDecoration: "none", color: "black" }}
                            >
                              <Grid
                                container
                                justify="center"
                                alignItems="flex-start"
                              >
                                <Grid item xs>
                                  <Avatar
                                    alt={`${option?.firstName} ${option?.lastName}`}
                                    src={option.imageUrl}
                                    style={{ height: 30, width: 30 }}
                                  />
                                </Grid>
                                <Grid item xs>
                                  <Typography
                                    variant="subtitle1"
                                    style={{ paddingLeft: 4 }}
                                    noWrap
                                  >
                                    {`${option?.firstName} ${option?.lastName}`}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Link>
                          )}
                        </>
                      );
                    }}
                    options={results}
                    loading={resultsLoading}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Search"
                        margin="dense"
                        variant="outlined"
                        onChange={(ev) => {
                          if (
                            ev.target.value !== "" ||
                            ev.target.value !== null
                          ) {
                            onChangeHandle(ev.target.value);
                          }
                        }}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>

                {authUserId ? (
                  <div>
                    {loading ? (
                      <Grid container justify="center" alignItems="center">
                        <Skeleton variant="circle" width={30} height={30} />
                        <Skeleton
                          variant="text"
                          width={30}
                          style={{ margin: "0 6px" }}
                        />
                        <Skeleton variant="circle" width={30} height={30} />
                      </Grid>
                    ) : (
                      <Grid container justify="center" alignItems="center">
                        <NavLink to="/profile" className={classes.profileLink}>
                          <Grid container justify="center" alignItems="center">
                            <Avatar
                              alt={`${authUser?.firstName} ${authUser?.lastName}`}
                              src={authUser.signedUrl}
                              style={{ height: 30, width: 30 }}
                            />
                            <Typography
                              style={{ margin: "0 6px" }}
                              className={classes.navItem}
                              variant="h6"
                              color="inherit"
                              noWrap
                            >
                              {`${authUser.firstName}`}
                            </Typography>
                          </Grid>
                        </NavLink>
                        <IconButton
                          onClick={handleMenu}
                          style={{ backgroundColor: "#DFC6BF", padding: 4 }}
                        >
                          <SettingsIcon style={{ color: "white" }} />
                        </IconButton>
                      </Grid>
                    )}

                    <Menu
                      id="menu-appbar"
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      open={open}
                      onClose={handleClose}
                    >
                      <MenuItem onClick={handleClose}>
                        <NavLink to="/profile-edit" className={classes.link}>
                          <Grid container justify="center" alignItems="center">
                            <AccountCircleIcon className={classes.navIcon} />
                            <Typography
                              className={classes.navItem}
                              variant="h6"
                              color="inherit"
                              noWrap
                            >
                              Edit Profile
                            </Typography>
                          </Grid>
                        </NavLink>
                      </MenuItem>
                      {isAdmin && (
                        <MenuItem onClick={handleClose}>
                          <NavLink
                            className={classes.link}
                            to="/admin-dashboard"
                          >
                            <Grid
                              container
                              justify="center"
                              alignItems="center"
                            >
                              <DashboardIcon className={classes.navIcon} />
                              <Typography
                                className={classes.navItem}
                                variant="h6"
                                color="inherit"
                                noWrap
                              >
                                Admin Dashboard
                              </Typography>
                            </Grid>
                          </NavLink>
                        </MenuItem>
                      )}
                      <MenuItem onClick={handleClose}>
                        <NavLink className={classes.link} to="/privacy-policy">
                          <Grid container justify="center" alignItems="center">
                            <SettingsIcon className={classes.navIcon} />
                            <Typography
                              className={classes.navItem}
                              variant="h6"
                              color="inherit"
                              noWrap
                            >
                              Privacy Policy
                            </Typography>
                          </Grid>
                        </NavLink>
                      </MenuItem>
                      <MenuItem onClick={handleClose}>
                        <NavLink className={classes.link} to="/contact-us">
                          <Grid container justify="center" alignItems="center">
                            <HelpIcon className={classes.navIcon} />
                            <Typography
                              className={classes.navItem}
                              variant="h6"
                              color="inherit"
                              noWrap
                            >
                              Help & Support
                            </Typography>
                          </Grid>
                        </NavLink>
                      </MenuItem>
                      <MenuItem onClick={handleClose}>
                        <Button
                          onClick={handleSignOut}
                          variant="contained"
                          color="primary"
                          disableElevation
                          style={{ padding: "6px 32px", margin: "auto" }}
                        >
                          Sign out
                        </Button>
                      </MenuItem>
                    </Menu>
                  </div>
                ) : (
                  <NavLink
                    to="/login"
                    className={classes.link}
                    activeClassName={classes.active}
                  >
                    <Typography
                      className={classes.navItem}
                      variant="h6"
                      color="inherit"
                      noWrap
                    >
                      Sign in
                    </Typography>
                  </NavLink>
                )}
              </Grid>
            </Hidden>
            <Hidden mdUp>
              <Grid item>
                <Autocomplete
                  forcePopupIcon={false}
                  open={resultsOpen}
                  onOpen={() => {
                    setResultsOpen(true);
                  }}
                  onClose={() => {
                    setResultsOpen(false);
                  }}
                  getOptionSelected={(option, value) =>
                    option.searchField === value.searchField
                  }
                  getOptionLabel={(option) =>
                    option?.type === "Review"
                      ? `${option.garmentBrand} ${option.garmentName}`
                      : `${option.firstName} ${option.lastName}`
                  }
                  renderOption={(option) => {
                    return (
                      <>
                        {option?.type === "Review" ? (
                          <Grid
                            container
                            justify="center"
                            alignItems="flex-start"
                            onClick={() => {
                              setSearch(
                                `${option.garmentBrand} ${option.garmentName}`
                              );
                              history.push("/reviews");
                            }}
                          >
                            <Grid item xs>
                              <Avatar
                                alt={`${option.garmentBrand} ${option.garmentName}`}
                                src={option.imageUrls[0]}
                                style={{ height: 30, width: 30 }}
                              />
                            </Grid>
                            <Grid item xs>
                              <Typography variant="subtitle1" noWrap>
                                {`${option.garmentBrand} ${option.garmentName}`}
                              </Typography>
                            </Grid>
                          </Grid>
                        ) : (
                          <Link
                            to={`/user/show/${option.id}`}
                            style={{ textDecoration: "none", color: "black" }}
                          >
                            <Grid
                              container
                              justify="center"
                              alignItems="flex-start"
                            >
                              <Grid item xs>
                                <Avatar
                                  alt={`${option?.firstName} ${option?.lastName}`}
                                  src={option.imageUrl}
                                  style={{ height: 30, width: 30 }}
                                />
                              </Grid>
                              <Grid item xs>
                                <Typography
                                  variant="subtitle1"
                                  style={{ paddingLeft: 4 }}
                                  noWrap
                                >
                                  {`${option?.firstName} ${option?.lastName}`}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Link>
                        )}
                      </>
                    );
                  }}
                  options={results}
                  loading={resultsLoading}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Search"
                      margin="dense"
                      variant="outlined"
                      onChange={(ev) => {
                        if (
                          ev.target.value !== "" ||
                          ev.target.value !== null
                        ) {
                          onChangeHandle(ev.target.value);
                        }
                      }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
            </Hidden>
            <Hidden mdUp>
              <Grid
                container
                justify="flex-end"
                alignItems="center"
                item
                xs={2}
              >
                <IconButton
                  edge="start"
                  className={classes.menuButton}
                  color="inherit"
                  aria-label="menu"
                  onClick={() => setMobileDrawerOpen(!mobileDrawerOpen)}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
          </Grid>
        </Toolbar>
      </AppBar>

      <CategoryDrawer
        categoryDrawerOpen={categoryDrawerOpen}
        setCategoryDrawerOpen={setCategoryDrawerOpen}
      />

      <MobileDrawer
        user={authUser}
        authUserId={authUserId}
        isAdmin={isAdmin}
        mobileDrawerOpen={mobileDrawerOpen}
        setMobileDrawerOpen={setMobileDrawerOpen}
        categoryDrawerOpen={categoryDrawerOpen}
        setCategoryDrawerOpen={setCategoryDrawerOpen}
        handleSignOut={handleSignOut}
        userImage={authUser.signedUrl}
      />
    </div>
  );
};

export default SiteNav;
