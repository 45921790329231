import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Rating from "@material-ui/lab/Rating";
import StarBorderIcon from "@material-ui/icons/StarBorder";

const StyledRating = withStyles({
  iconFilled: {
    color: "#DFC6BF",
    // stroke: "#adadad",
  },
})(Rating);

const StarRatingDisplay = ({ rating }) => {
  return (
    <StyledRating
      name="rating"
      value={rating}
      precision={0.25}
      emptyIcon={<StarBorderIcon fontSize="inherit" />}
      size="large"
      readOnly
    />
  );
};

export default StarRatingDisplay;
