import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Button, TextField, Grid, Typography } from "@material-ui/core";
import { useSnackbar } from "notistack";

import Loading from "../../components/Loading/Loading";

import { API } from "aws-amplify";
import { createMessage } from "../../graphql/mutations";

const useStyles = makeStyles((theme) => ({
  field: { padding: 8 },
  button: {
    margin: theme.spacing(3),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    fontWeight: "bold",
    fontSize: 16,
  },
}));

const ContactFrom = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [validation, setValidation] = useState({});

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");

  // Validate
  //==============================================================================

  const validate = () => {
    const validationErrors = {};

    if (!name) {
      validationErrors.name = "Name is required";
    }

    if (!email) {
      validationErrors.email = "Email is required";
    }

    if (!subject) {
      validationErrors.subject = "Subject is required";
    }

    if (!body) {
      validationErrors.body = "A message is required";
    }

    if (Object.keys(validationErrors).length > 0) {
      setValidation(validationErrors);
      return false;
    }

    return true;
  };

  const handleClear = () => {
    setName("");
    setEmail("");
    setSubject("");
    setBody("");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!validate()) {
      setLoading(false);
      return;
    }

    try {
      const messageData = {
        name,
        email,
        subject,
        body,
        type: "Message",
      };

      await API.graphql({
        query: createMessage,
        variables: { input: messageData },
        authMode: "API_KEY",
      });
      enqueueSnackbar("Thank you for your message!  We will be in touch.", {
        variant: "success",
      });
      setValidation({});
      handleClear();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.warn(error);
      setValidation({ error: error.message });
      enqueueSnackbar(
        "There was an error, please review your form and try again.",
        {
          variant: "error",
        }
      );
    }
  };

  return (
    <form>
      <Grid container justify="center" alignItems="center">
        <Grid item xs></Grid>
        <Grid item xs={10} container>
          <Grid item xs={12} md={6} className={classes.field}>
            <Typography variant="subtitle1">Name</Typography>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Jane Smith"
              value={name}
              onChange={(event) => setName(event.target.value)}
              error={validation?.name?.length > 0 ? true : false}
              helperText={validation?.name || ""}
            />
          </Grid>
          <Grid item xs={12} md={6} className={classes.field}>
            <Typography variant="subtitle1">Email Address</Typography>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="jane@example.com"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              error={validation?.email?.length > 0 ? true : false}
              helperText={validation?.email || ""}
            />
          </Grid>
          <Grid item xs={12} className={classes.field}>
            <Typography variant="subtitle1">Subject</Typography>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Subject"
              value={subject}
              onChange={(event) => setSubject(event.target.value)}
              error={validation?.subject?.length > 0 ? true : false}
              helperText={validation?.subject || ""}
            />
          </Grid>
          <Grid item xs={12} className={classes.field}>
            <Typography variant="subtitle1">Message</Typography>
            <TextField
              fullWidth
              multiline
              rows={6}
              variant="outlined"
              placeholder="Message..."
              value={body}
              onChange={(event) => setBody(event.target.value)}
              error={validation?.body?.length > 0 ? true : false}
              helperText={validation?.body || ""}
            />
          </Grid>
          <Grid container justify="center">
            {loading ? (
              <Loading />
            ) : (
              <Button
                variant="outlined"
                onClick={handleSubmit}
                className={classes.button}
              >
                Submit
              </Button>
            )}
          </Grid>
        </Grid>
        <Grid item xs></Grid>
      </Grid>
    </form>
  );
};

export default ContactFrom;
