import React, { useState, useEffect } from "react";
import UserProfile from "../../components/UserProfile/UserProfile";
import Loading from "../../components/Loading/Loading";

import { API, Auth, graphqlOperation, Storage } from "aws-amplify";
import {
  getUser,
  reviewsByAuthorId,
  likesByUser,
  followersByUser,
  followingByUser,
} from "../../graphql/queries";

const Profile = () => {
  const [loading, setLoading] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [likes, setLikes] = useState([]);
  const [followers, setFollowers] = useState([]);
  const [following, setFollowing] = useState([]);
  const [user, setUser] = useState({});
  const [signedProfilePic, setSignedProfilePic] = useState("");

  useEffect(() => {
    getCurrentUser();
    getUserReviews();
    getUserLikes();
    getUserFollowers();
    getUserFollowing();
  }, []);

  // Get Current User
  // ====================================================================
  const getCurrentUser = async () => {
    setLoading(true);
    const currentUser = await Auth.currentAuthenticatedUser();
    try {
      const dbUser = await API.graphql(
        graphqlOperation(getUser, { id: currentUser.attributes.sub })
      );

      const userPic = dbUser.data.getUser.profilePicture;
      const signedPic = await Storage.get(userPic);
      setSignedProfilePic(signedPic);

      setUser(dbUser.data.getUser);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // Reviews
  // ====================================================================
  const getUserReviews = async () => {
    try {
      let currentUser = await Auth.currentAuthenticatedUser();
      const reviewData = await API.graphql({
        query: reviewsByAuthorId,
        variables: {
          authorId: currentUser.username,
        },
      });
      const reviewsArray = reviewData.data.reviewsByAuthorId.items;
      const signedReviewsArray = await Promise.all(
        reviewsArray.map(async (item) => {
          const signedUrls = await Promise.all(
            item.garmentImages.map(async (image) => {
              const signedUrl = await Storage.get(image);
              return signedUrl;
            })
          );
          item.imageUrls = signedUrls;
          if (item.author.profilePicture) {
            const signedProfilePicture = await Storage.get(
              item.author.profilePicture
            );
            item.author.profilePictureUrl = signedProfilePicture;
          }
          return item;
        })
      );
      setReviews(signedReviewsArray);
    } catch (error) {
      console.log("error", error);
    }
  };

  // Likes
  // ====================================================================
  const getUserLikes = async () => {
    try {
      let currentUser = await Auth.currentAuthenticatedUser();
      const likeData = await API.graphql({
        query: likesByUser,
        variables: {
          userId: currentUser.username,
        },
      });
      const likedReviewsArray = likeData.data.likesByUser.items;
      const signedLikedReviewsArray = await Promise.all(
        likedReviewsArray.map(async (item) => {
          const signedUrls = await Promise.all(
            item.review.garmentImages.map(async (image) => {
              const signedUrl = await Storage.get(image);
              return signedUrl;
            })
          );
          item.review.imageUrls = signedUrls;
          if (item.review.author.profilePicture) {
            const signedProfilePicture = await Storage.get(
              item.review.author.profilePicture
            );
            item.review.author.profilePictureUrl = signedProfilePicture;
          }
          return item.review;
        })
      );
      setLikes(signedLikedReviewsArray);
    } catch (error) {
      console.log("error", error);
    }
  };

  // Followers
  // ====================================================================
  const getUserFollowers = async () => {
    try {
      let currentUser = await Auth.currentAuthenticatedUser();
      const followersData = await API.graphql({
        query: followersByUser,
        variables: {
          followingId: currentUser.username,
        },
      });
      const followersArray = followersData.data.followersByUser.items;
      const signedFollowersArray = await Promise.all(
        followersArray.map(async (item) => {
          const signedUrl = await Storage.get(item.follower.profilePicture);
          item.follower.imageUrl = signedUrl;
          return item.follower;
        })
      );
      setFollowers(signedFollowersArray);
    } catch (error) {
      console.log("error", error);
    }
  };

  // Following
  // ====================================================================
  const getUserFollowing = async () => {
    try {
      let currentUser = await Auth.currentAuthenticatedUser();
      const followingData = await API.graphql({
        query: followingByUser,
        variables: {
          followerId: currentUser.username,
        },
      });
      const followingArray = followingData.data.followingByUser.items;
      const signedFollowingArray = await Promise.all(
        followingArray.map(async (item) => {
          const signedUrl = await Storage.get(item.following.profilePicture);
          item.following.imageUrl = signedUrl;
          return item.following;
        })
      );
      setFollowing(signedFollowingArray);
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <UserProfile
          user={user}
          signedProfilePic={signedProfilePic}
          reviews={reviews}
          likes={likes}
          followers={followers}
          following={following}
        />
      )}
    </div>
  );
};

export default Profile;
