import React from "react";
import MessageTable from "./MessageTable";

const Messages = () => {
  return (
    <div>
      <MessageTable />
    </div>
  );
};

export default Messages;
