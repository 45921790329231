import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  TableCell,
  TableHead,
  Table,
  TableBody,
  TableRow,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { useSnackbar } from "notistack";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import { API, graphqlOperation } from "aws-amplify";
import { deleteBrand } from "../../graphql/mutations";

const useStyles = makeStyles(() => ({
  table: {
    maxWidth: 700,
  },
}));

const BrandTable = ({
  brands,
  setBrands,
  loading,
  nextToken,
  setNextToken,
  nextNextToken,
  setNextNextToken,
  previousTokens,
  setPreviousTokens,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(false);
  const [brand, setBrand] = useState({});

  const handleNext = () => {
    setPreviousTokens((prev) => [...prev, nextToken]);
    setNextToken(nextNextToken);
    setNextNextToken(null);
  };

  const handlePrev = () => {
    setNextToken(previousTokens.pop());
    setPreviousTokens([...previousTokens]);
    setNextNextToken(null);
  };

  const handleOpen = (brand) => {
    setOpen(true);
    setBrand(brand);
  };

  const handleClose = () => {
    setOpen(false);
    setBrand({});
  };

  const handleDeleteBrand = async () => {
    setOpen(false);

    try {
      await API.graphql(
        graphqlOperation(deleteBrand, { input: { id: brand.id } })
      );

      const filterBrands = brands.filter((item) => item.id !== brand.id);
      setBrands(filterBrands);
      setBrand({});
      enqueueSnackbar(`Brand successfully deleted`);
    } catch (error) {
      console.log("error deleting brand", error);
    }
  };

  return (
    <>
      <Grid container justify="center" alignItems="center">
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell>Brand Name</TableCell>
              <TableCell align="center">Image</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {brands.map((item) => (
              <TableRow key={item.id}>
                <TableCell component="th" scope="row">
                  {item.name}
                </TableCell>
                <TableCell align="center">
                  <img
                    style={{ maxHeight: 100, maxWidth: 100, padding: 8 }}
                    src={item.imageUrl}
                    alt={`${item.name}`}
                  />
                </TableCell>
                <TableCell align="right">
                  <DeleteIcon onClick={() => handleOpen(item)} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Are you sure you want to Delete?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Deleting this brand will remove it from the site.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" onClick={handleClose}>
              Cancel
            </Button>
            <Button color="primary" onClick={() => handleDeleteBrand()}>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
      <Grid
        container
        justify="center"
        alignItems="center"
        style={{ padding: 16 }}
      >
        <Button
          disabled={previousTokens.length < 1 || loading}
          onClick={handlePrev}
        >
          <ArrowBackIosIcon />
        </Button>
        <Button disabled={!nextNextToken || loading} onClick={handleNext}>
          <ArrowForwardIosIcon />
        </Button>
      </Grid>
    </>
  );
};

export default BrandTable;
