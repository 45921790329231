import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Container,
  FormLabel,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    paddingLeft: 50,
    paddingRight: 50,
  },
  account: {
    cursor: "pointer",
    textDecoration: "underline",
  },
}));

const SignUp = ({
  firstName,
  setFirstName,
  lastName,
  setLastName,
  email,
  setEmail,
  password,
  setPassword,
  handleSignUp,
  setFormType,
  validation,
}) => {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Grid container justify="flex-start" alignItems="center">
          <Typography variant="h3">Sign up</Typography>
          <Typography component="p" variant="subtitle1">
            Already have an account?{" "}
            <span
              className={classes.account}
              onClick={() => setFormType("signIn")}
            >
              Sign In
            </span>
          </Typography>
        </Grid>
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormLabel component="legend">First Name</FormLabel>
              <TextField
                autoComplete="fname"
                name="firstName"
                variant="outlined"
                required
                fullWidth
                id="firstName"
                margin="normal"
                autoFocus
                value={firstName}
                onChange={(event) => setFirstName(event.target.value)}
                error={validation?.firstName?.length > 0 ? true : false}
                helperText={validation?.firstName || ""}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormLabel component="legend">Last Name</FormLabel>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="lastName"
                margin="normal"
                name="lastName"
                autoComplete="lname"
                value={lastName}
                onChange={(event) => setLastName(event.target.value)}
                error={validation?.lastName?.length > 0 ? true : false}
                helperText={validation?.lastName || ""}
              />
            </Grid>
            <Grid item xs={12}>
              <FormLabel component="legend">Email Address</FormLabel>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                name="email"
                margin="normal"
                autoComplete="email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                error={validation?.email?.length > 0 ? true : false}
                helperText={validation?.email || ""}
              />
            </Grid>
            <Grid item xs={12}>
              <FormLabel component="legend">Password</FormLabel>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                margin="normal"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                error={validation?.password?.length > 0 ? true : false}
                helperText={validation?.password || ""}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            variant="outlined"
            className={classes.submit}
            onClick={handleSignUp}
          >
            Sign Up
          </Button>
        </form>
      </div>
    </Container>
  );
};

export default SignUp;
