/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      name
      email
      subject
      body
      type
      createdAt
      updatedAt
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        subject
        body
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listMessagesByCreatedAt = /* GraphQL */ `
  query ListMessagesByCreatedAt(
    $type: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessagesByCreatedAt(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        email
        subject
        body
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $id: ID
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsers(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        firstName
        lastName
        email
        bio
        phone
        sizes
        bustSizes
        bodyType
        height
        brands
        userStyles
        userBirthday
        userLocation
        profilePicture
        acceptTerms
        searchField
        createdAt
        updatedAt
        reviews {
          items {
            id
          }
        }
      }
      nextToken
      scannedCount
      count
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      firstName
      lastName
      email
      bio
      phone
      sizes
      bustSizes
      bodyType
      height
      brands
      userStyles
      userBirthday
      userLocation
      profilePicture
      acceptTerms
      searchField
      createdAt
      updatedAt
      reviews {
        nextToken
        scannedCount
        count
      }
      following {
        nextToken
      }
    }
  }
`;
export const getReview = /* GraphQL */ `
  query GetReview($id: ID!) {
    getReview(id: $id) {
      id
      authorId
      garmentBrand
      garmentName
      garmentTitle
      garmentOccasion
      garmentType
      garmentDetails
      garmentRating
      garmentColor
      garmentSize
      garmentFit
      garmentImages
      searchField
      productId
      type
      createdAt
      updatedAt
      author {
        id
        firstName
        lastName
        email
        bio
        phone
        sizes
        bustSizes
        bodyType
        height
        brands
        userStyles
        userBirthday
        userLocation
        profilePicture
        acceptTerms
        searchField
        createdAt
        updatedAt
        reviews {
          items {
            id
          }
        }
        following {
          items {
            following {
              id
            }
          }
        }
      }
      likes {
        items {
          id
        }
      }
      photo {
        nextToken
      }
    }
  }
`;
export const listReviews = /* GraphQL */ `
  query ListReviews(
    $filter: ModelReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReviews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        authorId
        garmentBrand
        garmentName
        garmentTitle
        garmentOccasion
        garmentType
        garmentDetails
        garmentRating
        garmentColor
        garmentSize
        garmentFit
        garmentImages
        searchField
        productId
        type
        createdAt
        updatedAt
        likes {
          items {
            id
          }
        }
        author {
          id
          firstName
          lastName
          email
          bio
          phone
          sizes
          bustSizes
          bodyType
          height
          brands
          userStyles
          userBirthday
          userLocation
          profilePicture
          acceptTerms
          createdAt
          updatedAt
          reviews {
            items {
              id
            }
          }
          following {
            items {
              following {
                id
              }
            }
          }
        }
      }
      nextToken
      scannedCount
      count
    }
  }
`;
export const reviewsByAuthorId = /* GraphQL */ `
  query ReviewsByAuthorId(
    $authorId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reviewsByAuthorId(
      authorId: $authorId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        authorId
        garmentBrand
        garmentName
        garmentTitle
        garmentOccasion
        garmentType
        garmentDetails
        garmentRating
        garmentColor
        garmentSize
        garmentFit
        garmentImages
        searchField
        productId
        type
        createdAt
        updatedAt
        likes {
          items {
            id
          }
        }
        author {
          id
          firstName
          lastName
          email
          bio
          phone
          sizes
          bustSizes
          bodyType
          height
          brands
          userStyles
          userBirthday
          userLocation
          profilePicture
          acceptTerms
          createdAt
          updatedAt
          reviews {
            items {
              id
            }
          }
          following {
            items {
              following {
                id
              }
            }
          }
        }
      }
      nextToken
      scannedCount
      count
    }
  }
`;
export const listReviewsByCreatedAt = /* GraphQL */ `
  query ListReviewsByCreatedAt(
    $type: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReviewsByCreatedAt(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        authorId
        garmentBrand
        garmentName
        garmentTitle
        garmentOccasion
        garmentType
        garmentDetails
        garmentRating
        garmentColor
        garmentSize
        garmentFit
        garmentImages
        searchField
        productId
        type
        createdAt
        updatedAt
        likes {
          items {
            id
          }
        }
        author {
          id
          firstName
          lastName
          email
          bio
          phone
          sizes
          bustSizes
          bodyType
          height
          brands
          userStyles
          userBirthday
          userLocation
          profilePicture
          acceptTerms
          createdAt
          updatedAt
          reviews {
            items {
              id
            }
          }
          following {
            items {
              following {
                id
              }
            }
          }
        }
      }
      nextToken
      scannedCount
      count
    }
  }
`;
export const getReviewLike = /* GraphQL */ `
  query GetReviewLike($id: ID!) {
    getReviewLike(id: $id) {
      id
      reviewId
      userId
      createdAt
      updatedAt
      user {
        id
        firstName
        lastName
        email
        bio
        phone
        sizes
        bustSizes
        bodyType
        height
        brands
        userStyles
        userBirthday
        userLocation
        profilePicture
        acceptTerms
        searchField
        createdAt
        updatedAt
      }
      review {
        id
        authorId
        garmentBrand
        garmentName
        garmentTitle
        garmentOccasion
        garmentType
        garmentDetails
        garmentRating
        garmentColor
        garmentSize
        garmentFit
        garmentImages
        searchField
        productId
        type
        createdAt
        updatedAt
      }
    }
  }
`;
export const listReviewLikes = /* GraphQL */ `
  query ListReviewLikes(
    $filter: ModelReviewLikeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReviewLikes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        reviewId
        userId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const likesByUser = /* GraphQL */ `
  query LikesByUser(
    $userId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReviewLikeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    likesByUser(
      userId: $userId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        reviewId
        review {
          id
          authorId
          garmentBrand
          garmentName
          garmentTitle
          garmentOccasion
          garmentType
          garmentDetails
          garmentRating
          garmentColor
          garmentSize
          garmentImages
          searchField
          createdAt
          updatedAt
          likes {
            items {
              id
            }
          }
          author {
            id
            firstName
            lastName
            email
            bio
            phone
            sizes
            bustSizes
            bodyType
            height
            brands
            userStyles
            userBirthday
            userLocation
            profilePicture
            acceptTerms
            createdAt
            updatedAt
            reviews {
              items {
                id
              }
            }
            following {
              items {
                following {
                  id
                }
              }
            }
          }
        }
        userId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const likesByReview = /* GraphQL */ `
  query LikesByReview(
    $reviewId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelReviewLikeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    likesByReview(
      reviewId: $reviewId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        reviewId
        userId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFollowing = /* GraphQL */ `
  query GetFollowing($id: ID!) {
    getFollowing(id: $id) {
      id
      followerId
      followingId
      createdAt
      updatedAt
      follower {
        id
        firstName
        lastName
        email
        bio
        phone
        sizes
        bustSizes
        bodyType
        height
        brands
        userStyles
        userBirthday
        userLocation
        profilePicture
        acceptTerms
        searchField
        createdAt
        updatedAt
      }
      following {
        id
        firstName
        lastName
        email
        bio
        phone
        sizes
        bustSizes
        bodyType
        height
        brands
        userStyles
        userBirthday
        userLocation
        profilePicture
        acceptTerms
        searchField
        createdAt
        updatedAt
      }
    }
  }
`;
export const listFollowings = /* GraphQL */ `
  query ListFollowings(
    $filter: ModelFollowingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFollowings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        followerId
        followingId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const followingByUser = /* GraphQL */ `
  query FollowingByUser(
    $followerId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelFollowingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    followingByUser(
      followerId: $followerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        followerId
        followingId
        following {
          id
          firstName
          lastName
          email
          bio
          phone
          sizes
          bustSizes
          bodyType
          height
          brands
          userStyles
          userBirthday
          userLocation
          profilePicture
          acceptTerms
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const followersByUser = /* GraphQL */ `
  query FollowersByUser(
    $followingId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelFollowingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    followersByUser(
      followingId: $followingId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        followerId
        follower {
          id
          firstName
          lastName
          email
          bio
          phone
          sizes
          bustSizes
          bodyType
          height
          brands
          userStyles
          userBirthday
          userLocation
          profilePicture
          acceptTerms
          createdAt
          updatedAt
        }
        followingId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPhoto = /* GraphQL */ `
  query GetPhoto($id: ID!) {
    getPhoto(id: $id) {
      id
      reviewId
      image
      createdAt
      updatedAt
      review {
        id
        authorId
        garmentBrand
        garmentName
        garmentTitle
        garmentOccasion
        garmentType
        garmentDetails
        garmentRating
        garmentColor
        garmentSize
        garmentFit
        garmentImages
        searchField
        productId
        type
        createdAt
        updatedAt
      }
    }
  }
`;
export const listPhotos = /* GraphQL */ `
  query ListPhotos(
    $filter: ModelPhotoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPhotos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        reviewId
        image
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listPhotosByReview = /* GraphQL */ `
  query ListPhotosByReview(
    $reviewId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelPhotoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPhotosByReview(
      reviewId: $reviewId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        reviewId
        image
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      brand
      type
      name
      image
      link
      createdAt
      updatedAt
      reviews {
        nextToken
        scannedCount
        count
      }
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        brand
        type
        name
        image
        link
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBrand = /* GraphQL */ `
  query GetBrand($id: ID!) {
    getBrand(id: $id) {
      id
      type
      name
      image
      createdAt
      updatedAt
    }
  }
`;
export const listBrands = /* GraphQL */ `
  query ListBrands(
    $filter: ModelBrandFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBrands(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        name
        image
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBrandsAlphabetically = /* GraphQL */ `
  query GetBrandsAlphabetically(
    $type: String
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBrandFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getBrandsAlphabetically(
      type: $type
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        name
        image
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSuggestedBrand = /* GraphQL */ `
  query GetSuggestedBrand($id: ID!) {
    getSuggestedBrand(id: $id) {
      id
      authorId
      brandName
      type
      createdAt
      updatedAt
    }
  }
`;
export const listSuggestedBrands = /* GraphQL */ `
  query ListSuggestedBrands(
    $filter: ModelSuggestedBrandFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSuggestedBrands(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        authorId
        brandName
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listSuggestedBrandsByCreatedAt = /* GraphQL */ `
  query ListSuggestedBrandsByCreatedAt(
    $type: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSuggestedBrandFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSuggestedBrandsByCreatedAt(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        authorId
        brandName
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSuggestedProduct = /* GraphQL */ `
  query GetSuggestedProduct($id: ID!) {
    getSuggestedProduct(id: $id) {
      id
      authorId
      brandName
      productName
      type
      createdAt
      updatedAt
    }
  }
`;
export const listSuggestedProducts = /* GraphQL */ `
  query ListSuggestedProducts(
    $filter: ModelSuggestedProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSuggestedProducts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        authorId
        brandName
        productName
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listSuggestedProductsByCreatedAt = /* GraphQL */ `
  query ListSuggestedProductsByCreatedAt(
    $type: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSuggestedProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSuggestedProductsByCreatedAt(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        authorId
        brandName
        productName
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
