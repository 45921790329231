import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  TableCell,
  TableHead,
  Table,
  TableBody,
  TableRow,
  Typography,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Loading from "../../components/Loading/Loading";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { useSnackbar } from "notistack";

import { API, graphqlOperation } from "aws-amplify";
import { listMessagesByCreatedAt } from "../../graphql/queries";
import { deleteMessage } from "../../graphql/mutations";

const useStyles = makeStyles(() => ({
  table: {
    maxWidth: "95%",
  },
}));

const MessageTable = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [open, setOpen] = useState(false);
  const [viewOpen, setViewOpen] = useState(false);
  const [message, setMessage] = useState({});

  const [nextToken, setNextToken] = useState(undefined);
  const [nextNextToken, setNextNextToken] = useState();
  const [previousTokens, setPreviousTokens] = useState([]);

  const handleNext = () => {
    setPreviousTokens((prev) => [...prev, nextToken]);
    setNextToken(nextNextToken);
    setNextNextToken(null);
  };

  const handlePrev = () => {
    setNextToken(previousTokens.pop());
    setPreviousTokens([...previousTokens]);
    setNextNextToken(null);
  };

  // ===========================================================================
  const handleOpen = (message) => {
    setOpen(true);
    setMessage(message);
  };

  const handleClose = () => {
    setOpen(false);
    setMessage({});
  };

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        setLoading(true);

        const messageData = await API.graphql({
          query: listMessagesByCreatedAt,
          authMode: "AMAZON_COGNITO_USER_POOLS",
          variables: {
            type: "Message",
            sortDirection: "DESC",
            limit: 20,
            nextToken,
          },
        });
        setNextNextToken(messageData.data.listMessagesByCreatedAt.nextToken);
        const messagesArray = messageData.data.listMessagesByCreatedAt.items;

        setMessages(messagesArray);
        setLoading(false);
      } catch (error) {
        console.log("error fetching messages:", error);
        setLoading(false);
      }
    };

    fetchMessages();
  }, [nextToken]);

  // View Message
  // ===========================================================================
  const handleView = (message) => {
    setMessage(message);
    setViewOpen(true);
  };

  const handleViewClose = () => {
    setViewOpen(false);
    setMessage({});
  };

  // Delete Message
  // ===========================================================================
  const handleDeleteMessage = async () => {
    setOpen(false);

    try {
      await API.graphql(
        graphqlOperation(deleteMessage, { input: { id: message.id } })
      );

      const filterMessages = messages.filter((item) => item.id !== message.id);
      setMessages(filterMessages);
      setMessage({});
      enqueueSnackbar(`Message successfully deleted`);
    } catch (error) {
      console.log("error deleting message", error);
    }
  };

  // ===========================================================================
  return (
    <div>
      {loading ? (
        <Loading />
      ) : messages.length === 0 ? (
        <Card style={{ padding: 16, margin: 16 }}>
          <CardContent>
            <Typography variant="h6">There are no new Messages.</Typography>
            <Typography variant="body2" component="p">
              Check back later.
            </Typography>
          </CardContent>
        </Card>
      ) : (
        <>
          <Grid container justify="center" alignItems="center">
            <Table className={classes.table} size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell align="left">Email Address</TableCell>
                  <TableCell align="left">Subject</TableCell>
                  <TableCell align="left">Body</TableCell>
                  <TableCell align="right">Date Submitted</TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {messages.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>{item.name}</TableCell>
                    <TableCell align="left">{item.email}</TableCell>
                    <TableCell align="left">{item.subject}</TableCell>
                    <TableCell align="left">{item.body}</TableCell>
                    <TableCell align="right">
                      {format(new Date(item.createdAt), "MM/dd/yyyy")}
                    </TableCell>
                    <TableCell align="right">
                      <DeleteIcon onClick={() => handleOpen(item)} />
                    </TableCell>
                    <TableCell align="right">
                      <VisibilityIcon onClick={() => handleView(item)} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Dialog open={open} onClose={handleClose}>
              <DialogTitle>Are you sure you want to Delete?</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Deleting this message will remove it forever.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button color="primary" onClick={handleDeleteMessage}>
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              fullWidth
              maxWidth="sm"
              open={viewOpen}
              onClose={() => setViewOpen(false)}
            >
              <Grid container justify="center" alignItems="center">
                <DialogTitle>View Message</DialogTitle>
              </Grid>
              <Grid
                container
                justify="center"
                alignItems="flex-start"
                direction="column"
              >
                <DialogContent>
                  <Grid item>
                    <Typography variant="subtitle1">Name</Typography>
                    <DialogContentText>{message.name}</DialogContentText>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle1">Email Address</Typography>
                    <DialogContentText>{message.email}</DialogContentText>
                  </Grid>

                  <Grid item>
                    <Typography variant="subtitle1">Subject</Typography>
                    <DialogContentText>{message.subject}</DialogContentText>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle1">Message</Typography>
                    <DialogContentText>{message.body}</DialogContentText>
                  </Grid>
                </DialogContent>
              </Grid>

              <DialogActions>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleViewClose}
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{ padding: 16 }}
          >
            <Button
              disabled={previousTokens.length < 1 || loading}
              onClick={handlePrev}
            >
              <ArrowBackIosIcon />
            </Button>
            <Button disabled={!nextNextToken || loading} onClick={handleNext}>
              <ArrowForwardIosIcon />
            </Button>
          </Grid>
        </>
      )}
    </div>
  );
};

export default MessageTable;
