import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { isAdminState } from "../../recoil/atoms";

const AdminRoute = ({ component: Component, ...rest }) => {
  const isAdmin = useRecoilValue(isAdminState);

  return (
    <Route
      {...rest}
      render={(props) =>
        isAdmin ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: {
                from: props.location,
              },
            }}
          />
        )
      }
    />
  );
};

export default AdminRoute;
