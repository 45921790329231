/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      bio
      phone
      sizes
      bustSizes
      bodyType
      height
      brands
      userStyles
      userBirthday
      userLocation
      profilePicture
      acceptTerms
      searchField
      createdAt
      updatedAt
      reviews {
        nextToken
        scannedCount
        count
      }
      following {
        nextToken
      }
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      bio
      phone
      sizes
      bustSizes
      bodyType
      height
      brands
      userStyles
      userBirthday
      userLocation
      profilePicture
      acceptTerms
      searchField
      createdAt
      updatedAt
      reviews {
        nextToken
        scannedCount
        count
      }
      following {
        nextToken
      }
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      bio
      phone
      sizes
      bustSizes
      bodyType
      height
      brands
      userStyles
      userBirthday
      userLocation
      profilePicture
      acceptTerms
      searchField
      createdAt
      updatedAt
      reviews {
        nextToken
        scannedCount
        count
      }
      following {
        nextToken
      }
    }
  }
`;
export const createReview = /* GraphQL */ `
  mutation CreateReview(
    $input: CreateReviewInput!
    $condition: ModelReviewConditionInput
  ) {
    createReview(input: $input, condition: $condition) {
      id
      authorId
      garmentBrand
      garmentName
      garmentTitle
      garmentOccasion
      garmentType
      garmentDetails
      garmentRating
      garmentColor
      garmentSize
      garmentFit
      garmentImages
      searchField
      productId
      type
      createdAt
      updatedAt
      author {
        id
        firstName
        lastName
        email
        bio
        phone
        sizes
        bustSizes
        bodyType
        height
        brands
        userStyles
        userBirthday
        userLocation
        profilePicture
        acceptTerms
        searchField
        createdAt
        updatedAt
      }
      likes {
        nextToken
      }
      photo {
        nextToken
      }
    }
  }
`;
export const updateReview = /* GraphQL */ `
  mutation UpdateReview(
    $input: UpdateReviewInput!
    $condition: ModelReviewConditionInput
  ) {
    updateReview(input: $input, condition: $condition) {
      id
      authorId
      garmentBrand
      garmentName
      garmentTitle
      garmentOccasion
      garmentType
      garmentDetails
      garmentRating
      garmentColor
      garmentSize
      garmentFit
      garmentImages
      searchField
      productId
      type
      createdAt
      updatedAt
      author {
        id
        firstName
        lastName
        email
        bio
        phone
        sizes
        bustSizes
        bodyType
        height
        brands
        userStyles
        userBirthday
        userLocation
        profilePicture
        acceptTerms
        searchField
        createdAt
        updatedAt
      }
      likes {
        nextToken
      }
      photo {
        nextToken
      }
    }
  }
`;
export const deleteReview = /* GraphQL */ `
  mutation DeleteReview(
    $input: DeleteReviewInput!
    $condition: ModelReviewConditionInput
  ) {
    deleteReview(input: $input, condition: $condition) {
      id
      authorId
      garmentBrand
      garmentName
      garmentTitle
      garmentOccasion
      garmentType
      garmentDetails
      garmentRating
      garmentColor
      garmentSize
      garmentFit
      garmentImages
      searchField
      productId
      type
      createdAt
      updatedAt
      author {
        id
        firstName
        lastName
        email
        bio
        phone
        sizes
        bustSizes
        bodyType
        height
        brands
        userStyles
        userBirthday
        userLocation
        profilePicture
        acceptTerms
        searchField
        createdAt
        updatedAt
      }
      likes {
        nextToken
      }
      photo {
        nextToken
      }
    }
  }
`;
export const createReviewLike = /* GraphQL */ `
  mutation CreateReviewLike(
    $input: CreateReviewLikeInput!
    $condition: ModelReviewLikeConditionInput
  ) {
    createReviewLike(input: $input, condition: $condition) {
      id
      reviewId
      userId
      createdAt
      updatedAt
      user {
        id
        firstName
        lastName
        email
        bio
        phone
        sizes
        bustSizes
        bodyType
        height
        brands
        userStyles
        userBirthday
        userLocation
        profilePicture
        acceptTerms
        searchField
        createdAt
        updatedAt
      }
      review {
        id
        authorId
        garmentBrand
        garmentName
        garmentTitle
        garmentOccasion
        garmentType
        garmentDetails
        garmentRating
        garmentColor
        garmentSize
        garmentFit
        garmentImages
        searchField
        productId
        type
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateReviewLike = /* GraphQL */ `
  mutation UpdateReviewLike(
    $input: UpdateReviewLikeInput!
    $condition: ModelReviewLikeConditionInput
  ) {
    updateReviewLike(input: $input, condition: $condition) {
      id
      reviewId
      userId
      createdAt
      updatedAt
      user {
        id
        firstName
        lastName
        email
        bio
        phone
        sizes
        bustSizes
        bodyType
        height
        brands
        userStyles
        userBirthday
        userLocation
        profilePicture
        acceptTerms
        searchField
        createdAt
        updatedAt
      }
      review {
        id
        authorId
        garmentBrand
        garmentName
        garmentTitle
        garmentOccasion
        garmentType
        garmentDetails
        garmentRating
        garmentColor
        garmentSize
        garmentFit
        garmentImages
        searchField
        productId
        type
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteReviewLike = /* GraphQL */ `
  mutation DeleteReviewLike(
    $input: DeleteReviewLikeInput!
    $condition: ModelReviewLikeConditionInput
  ) {
    deleteReviewLike(input: $input, condition: $condition) {
      id
      reviewId
      userId
      createdAt
      updatedAt
      user {
        id
        firstName
        lastName
        email
        bio
        phone
        sizes
        bustSizes
        bodyType
        height
        brands
        userStyles
        userBirthday
        userLocation
        profilePicture
        acceptTerms
        searchField
        createdAt
        updatedAt
      }
      review {
        id
        authorId
        garmentBrand
        garmentName
        garmentTitle
        garmentOccasion
        garmentType
        garmentDetails
        garmentRating
        garmentColor
        garmentSize
        garmentFit
        garmentImages
        searchField
        productId
        type
        createdAt
        updatedAt
      }
    }
  }
`;
export const createFollowing = /* GraphQL */ `
  mutation CreateFollowing(
    $input: CreateFollowingInput!
    $condition: ModelFollowingConditionInput
  ) {
    createFollowing(input: $input, condition: $condition) {
      id
      followerId
      followingId
      createdAt
      updatedAt
      follower {
        id
        firstName
        lastName
        email
        bio
        phone
        sizes
        bustSizes
        bodyType
        height
        brands
        userStyles
        userBirthday
        userLocation
        profilePicture
        acceptTerms
        searchField
        createdAt
        updatedAt
      }
      following {
        id
        firstName
        lastName
        email
        bio
        phone
        sizes
        bustSizes
        bodyType
        height
        brands
        userStyles
        userBirthday
        userLocation
        profilePicture
        acceptTerms
        searchField
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateFollowing = /* GraphQL */ `
  mutation UpdateFollowing(
    $input: UpdateFollowingInput!
    $condition: ModelFollowingConditionInput
  ) {
    updateFollowing(input: $input, condition: $condition) {
      id
      followerId
      followingId
      createdAt
      updatedAt
      follower {
        id
        firstName
        lastName
        email
        bio
        phone
        sizes
        bustSizes
        bodyType
        height
        brands
        userStyles
        userBirthday
        userLocation
        profilePicture
        acceptTerms
        searchField
        createdAt
        updatedAt
      }
      following {
        id
        firstName
        lastName
        email
        bio
        phone
        sizes
        bustSizes
        bodyType
        height
        brands
        userStyles
        userBirthday
        userLocation
        profilePicture
        acceptTerms
        searchField
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteFollowing = /* GraphQL */ `
  mutation DeleteFollowing(
    $input: DeleteFollowingInput!
    $condition: ModelFollowingConditionInput
  ) {
    deleteFollowing(input: $input, condition: $condition) {
      id
      followerId
      followingId
      createdAt
      updatedAt
      follower {
        id
        firstName
        lastName
        email
        bio
        phone
        sizes
        bustSizes
        bodyType
        height
        brands
        userStyles
        userBirthday
        userLocation
        profilePicture
        acceptTerms
        searchField
        createdAt
        updatedAt
      }
      following {
        id
        firstName
        lastName
        email
        bio
        phone
        sizes
        bustSizes
        bodyType
        height
        brands
        userStyles
        userBirthday
        userLocation
        profilePicture
        acceptTerms
        searchField
        createdAt
        updatedAt
      }
    }
  }
`;
export const createPhoto = /* GraphQL */ `
  mutation CreatePhoto(
    $input: CreatePhotoInput!
    $condition: ModelPhotoConditionInput
  ) {
    createPhoto(input: $input, condition: $condition) {
      id
      reviewId
      image
      createdAt
      updatedAt
      review {
        id
        authorId
        garmentBrand
        garmentName
        garmentTitle
        garmentOccasion
        garmentType
        garmentDetails
        garmentRating
        garmentColor
        garmentSize
        garmentFit
        garmentImages
        searchField
        productId
        type
        createdAt
        updatedAt
      }
    }
  }
`;
export const updatePhoto = /* GraphQL */ `
  mutation UpdatePhoto(
    $input: UpdatePhotoInput!
    $condition: ModelPhotoConditionInput
  ) {
    updatePhoto(input: $input, condition: $condition) {
      id
      reviewId
      image
      createdAt
      updatedAt
      review {
        id
        authorId
        garmentBrand
        garmentName
        garmentTitle
        garmentOccasion
        garmentType
        garmentDetails
        garmentRating
        garmentColor
        garmentSize
        garmentFit
        garmentImages
        searchField
        productId
        type
        createdAt
        updatedAt
      }
    }
  }
`;
export const deletePhoto = /* GraphQL */ `
  mutation DeletePhoto(
    $input: DeletePhotoInput!
    $condition: ModelPhotoConditionInput
  ) {
    deletePhoto(input: $input, condition: $condition) {
      id
      reviewId
      image
      createdAt
      updatedAt
      review {
        id
        authorId
        garmentBrand
        garmentName
        garmentTitle
        garmentOccasion
        garmentType
        garmentDetails
        garmentRating
        garmentColor
        garmentSize
        garmentFit
        garmentImages
        searchField
        productId
        type
        createdAt
        updatedAt
      }
    }
  }
`;
export const createProduct = /* GraphQL */ `
  mutation CreateProduct(
    $input: CreateProductInput!
    $condition: ModelProductConditionInput
  ) {
    createProduct(input: $input, condition: $condition) {
      id
      brand
      type
      name
      image
      link
      createdAt
      updatedAt
      reviews {
        nextToken
        scannedCount
        count
      }
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct(
    $input: UpdateProductInput!
    $condition: ModelProductConditionInput
  ) {
    updateProduct(input: $input, condition: $condition) {
      id
      brand
      type
      name
      image
      link
      createdAt
      updatedAt
      reviews {
        nextToken
        scannedCount
        count
      }
    }
  }
`;
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct(
    $input: DeleteProductInput!
    $condition: ModelProductConditionInput
  ) {
    deleteProduct(input: $input, condition: $condition) {
      id
      brand
      type
      name
      image
      link
      createdAt
      updatedAt
      reviews {
        nextToken
        scannedCount
        count
      }
    }
  }
`;
export const createBrand = /* GraphQL */ `
  mutation CreateBrand(
    $input: CreateBrandInput!
    $condition: ModelBrandConditionInput
  ) {
    createBrand(input: $input, condition: $condition) {
      id
      type
      name
      image
      createdAt
      updatedAt
    }
  }
`;
export const updateBrand = /* GraphQL */ `
  mutation UpdateBrand(
    $input: UpdateBrandInput!
    $condition: ModelBrandConditionInput
  ) {
    updateBrand(input: $input, condition: $condition) {
      id
      type
      name
      image
      createdAt
      updatedAt
    }
  }
`;
export const deleteBrand = /* GraphQL */ `
  mutation DeleteBrand(
    $input: DeleteBrandInput!
    $condition: ModelBrandConditionInput
  ) {
    deleteBrand(input: $input, condition: $condition) {
      id
      type
      name
      image
      createdAt
      updatedAt
    }
  }
`;
export const createSuggestedBrand = /* GraphQL */ `
  mutation CreateSuggestedBrand(
    $input: CreateSuggestedBrandInput!
    $condition: ModelSuggestedBrandConditionInput
  ) {
    createSuggestedBrand(input: $input, condition: $condition) {
      id
      authorId
      brandName
      type
      createdAt
      updatedAt
    }
  }
`;
export const updateSuggestedBrand = /* GraphQL */ `
  mutation UpdateSuggestedBrand(
    $input: UpdateSuggestedBrandInput!
    $condition: ModelSuggestedBrandConditionInput
  ) {
    updateSuggestedBrand(input: $input, condition: $condition) {
      id
      authorId
      brandName
      type
      createdAt
      updatedAt
    }
  }
`;
export const deleteSuggestedBrand = /* GraphQL */ `
  mutation DeleteSuggestedBrand(
    $input: DeleteSuggestedBrandInput!
    $condition: ModelSuggestedBrandConditionInput
  ) {
    deleteSuggestedBrand(input: $input, condition: $condition) {
      id
      authorId
      brandName
      type
      createdAt
      updatedAt
    }
  }
`;
export const createSuggestedProduct = /* GraphQL */ `
  mutation CreateSuggestedProduct(
    $input: CreateSuggestedProductInput!
    $condition: ModelSuggestedProductConditionInput
  ) {
    createSuggestedProduct(input: $input, condition: $condition) {
      id
      authorId
      brandName
      productName
      type
      createdAt
      updatedAt
    }
  }
`;
export const updateSuggestedProduct = /* GraphQL */ `
  mutation UpdateSuggestedProduct(
    $input: UpdateSuggestedProductInput!
    $condition: ModelSuggestedProductConditionInput
  ) {
    updateSuggestedProduct(input: $input, condition: $condition) {
      id
      authorId
      brandName
      productName
      type
      createdAt
      updatedAt
    }
  }
`;
export const deleteSuggestedProduct = /* GraphQL */ `
  mutation DeleteSuggestedProduct(
    $input: DeleteSuggestedProductInput!
    $condition: ModelSuggestedProductConditionInput
  ) {
    deleteSuggestedProduct(input: $input, condition: $condition) {
      id
      authorId
      brandName
      productName
      type
      createdAt
      updatedAt
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      name
      email
      subject
      body
      type
      createdAt
      updatedAt
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      name
      email
      subject
      body
      type
      createdAt
      updatedAt
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      name
      email
      subject
      body
      type
      createdAt
      updatedAt
    }
  }
`;
