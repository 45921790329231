const occasionOptions = [
  {
    id: 1,
    name: "Athleisure",
    img: require("../assets/images/occasions/WeWear_categories_Athleisure.png"),
  },
  {
    id: 2,
    name: "Cocktail",
    img: require("../assets/images/occasions/WeWear_categories_Cocktail.png"),
  },
  {
    id: 3,
    name: "Daytime",
    img: require("../assets/images/occasions/WeWear_categories_Daytime.png"),
  },
  {
    id: 4,
    name: "Gala",
    img: require("../assets/images/occasions/WeWear_categories_Gala.png"),
  },
  {
    id: 5,
    name: "Night Out",
    img: require("../assets/images/occasions/WeWear_categories_Night_Out.png"),
  },
  {
    id: 6,
    name: "Office",
    img: require("../assets/images/occasions/WeWear_categories_Office.png"),
  },
  {
    id: 7,
    name: "Vacation",
    img: require("../assets/images/occasions/WeWear_categories_Vacation.png"),
  },
];

export default occasionOptions;
