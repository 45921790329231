import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { useRecoilState } from "recoil";
import { signUpModalOpenState } from "../../recoil/atoms";

const useStyles = makeStyles((theme) => ({
  title: { padding: theme.spacing(2) },
  content: { padding: "16px 8px" },
  buttons: { padding: theme.spacing(2) },
}));

const SignUpModal = () => {
  const classes = useStyles();
  const history = useHistory();
  const [signUpModalOpen, setSignUpModalOpen] = useRecoilState(
    signUpModalOpenState
  );

  const handleClose = () => {
    setSignUpModalOpen(false);
  };

  const handleSignUp = () => {
    setSignUpModalOpen(false);
    history.push({ pathname: "/login", state: { formType: "signUp" } });
  };

  return (
    <Dialog fullWidth open={signUpModalOpen} onClose={handleClose}>
      <DialogTitle className={classes.title}>Like what you see?</DialogTitle>
      <DialogContent className={classes.content}>
        <DialogContentText>
          Create a profile to follow users and save reviews!
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.buttons}>
        <Button onClick={handleClose} color="primary">
          Maybe Later
        </Button>
        <Button onClick={handleSignUp} variant="outlined">
          Sign Up Now!
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SignUpModal;
