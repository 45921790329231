const colorOptions = [
  { id: 1, name: "black", displayColor: "#323232" },
  { id: 2, name: "white", displayColor: "#9A9A9A" },
  { id: 3, name: "gray", displayColor: "#FFFFFF" },
  { id: 4, name: "beige", displayColor: "#EDE2D0" },
  { id: 5, name: "brown", displayColor: "#A37751" },
  { id: 6, name: "red", displayColor: "#C0402B" },
  { id: 7, name: "orange", displayColor: "#ED7946" },
  { id: 8, name: "yellow", displayColor: "#F5CC60" },
  { id: 9, name: "green", displayColor: "#6FA380" },
  { id: 10, name: "blue", displayColor: "#4A69B2" },
  { id: 11, name: "purple", displayColor: "#A178B4" },
  { id: 12, name: "pink", displayColor: "#F7A0B6" },
  { id: 13, name: "navy", displayColor: "#000080" },
];

export default colorOptions;
