const sizeOptions = [
  "00",
  "0",
  "02",
  "04",
  "06",
  "08",
  "10",
  "12",
  "14",
  "16",
  "18",
  "20",
  "22",
  "24",
  "26",
  "28",
  "30",
  "32",
  "34",
  "36",
  "XXS",
  "XS",
  "S",
  "M",
  "L",
  "XL",
  "XXL",
];

export default sizeOptions;
