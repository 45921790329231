import React, { useState } from "react";
import { useRecoilValue } from "recoil";
import { brandsState } from "../../recoil/atoms";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  TextField,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
  InputLabel,
  FormHelperText,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import { v4 as uuid } from "uuid";
import { useDropzone } from "react-dropzone";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

import clothingTypeOptions from "../../data/clothingTypeOptions";

import Loading from "../../components/Loading/Loading";

import { API, graphqlOperation, Storage } from "aws-amplify";
import { createProduct } from "../../graphql/mutations";

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: "#F5F5F5",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(3),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    fontWeight: "bold",
    fontSize: 16,
  },
  navItem: { fontWeight: 600, fontSize: "16px", cursor: "pointer" },
}));

const ProductForm = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const brands = useRecoilValue(brandsState);

  const [loading, setLoading] = useState(false);
  const [validation, setValidation] = useState({});

  const [brand, setBrand] = useState("");
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [link, setLink] = useState("");
  const [image, setImage] = useState({});

  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setImage(acceptedFiles[0]);
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setBrand("");
    setName("");
    setType("");
    setLink("");
    setImage("");
    setValidation({});
    setFiles([]);
  };

  // Validate
  //==============================================================================

  const validate = () => {
    const validationErrors = {};

    if (!brand) {
      validationErrors.brand = "Brand is required";
    }

    if (!name) {
      validationErrors.name = "The product name is required";
    }

    if (!type) {
      validationErrors.type = "Clothing item is required";
    }

    if (!image.name) {
      validationErrors.image = "An image is required";
    }

    if (Object.keys(validationErrors).length > 0) {
      setValidation(validationErrors);
      return false;
    }

    return true;
  };

  const handleClear = () => {
    setBrand("");
    setName("");
    setType("");
    setLink("");
    setImage({});
    setFiles([]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!validate()) {
      setLoading(false);
      return;
    }

    try {
      const imageKey = uuid() + image.name.replace(/\s/g, "-").toLowerCase();
      await Storage.put(imageKey, image);

      const productData = {
        brand,
        type,
        name,
        link,
        image: imageKey,
      };

      await API.graphql(
        graphqlOperation(createProduct, { input: productData })
      );
      enqueueSnackbar("Beautiful!  The product has been created.");
      setValidation({});
      handleClear();
      setLoading(false);
      handleClose();
    } catch (error) {
      setLoading(false);
      console.warn(error);
      setValidation({ error: error.message });
      enqueueSnackbar(
        "There was an error, please review your form and try again.",
        {
          variant: "error",
        }
      );
    }
  };

  return (
    <div>
      <Button
        style={{ margin: 8 }}
        variant="contained"
        color="primary"
        onClick={handleClickOpen}
      >
        + New
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <form>
          <Grid container justify="center" alignItems="center">
            <Grid item xs></Grid>
            <Grid item xs={10} container>
              <Grid container justify="center" alignItems="center" item xs={12}>
                <Typography variant="h3">Create Product</Typography>
              </Grid>
              <Grid
                item
                container
                direction="column"
                justify="center"
                alignItems="center"
                style={{
                  height: "95%",
                  alignItems: "center",
                  borderRadius: "20px",
                  border: "1px dashed #bdbdbd",
                  cursor: "pointer",
                }}
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                <Grid item container justify="center" alignItems="center">
                  {files.map((file) => (
                    <Grid
                      item
                      style={{
                        margin: 4,
                        width: 100,
                        height: 100,
                        padding: 4,
                      }}
                      key={file.name}
                    >
                      <img
                        alt="preview"
                        src={file.preview}
                        style={{
                          display: "block",
                          width: "auto",
                          height: "100%",
                          borderRadius: "5px",
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>
                <Grid item container justify="center" alignItems="center">
                  <CloudUploadIcon />
                  <Typography variant="subtitle2">
                    You can upload or drop your image here.
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  error={validation?.brand?.length > 0 ? true : false}
                >
                  <InputLabel>Brand</InputLabel>
                  <Select
                    label="Brand"
                    value={brand}
                    onChange={(event) => setBrand(event.target.value)}
                    name="brand"
                  >
                    {brands.map(({ id, name }) => (
                      <MenuItem key={id} value={name}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                  {validation?.brand && (
                    <FormHelperText>{validation?.brand}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  error={validation?.type?.length > 0 ? true : false}
                >
                  <InputLabel>Type</InputLabel>
                  <Select
                    label="Type"
                    value={type}
                    onChange={(event) => setType(event.target.value)}
                    name="type"
                  >
                    {clothingTypeOptions.map(({ id, name }) => (
                      <MenuItem key={id} value={name}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                  {validation?.type && (
                    <FormHelperText>{validation?.type}</FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="link"
                  label="Garment Link"
                  margin="normal"
                  autoComplete="off"
                  value={link}
                  onChange={(event) => setLink(event.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="name"
                  label="Garment Name"
                  margin="normal"
                  autoComplete="off"
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                  error={validation?.name?.length > 0 ? true : false}
                  helperText={validation?.name || ""}
                />
              </Grid>

              <Grid container justify="center">
                {loading ? (
                  <Loading />
                ) : (
                  <Button
                    variant="outlined"
                    onClick={handleSubmit}
                    className={classes.button}
                  >
                    Create Product
                  </Button>
                )}
              </Grid>
            </Grid>
            <Grid item xs></Grid>
          </Grid>
        </form>
      </Dialog>
    </div>
  );
};

export default ProductForm;
