import React, { useState, useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  brandsState,
  createReviewModalOpenState,
  authUserIdState,
  authUserState,
  reviewToEditState,
} from "../../recoil/atoms";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  GridList,
  GridListTile,
  GridListTileBar,
  IconButton,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  FormControlLabel,
  Select,
  Typography,
} from "@material-ui/core";
import { Rating } from "@material-ui/lab";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { useSnackbar } from "notistack";
import { v4 as uuid } from "uuid";

// Icons
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import RotateRightIcon from "@material-ui/icons/RotateRight";

// Dropzone, Cropper, and Blob Reduce
import Dropzone from "react-dropzone";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import ImageBlobReduce from "image-blob-reduce";

// Data Options
import occasionOptions from "../../data/occasionOptions";
import clothingTypeOptions from "../../data/clothingTypeOptions";
import colorOptions from "../../data/colorOptions";
import sizeOptions from "../../data/sizeOptions";

// Components
import Loading from "../Loading/Loading";
import SuggestedProductForm from "../SuggestedProductForm/SuggestedProductForm";

// Amplify
import { API, Auth, graphqlOperation, Storage } from "aws-amplify";
import {
  createReview,
  updateReview,
  createSuggestedBrand,
} from "../../graphql/mutations";
import { listProducts } from "../../graphql/queries";

// Style
const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: "#F5F5F5",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  size: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: theme.spacing(1),
    width: 38,
    height: 38,
    textAlign: "center",
    border: "1px solid #8497AC",
  },
  sizeActive: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: theme.spacing(1),
    width: 38,
    height: 38,
    textAlign: "center",
    backgroundColor: "#DFC6BF",
    color: "black",
  },
  button: {
    margin: theme.spacing(3),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    fontWeight: "bold",
    fontSize: 16,
  },
  dropArea: {
    height: "95%",
    alignItems: "center",
    borderRadius: "20px",
    border: "1px dashed #bdbdbd",
    cursor: "pointer",
    margin: 5,
  },
  dropAreaError: {
    height: "95%",
    alignItems: "center",
    borderRadius: "20px",
    border: "1px red solid",
    cursor: "pointer",
    margin: 5,
  },
  colors: {
    height: 27,
    width: 27,
    borderRadius: "50%",
  },
  colorActive: {
    padding: 3,
    borderRadius: "50%",
    border: "2px solid #DFC6BF",
  },
  navItem: { fontWeight: 600, fontSize: "16px", cursor: "pointer" },
  error: {
    border: "1px red solid",
    borderRadius: "10px",
  },
  errorText: {
    color: "red",
  },
  title: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
  },
}));

const StyledRating = withStyles({
  iconFilled: {
    color: "#DFC6BF",
    fontSize: 40,
  },
  iconHover: {
    fontSize: 40,
  },
  iconEmpty: {
    fontSize: 40,
  },
})(Rating);

const filterOptions = (options, { inputValue }) => {
  const terms = inputValue.toLowerCase().split(" ");
  return options.filter((option) => {
    let found = true;
    const subject = `${option.brand.toLowerCase()} ${option.name.toLowerCase()} ${option.type.toLowerCase()}`;
    for (const term of terms) {
      if (subject.indexOf(term) === -1) {
        found = false;
        break;
      }
    }
    return found;
  });
};

const filter = createFilterOptions();

const ReviewForm = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const authUserId = useRecoilValue(authUserIdState);
  const authUser = useRecoilValue(authUserState);
  const [createReviewModalOpen, setCreateReviewModalOpen] = useRecoilState(
    createReviewModalOpenState
  );
  const [reviewToEdit, setReviewToEdit] = useRecoilState(reviewToEditState);

  const [loading, setLoading] = useState(false);
  const [validation, setValidation] = useState({});
  const [products, setProducts] = useState([]);
  const brandOptions = useRecoilValue(brandsState);

  const [garmentBrand, setGarmentBrand] = useState(null);
  const [garmentName, setGarmentName] = useState("");
  const [garmentTitle, setGarmentTitle] = useState("");
  const [garmentOccasion, setGarmentOccasion] = useState("");
  const [garmentType, setGarmentType] = useState("");
  const [garmentDetails, setGarmentDetails] = useState("");
  const [garmentImages, setGarmentImages] = useState([]);
  const [garmentRating, setGarmentRating] = useState(null);
  const [garmentColor, setGarmentColor] = useState("");
  const [garmentSize, setGarmentSize] = useState("");
  const [garmentFit, setGarmentFit] = useState("");
  const [garmentProductId, setGarmentProductId] = useState("1");

  const [dialogOpen, setDialogOpen] = useState(false);
  const [images, setImages] = useState([]);
  const [pending, setPending] = useState([]);
  const [cropper, setCropper] = useState();
  const [rotateImage, setRotateImage] = useState(0);

  const getImage = (file) => {
    return new Promise((resolve, reject) => {
      const fr = new FileReader();
      fr.onload = () => {
        resolve(fr.result);
      };
      fr.addEventListener("error", (e) => {
        reject(e);
      });
      fr.readAsDataURL(file);
    });
  };

  const onComplete = async () => {
    try {
      const blob = await new Promise((resolve, reject) => {
        cropper.getCroppedCanvas().toBlob(resolve);
      });
      const resize = new ImageBlobReduce();
      const image = await resize.toCanvas(blob, { max: 600 });
      setImages([image, ...images]);
      const newPending = [...pending];
      newPending.shift();
      if (newPending.length === 0) {
        setDialogOpen(false);
      }
      setPending(newPending);
      setRotateImage(0);
    } catch (error) {
      console.log("error saving image", error);
    }
  };

  const onDrop = async (files) => {
    if (files.length > 5) {
      enqueueSnackbar(
        "There is a 5 image max. Please choose 1-5 images and try again.",
        {
          variant: "error",
        }
      );
      return;
    }

    setPending(await Promise.all(files.map((file) => getImage(file))));
    setDialogOpen(true);
  };

  const handleRemoveImage = (index) => {
    const tempList = [...images];
    tempList.splice(index, 1);
    setImages(tempList);
  };

  const handleRotateCounter = () => {
    setRotateImage((prevRotate) => prevRotate - 90);
    setCropper(cropper.rotate(rotateImage));
  };

  const handleRotateClock = () => {
    setRotateImage((prevRotate) => prevRotate + 90);
    setCropper(cropper.rotate(rotateImage));
  };

  const closeImageDialog = () => {
    setDialogOpen(false);
    setRotateImage(0);
  };

  // Validate
  //==============================================================================

  const validate = () => {
    const validationErrors = {};

    if (!garmentBrand) {
      validationErrors.garmentBrand = "Brand is required";
    }

    if (!garmentName) {
      validationErrors.garmentName = "The product name is required";
    }

    if (garmentTitle?.length === 0) {
      validationErrors.garmentTitle = "Your thoughts are required";
    }

    if (garmentTitle.length > 60) {
      validationErrors.garmentTitleLength = "There is a 60 character max.";
    }

    if (!garmentOccasion) {
      validationErrors.garmentOccasion = "Occasion is required";
    }

    if (!garmentType) {
      validationErrors.garmentType = "Clothing item is required";
    }

    if (!garmentDetails) {
      validationErrors.garmentDetails = "Details are required";
    }

    if (images.length === 0 && !reviewToEdit.id) {
      validationErrors.garmentImages = "At least one image is required";
    }

    if (images.length > 5) {
      validationErrors.garmentImages = "There is a 5 image maximum";
    }

    if (!garmentRating) {
      validationErrors.garmentRating = "Rating is required";
    }

    if (!garmentColor) {
      validationErrors.garmentColor = "Color is required";
    }

    if (!garmentSize) {
      validationErrors.garmentSize = "Size is required";
    }

    if (!garmentFit) {
      validationErrors.garmentFit = "Overall fit is required";
    }

    if (Object.keys(validationErrors).length > 0) {
      setValidation(validationErrors);
      return false;
    }

    return true;
  };

  const handleClose = () => {
    setCreateReviewModalOpen(false);
    setGarmentBrand(null);
    setGarmentName("");
    setGarmentTitle("");
    setGarmentOccasion("");
    setGarmentType("");
    setGarmentImages([]);
    setGarmentDetails("");
    setGarmentRating(null);
    setGarmentColor("");
    setGarmentSize("");
    setGarmentFit("");
    setGarmentProductId("1");
    setValidation({});
    setImages([]);
    setPending([]);
    setReviewToEdit({});
    setRotateImage(0);
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  useEffect(() => {
    setGarmentBrand(reviewToEdit.garmentBrand || null);
    setGarmentName(reviewToEdit.garmentName || "");
    setGarmentTitle(reviewToEdit.garmentTitle || "");
    setGarmentOccasion(reviewToEdit.garmentOccasion || "");
    setGarmentType(reviewToEdit.garmentType || "");
    setGarmentImages(reviewToEdit.garmentImages || []);
    setGarmentDetails(reviewToEdit.garmentDetails || "");
    setGarmentRating(reviewToEdit.garmentRating || null);
    setGarmentColor(reviewToEdit.garmentColor || "");
    setGarmentSize(reviewToEdit.garmentSize || "");
    setGarmentFit(reviewToEdit.garmentFit || "");
    setGarmentProductId(reviewToEdit.garmentProductId || "1");
  }, [createReviewModalOpen]);

  const fetchProducts = async () => {
    try {
      const productData = await API.graphql({
        query: listProducts,
        authMode: "API_KEY",
      });
      const productsArray = productData.data.listProducts.items;
      const signedProductsArray = await Promise.all(
        productsArray.map(async (item) => {
          const signedUrl = await Storage.get(item.image);
          item.imageUrl = signedUrl;
          return item;
        })
      );
      setProducts(signedProductsArray);
    } catch (error) {
      console.log("error fetching products:", error);
    }
  };

  const handleSelectProduct = (value) => {
    if (!value) {
      setGarmentBrand(null);
      setGarmentName("");
      setGarmentType("");
      setGarmentProductId("1");
      return;
    }

    setGarmentBrand(value?.brand);
    setGarmentName(value?.name);
    setGarmentType(value?.type);
    setGarmentProductId(value?.id);
  };

  // Submit Review
  //==============================================================================

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!validate()) {
      setLoading(false);
      return;
    }

    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      const currentUserId = currentUser.attributes.sub;

      const imageKeys = await Promise.all(
        images.map(async (file) => {
          const blob = await new Promise((resolve, reject) => {
            file.toBlob(resolve);
          });
          const resize = new ImageBlobReduce();
          file = await resize.toBlob(blob, { max: 600 });
          const imageKey = uuid();
          await Storage.put(imageKey, file);
          return imageKey;
        })
      );

      const reviewData = {
        authorId: currentUserId,
        garmentBrand,
        garmentName,
        garmentColor,
        garmentDetails,
        garmentImages: imageKeys,
        garmentOccasion,
        garmentRating,
        garmentSize,
        garmentFit,
        garmentTitle,
        garmentType,
        type: "Review",
        productId: garmentProductId,
        searchField: `${garmentBrand} ${garmentName} ${garmentColor} ${garmentDetails} ${garmentOccasion} ${garmentSize} ${garmentFit} ${garmentTitle} ${garmentType} ${authUser?.firstName} ${authUser?.lastName}`.toLowerCase(),
      };
      await API.graphql(graphqlOperation(createReview, { input: reviewData }));

      enqueueSnackbar("Beautiful!  Your review has been created.", {
        variant: "success",
      });
      setValidation({});
      setLoading(false);
      handleClose();
    } catch (error) {
      setLoading(false);
      console.warn(error);
      setValidation({ error: error.message });
      enqueueSnackbar(
        "There was an error, please review your form and try again.",
        {
          variant: "error",
        }
      );
    }
  };

  // Update Review
  //==============================================================================

  const handleUpdate = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!validate()) {
      setLoading(false);
      return;
    }

    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      const currentUserId = currentUser.attributes.sub;

      const reviewData = {
        authorId: currentUserId,
        garmentBrand,
        garmentName,
        garmentColor,
        garmentDetails,
        garmentImages,
        garmentOccasion,
        garmentRating,
        garmentSize,
        garmentFit,
        garmentTitle,
        garmentType,
        type: "Review",
        productId: garmentProductId,
        searchField: `${garmentBrand} ${garmentName} ${garmentColor} ${garmentDetails} ${garmentOccasion} ${garmentSize} ${garmentFit} ${garmentTitle} ${garmentType} ${authUser?.firstName} ${authUser?.lastName}`.toLowerCase(),
      };

      await API.graphql(
        graphqlOperation(updateReview, {
          input: { id: reviewToEdit.id, ...reviewData },
        })
      );

      enqueueSnackbar("Your review has been updated.", {
        variant: "success",
      });
      setValidation({});
      setLoading(false);
      handleClose();
    } catch (error) {
      setLoading(false);
      console.warn(error);
      setValidation({ error: error.message });
      enqueueSnackbar(
        "There was an error, please review your form and try again.",
        {
          variant: "error",
        }
      );
    }
  };

  // Suggest a Brand
  //==============================================================================
  const handleChangeBrand = async (event, newValue) => {
    if (!newValue) {
      setGarmentBrand("");
    } else if (newValue && newValue.inputValue) {
      try {
        setGarmentBrand(newValue.inputValue);
        await API.graphql(
          graphqlOperation(createSuggestedBrand, {
            input: {
              brandName: newValue.inputValue,
              authorId: authUserId,
              type: "SuggestedBrand",
            },
          })
        );
      } catch (error) {
        console.log("error suggesting a brand", error);
      }
    } else {
      setGarmentBrand(newValue?.name);
    }
  };

  //==============================================================================
  return (
    <Dialog
      open={createReviewModalOpen}
      onClose={handleClose}
      scroll="body"
      maxWidth="lg"
      fullWidth
    >
      <IconButton onClick={handleClose}>
        <CloseIcon />
      </IconButton>
      <DialogTitle disableTypography>
        <Grid container justify="center">
          <Typography variant="h3">Time to review your fit!</Typography>
        </Grid>
        <Grid container justify="center">
          <Grid item xs></Grid>
          <Grid container justify="center" alignItems="center" item xs={6}>
            <FormControl fullWidth margin="dense" variant="outlined">
              <Autocomplete
                onChange={(event, newValue) => {
                  handleSelectProduct(newValue);
                }}
                id="products"
                options={products}
                filterOptions={filterOptions}
                getOptionLabel={(product) =>
                  `${product?.brand} ${product?.name} ${product?.type}`
                }
                renderOption={(product) => {
                  return (
                    <>
                      <img
                        style={{ height: 40 }}
                        src={product.imageUrl}
                        alt={`${product?.brand} ${product?.name}`}
                      />
                      {`${product?.brand} ${product?.name} ${product?.type}`}
                    </>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Search for your item"
                    variant="outlined"
                    margin="dense"
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid
            style={{ paddingLeft: 4 }}
            container
            justify="flex-start"
            alignItems="center"
            item
            xs
          >
            <SuggestedProductForm />
          </Grid>
          <Grid item xs></Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <form>
          <Grid container>
            <Grid item xs></Grid>
            <Grid container item xs={11} spacing={2}>
              <Grid container item spacing={2} xs={12} sm={6}>
                <Grid item xs={12}>
                  <FormControl
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    error={validation?.garmentBrand ? true : false}
                  >
                    <FormLabel component="legend" style={{ paddingBottom: 8 }}>
                      Brand
                    </FormLabel>
                    <Autocomplete
                      value={garmentBrand}
                      onChange={(event, newValue) =>
                        handleChangeBrand(event, newValue)
                      }
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        // Suggest the creation of a new value
                        if (params.inputValue !== "") {
                          filtered.push({
                            inputValue: params.inputValue,
                            name: `Add "${params.inputValue}"`,
                          });
                        }

                        return filtered;
                      }}
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      options={brandOptions}
                      getOptionLabel={(option) => {
                        if (typeof option === "string") {
                          return option;
                        }
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        return option.name;
                      }}
                      renderOption={(option) => option.name}
                      freeSolo
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          margin="dense"
                          variant="outlined"
                          placeholder="Nike, Madewell, Loft, etc."
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    error={validation?.garmentType ? true : false}
                  >
                    <FormLabel component="legend" style={{ paddingBottom: 8 }}>
                      Clothing Item
                    </FormLabel>
                    <Select
                      value={garmentType}
                      onChange={(event) => setGarmentType(event.target.value)}
                      id="garmentType"
                      name="garmentType"
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        Sweater, Dress, Outerwear, etc.
                      </MenuItem>
                      {clothingTypeOptions.map(({ id, name }) => (
                        <MenuItem key={id} value={name}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                    {validation?.garmentType && (
                      <FormHelperText>{validation?.garmentType}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <FormLabel component="legend">Product Name</FormLabel>
                  <TextField
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    id="garmentName"
                    name="garmentName"
                    placeholder="Name of garment"
                    autoComplete="off"
                    value={garmentName}
                    onChange={(event) => setGarmentName(event.target.value)}
                    error={validation?.garmentName ? true : false}
                    helperText={validation?.garmentName || ""}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControl
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    error={validation?.garmentOccasion ? true : false}
                  >
                    <FormLabel component="legend" style={{ paddingBottom: 8 }}>
                      Occasion
                    </FormLabel>
                    <Select
                      value={garmentOccasion}
                      onChange={(event) =>
                        setGarmentOccasion(event.target.value)
                      }
                      id="garmentoccasion"
                      name="garmentoccasion"
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        Office, Vacation, Athleisure, etc.
                      </MenuItem>
                      {occasionOptions.map(({ id, name }) => (
                        <MenuItem key={id} value={name}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                    {validation?.garmentOccasion && (
                      <FormHelperText>
                        {validation?.garmentOccasion}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <FormLabel component="legend">
                    What were your first thoughts?
                  </FormLabel>
                  <TextField
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    id="garmentTitle"
                    name="garmentTitle"
                    placeholder="Tight around the waist, but length was perfect"
                    autoComplete="off"
                    value={garmentTitle}
                    onChange={(event) => setGarmentTitle(event.target.value)}
                    error={
                      validation?.garmentTitle || validation?.garmentTitleLength
                        ? true
                        : false
                    }
                    helperText={validation?.garmentTitle || ""}
                  />
                  <Typography
                    variant="caption"
                    style={{
                      color: garmentTitle.length > 60 ? "red" : "green",
                    }}
                  >{`${
                    60 - garmentTitle.length
                  } characters remaining`}</Typography>
                </Grid>

                <Grid item xs={12}>
                  <FormLabel component="legend">We want the details!</FormLabel>
                  <TextField
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    id="garmentDetails"
                    name="garmentDetails"
                    placeholder="How exactly did it fit you? What were the pros and cons of the outfit? We want to know the details!"
                    multiline
                    rows={4}
                    autoComplete="off"
                    value={garmentDetails}
                    onChange={(event) => setGarmentDetails(event.target.value)}
                    error={validation?.garmentDetails ? true : false}
                    helperText={validation?.garmentDetails || ""}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                container
                direction="column"
                justify="center"
                alignItems="center"
                xs={12}
                sm={6}
              >
                {reviewToEdit.id ? (
                  <Grid item container justify="center">
                    <GridList className={classes.gridList} cols={3}>
                      {reviewToEdit.imageUrls.map((image, index) => (
                        <GridListTile key={index} spacing={1}>
                          <img src={image} alt="thumbnail" />
                        </GridListTile>
                      ))}
                    </GridList>
                    <Typography variant="subtitle1">
                      Images can not be edited.
                    </Typography>
                  </Grid>
                ) : (
                  <>
                    <Dropzone accept="image/*" onDrop={onDrop}>
                      {({ getRootProps, getInputProps }) => (
                        <Grid
                          item
                          xs
                          container
                          direction="column"
                          justify="center"
                          alignItems="center"
                          className={
                            validation?.garmentImages
                              ? classes.dropAreaError
                              : classes.dropArea
                          }
                          {...getRootProps()}
                        >
                          <input {...getInputProps()} />

                          <Grid
                            item
                            container
                            justify="center"
                            alignItems="center"
                          >
                            <CloudUploadIcon />
                            <Typography variant="subtitle2">
                              You can upload or drop your images here
                            </Typography>
                          </Grid>
                        </Grid>
                      )}
                    </Dropzone>

                    <GridList className={classes.gridList} cols={3}>
                      {images.map((image, index) => (
                        <GridListTile key={index} spacing={1}>
                          <img src={image.toDataURL()} alt="thumbnail" />
                          <GridListTileBar
                            classes={{
                              root: classes.titleBar,
                              title: classes.title,
                            }}
                            actionIcon={
                              <IconButton
                                onClick={() => handleRemoveImage(index)}
                              >
                                <DeleteIcon className={classes.title} />
                              </IconButton>
                            }
                          />
                        </GridListTile>
                      ))}
                    </GridList>

                    {validation?.garmentImages && (
                      <Typography
                        variant="caption"
                        className={classes.errorText}
                      >
                        {validation?.garmentImages || ""}
                      </Typography>
                    )}
                  </>
                )}

                <Grid container justify="flex-start" alignItems="center" item>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="subtitle1">Overall Fit</Typography>
                  </Grid>
                  <Grid item xs={12} sm>
                    <FormControl
                      fullWidth
                      margin="dense"
                      variant="outlined"
                      error={validation?.garmentFit ? true : false}
                    >
                      <RadioGroup
                        value={garmentFit}
                        onChange={(event) => setGarmentFit(event.target.value)}
                      >
                        <FormControlLabel
                          value="Runs Small"
                          control={
                            <Radio
                              disableRipple
                              color="primary"
                              style={{ padding: 4 }}
                            />
                          }
                          label="Runs Small"
                        />
                        <FormControlLabel
                          value="True to Size"
                          control={
                            <Radio
                              disableRipple
                              color="primary"
                              style={{ padding: 4 }}
                            />
                          }
                          label="True to Size"
                        />
                        <FormControlLabel
                          value="Runs Large"
                          control={
                            <Radio
                              disableRipple
                              color="primary"
                              style={{ padding: 4 }}
                            />
                          }
                          label="Runs Large"
                        />
                      </RadioGroup>
                      {validation?.garmentFit && (
                        <FormHelperText>
                          {validation?.garmentFit}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>

              <Dialog open={dialogOpen} onClose={() => closeImageDialog()}>
                <DialogTitle>Crop Images</DialogTitle>

                <DialogContent>
                  <Typography variant="subtitle2">
                    After making your changes on each image, press the save
                    button to move onto the next image. Once all images have
                    been completed, the dialog will close.
                  </Typography>
                  <GridList className={classes.gridList} cols={4} spacing={1}>
                    {pending.map((image, index) => (
                      <GridListTile key={index}>
                        <img src={image} alt="" />
                      </GridListTile>
                    ))}
                  </GridList>
                  <Cropper
                    style={{ height: 400, width: "100%" }}
                    src={pending[0]}
                    initialAspectRatio={5 / 7}
                    onInitialized={(instance) => {
                      setCropper(instance);
                    }}
                  />
                </DialogContent>

                <DialogActions>
                  <IconButton onClick={() => handleRotateCounter()}>
                    <RotateLeftIcon />
                  </IconButton>
                  <IconButton onClick={() => handleRotateClock()}>
                    <RotateRightIcon />
                  </IconButton>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onComplete}
                  >
                    Save
                  </Button>
                </DialogActions>
              </Dialog>

              <Grid
                item
                xs={12}
                sm={3}
                container
                direction="row"
                justify="space-around"
                alignItems="center"
                className={validation?.garmentRating ? classes.error : ""}
              >
                <FormLabel component="legend">Rating:</FormLabel>
                <StyledRating
                  name="rating"
                  value={garmentRating}
                  emptyIcon={<StarBorderIcon fontSize="inherit" />}
                  onChange={(event, newValue) => {
                    setGarmentRating(newValue);
                  }}
                />

                {validation?.garmentRating && (
                  <Typography variant="caption" className={classes.errorText}>
                    {validation?.garmentRating || ""}
                  </Typography>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                sm={9}
                container
                direction="row"
                justify="space-around"
                alignItems="center"
                className={validation?.garmentColor ? classes.error : ""}
              >
                <FormLabel component="legend">Color:</FormLabel>

                {colorOptions.map((color) => (
                  <div
                    key={color.id}
                    className={
                      color.name === garmentColor ? classes.colorActive : ""
                    }
                  >
                    <div
                      className={classes.colors}
                      style={{
                        backgroundColor: `${color.displayColor}`,
                      }}
                      onClick={() => setGarmentColor(color.name)}
                    ></div>
                  </div>
                ))}
                {validation?.garmentColor && (
                  <Typography variant="caption" className={classes.errorText}>
                    {validation?.garmentColor || ""}
                  </Typography>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                container
                direction="row"
                justify="center"
                alignItems="center"
                className={validation?.garmentSize ? classes.error : ""}
              >
                <FormLabel component="legend">Size Worn:</FormLabel>
                {sizeOptions.map((size) => (
                  <Paper
                    key={size}
                    className={
                      size === garmentSize ? classes.sizeActive : classes.size
                    }
                    elevation={0}
                    onClick={() => setGarmentSize(size)}
                  >
                    <Typography variant="button">{size}</Typography>
                  </Paper>
                ))}
                {validation?.garmentSize && (
                  <Typography variant="caption" className={classes.errorText}>
                    {validation?.garmentSize || ""}
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Grid item xs></Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions className={classes.content}>
        <Grid container justify="center">
          {loading ? (
            <Loading />
          ) : (
            <Button
              variant="outlined"
              onClick={reviewToEdit.id ? handleUpdate : handleSubmit}
              className={classes.button}
            >
              Submit Review
            </Button>
          )}
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default ReviewForm;
