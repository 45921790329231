import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Paper } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Heading from "../../components/Heading/Heading";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import { API, Storage } from "aws-amplify";
import { getBrandsAlphabetically } from "../../graphql/queries";

import Loading from "../../components/Loading/Loading";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: theme.spacing(1),
    width: 220,
    height: 110,
    textAlign: "center",
    border: "1px solid #DFC6BF",
  },
  active: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: theme.spacing(1),
    width: 220,
    height: 110,
    textAlign: "center",
    backgroundColor: "white",
    color: "#DFC6BF",
  },
}));

const StyleBrands = ({ brands, setBrands }) => {
  const classes = useStyles();
  const [brandOptions, setBrandOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const [nextToken, setNextToken] = useState(undefined);
  const [nextNextToken, setNextNextToken] = useState();
  const [previousTokens, setPreviousTokens] = useState([]);

  const handleNext = () => {
    setPreviousTokens((prev) => [...prev, nextToken]);
    setNextToken(nextNextToken);
    setNextNextToken(null);
  };

  const handlePrev = () => {
    setNextToken(previousTokens.pop());
    setPreviousTokens([...previousTokens]);
    setNextNextToken(null);
  };

  const handleBrandChange = (brand) => {
    if (brands?.includes(brand)) {
      setBrands(brands.filter((b) => b !== brand));
    } else {
      setBrands((prevBrands) => [...prevBrands, brand]);
    }
  };

  useEffect(() => {
    const fetchBrands = async () => {
      try {
        setLoading(true);
        const brandData = await API.graphql({
          query: getBrandsAlphabetically,
          authMode: "API_KEY",
          variables: {
            type: "Brand",
            sortDirection: "ASC",
            limit: 15,
            nextToken,
          },
        });
        setNextNextToken(brandData.data.getBrandsAlphabetically.nextToken);
        const brandsArray = brandData.data.getBrandsAlphabetically.items;
        const signedBrandsArray = await Promise.all(
          brandsArray.map(async (item) => {
            const signedUrl = await Storage.get(item.image);
            item.imageUrl = signedUrl;
            return item;
          })
        );
        setBrandOptions(signedBrandsArray);
        setLoading(false);
      } catch (error) {
        console.log("error fetching brands:", error);
        setLoading(false);
      }
    };

    fetchBrands();
  }, [nextToken]);

  return (
    <Grid
      container
      item
      xs={10}
      direction="column"
      justify="center"
      alignItems="center"
    >
      <Grid item>
        <Heading>Let us know your favorite brands:</Heading>
      </Grid>
      {loading ? (
        <Loading />
      ) : (
        <Grid
          item
          container
          direction="row"
          justify="center"
          alignItems="center"
        >
          {brandOptions.map((brand) => (
            <Paper
              key={brand.id}
              className={
                brands?.includes(brand.name) ? classes.active : classes.paper
              }
              elevation={0}
              onClick={() => handleBrandChange(brand.name)}
            >
              <Grid container>
                <Grid item style={{ position: "absolute" }}>
                  {brands?.includes(brand.name) && <CheckCircleIcon />}
                </Grid>
                <Grid item container justify="center" alignItems="center">
                  <img
                    src={brand.imageUrl}
                    alt={brand.name}
                    style={{ maxHeight: 90, maxWidth: 90 }}
                  />
                </Grid>
              </Grid>
            </Paper>
          ))}
        </Grid>
      )}
      <Grid
        container
        justify="center"
        alignItems="center"
        style={{ padding: 16 }}
      >
        <Button
          disabled={previousTokens.length < 1 || loading}
          onClick={handlePrev}
        >
          <ArrowBackIosIcon />
        </Button>
        <Button disabled={!nextNextToken || loading} onClick={handleNext}>
          <ArrowForwardIosIcon />
        </Button>
      </Grid>
    </Grid>
  );
};

export default StyleBrands;
