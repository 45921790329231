import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  heading: {
    textAlign: "center",
    marginTop: 50,
    marginBottom: 15,
  },
}));

const Heading = ({ children }) => {
  const classes = useStyles();

  return (
    <Typography variant="h3" className={classes.heading}>
      {children}
    </Typography>
  );
};

export default Heading;
