import React from "react";
import { Link, useLocation } from "react-router-dom";
import { differenceInYears } from "date-fns";
import { Grid, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useRecoilValue } from "recoil";
import { authUserIdState } from "../../recoil/atoms";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 20,
    maxWidth: 800,
    border: "1px solid #DFC6BF",
  },

  title: {
    fontWeight: "bold",
    marginRight: 15,
  },
}));

const InchesFeetConvert = ({ height }) => {
  let feet = Math.floor(height / 12);
  let inches = height - feet * 12;

  return (
    <Typography variant="subtitle1">
      {feet}ft {inches > 0 ? `${inches}in` : ""}
    </Typography>
  );
};

const MeasurementsCard = ({ user, showFit }) => {
  const classes = useStyles();
  const location = useLocation();
  const authUserId = useRecoilValue(authUserIdState);

  return (
    <Paper className={classes.paper} elevation={0}>
      <Grid container spacing={2}>
        {showFit && (
          <Grid container item>
            <Typography variant="h5">{user?.firstName}'s fit:</Typography>
          </Grid>
        )}
        <Grid container item xs={12} sm={6}>
          <Typography className={classes.title} variant="subtitle1">
            Size:
          </Typography>
          <Typography variant="subtitle1">{user?.sizes?.join(", ")}</Typography>
        </Grid>
        <Grid container item xs={12} sm={6}>
          <Typography className={classes.title} variant="subtitle1">
            Bust:
          </Typography>
          <Typography variant="subtitle1">
            {user?.bustSizes?.join(", ")}
          </Typography>
        </Grid>
        <Grid container item xs={12} sm={6}>
          <Typography className={classes.title} variant="subtitle1">
            Height:
          </Typography>
          <InchesFeetConvert height={user?.height} />
        </Grid>
        <Grid container item xs={12} sm={6}>
          <Typography className={classes.title} variant="subtitle1">
            Age:
          </Typography>
          <Typography variant="subtitle1">
            {user.userBirthday
              ? Math.abs(
                  differenceInYears(new Date(user.userBirthday), new Date())
                )
              : ""}
          </Typography>
        </Grid>
        <Grid container item xs={12} sm={6}>
          <Typography className={classes.title} variant="subtitle1">
            Body Type:
          </Typography>
          <Typography variant="subtitle1">{user?.bodyType}</Typography>
        </Grid>
        <Grid container item xs={12} sm={6}>
          <Typography className={classes.title} variant="subtitle1">
            Styles:
          </Typography>
          <Typography variant="subtitle1">
            {user?.userStyles?.join(", ")}
          </Typography>
        </Grid>
        {location.pathname === "/profile" && user?.id === authUserId && (
          <Grid container justify="center" alignItems="center" item xs={12}>
            <Link
              to="./profile-edit"
              style={{
                textDecoration: "underline",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              <Typography variant="subtitle2">Edit Profile</Typography>
            </Link>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

export default MeasurementsCard;
