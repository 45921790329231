import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, Typography } from "@material-ui/core";
import Heading from "../../components/Heading/Heading";
import sizeOptions from "../../data/sizeOptions";
import bustSizeOptions from "../../data/bustSizeOptions";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: theme.spacing(1),
    width: 60,
    height: 60,
    textAlign: "center",
    border: "1px solid #DFC6BF",
  },
  active: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: theme.spacing(1),
    width: 60,
    height: 60,
    textAlign: "center",
    backgroundColor: "#DFC6BF",
    color: "black",
  },
}));

const BasicsSizes = ({ sizes, setSizes, bustSizes, setBustSizes }) => {
  const classes = useStyles();

  const handleSizeChange = (size) => {
    if (sizes?.includes(size)) {
      setSizes(sizes?.filter((s) => s !== size));
    } else {
      setSizes((prevSizes) => [...prevSizes, size]);
    }
  };

  const handleBustChange = (bust) => {
    if (bustSizes?.includes(bust)) {
      setBustSizes(bustSizes?.filter((b) => b !== bust));
    } else {
      setBustSizes((prevBustSizes) => [...prevBustSizes, bust]);
    }
  };

  return (
    <Grid
      container
      item
      xs={10}
      direction="column"
      justify="center"
      alignItems="center"
    >
      <Grid container item>
        <Grid item xs={12}>
          <Heading>
            Let’s get started with the basics. What size(s) do you normally
            wear?
          </Heading>
          <Typography variant="subtitle1" style={{ textAlign: "center" }}>
            Dresses & Pants
          </Typography>
        </Grid>
        <Grid
          item
          container
          direction="row"
          justify="center"
          alignItems="center"
        >
          {sizeOptions.map((size) => (
            <Paper
              key={size}
              className={sizes?.includes(size) ? classes.active : classes.paper}
              elevation={0}
              onClick={() => handleSizeChange(size)}
            >
              <Typography variant="h5">{size}</Typography>
            </Paper>
          ))}
        </Grid>
      </Grid>
      <Grid container item style={{ marginTop: 24 }}>
        <Grid item xs={12}>
          <Typography variant="subtitle1" style={{ textAlign: "center" }}>
            Bust Size
          </Typography>
        </Grid>
        <Grid
          item
          container
          direction="row"
          justify="center"
          alignItems="center"
        >
          {bustSizeOptions.map((bust) => (
            <Paper
              key={bust}
              className={
                bustSizes?.includes(bust) ? classes.active : classes.paper
              }
              elevation={0}
              onClick={() => handleBustChange(bust)}
            >
              <Typography variant="h6">{bust}</Typography>
            </Paper>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BasicsSizes;
