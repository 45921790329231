import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import { API, Storage } from "aws-amplify";
import { listUsers, listReviewsByCreatedAt } from "../../graphql/queries";
import Loading from "../../components/Loading/Loading";
import { startOfMonth, formatISO, endOfDay } from "date-fns";

const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState(null);
  const [reviews, setReviews] = useState(null);

  useEffect(() => {
    fetchUsers();
    fetchReviews();
  }, []);

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const userData = await API.graphql({
        query: listUsers,
        authMode: "API_KEY",
      });
      const usersCount = userData.data.listUsers.scannedCount;

      setUsers(usersCount);
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  const fetchReviews = async () => {
    try {
      setLoading(true);

      const reviewData = await API.graphql({
        query: listReviewsByCreatedAt,
        authMode: "API_KEY",
        variables: {
          createdAt: {
            between: [
              startOfMonth(new Date()),
              formatISO(endOfDay(new Date())),
            ],
          },
          sortDirection: "DESC",
          type: "Review",
        },
      });
      const reviewCount = reviewData.data.listReviewsByCreatedAt.scannedCount;
      setReviews(reviewCount);
      setLoading(false);
    } catch (error) {
      console.log("error fetching reviews:", error);
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Grid container direction="column" justify="center" alignItems="center">
          <Grid item xs={12}>
            <Typography variant="h5">Dashboard</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2">
              Total number of users: {users}
            </Typography>
          </Grid>
          {/* <Grid item xs={12}>
            <Typography variant="subtitle2">
              New users (weekly basis).
            </Typography>
          </Grid> */}
          <Grid item xs={12}>
            <Typography variant="subtitle2">
              Reviews since the start of this month: {reviews}
            </Typography>
          </Grid>
          {/* <Grid item xs={12}>
            <Typography variant="subtitle2">
              New reviews (weekly basis).
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2">
              Top 10 most liked reviews (every week).
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2">
              Top 10 most followed reviewers (every week).
            </Typography>
          </Grid> */}
        </Grid>
      )}
    </>
  );
};

export default Dashboard;
