import React from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Masonry from "react-masonry-css";
import ReviewCard from "../../components/ReviewCard/ReviewCard";
// import { Waypoint } from "react-waypoint";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(3, 0, 2, 0),
  },
  paper: {
    marginBottom: theme.spacing(4),
  },
  masonryGrid: {
    display: "flex",
    marginLeft: theme.spacing(-4),
    width: "inherit",
  },
  masonryColumn: {
    paddingLeft: theme.spacing(4),
    backgroundClip: "padding-box",
  },
}));

const propTypes = {
  children: PropTypes.node,
};

const ReviewsTable = ({ reviews, browse, handleNext, nextNextToken }) => {
  const classes = useStyles();
  const theme = useTheme();

  const breakpointCols = {
    default: 4,
    [theme.breakpoints.values.xl]: 4,
    [theme.breakpoints.values.lg]: 3,
    [theme.breakpoints.values.md]: 2,
    [theme.breakpoints.values.sm]: 1,
    [theme.breakpoints.values.xs]: 1,
  };

  // This is leftover from an attempt at infinite scrolling - WIP
  // const fetchMoreReviews = () => {
  //   if (!nextNextToken) {
  //     return;
  //   }
  //   handleNext();
  // };

  return (
    <Masonry
      breakpointCols={breakpointCols}
      className={classes.masonryGrid}
      columnClassName={classes.masonryColumn}
    >
      {reviews.map((review, index) => {
        return (
          <React.Fragment key={review.id}>
            <ReviewCard review={review} browse={browse} />
            {/* {index === reviews.length - 1 && (
              <Waypoint onEnter={() => fetchMoreReviews()} />
            )} */}
          </React.Fragment>
        );
      })}
    </Masonry>
  );
};

ReviewsTable.propTypes = propTypes;

export default ReviewsTable;
