import React, { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { Link } from "react-router-dom";
import { Typography, Grid, Drawer } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { brandsState, reviewSearchState } from "../../recoil/atoms";

import { API, Storage } from "aws-amplify";
import { getBrandsAlphabetically } from "../../graphql/queries";

import occasionOptions from "../../data/occasionOptions";
import clothingTypeOptions from "../../data/clothingTypeOptions";
import featuredBrands from "../../data/featuredBrands";

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  categoryContainer: {
    paddingTop: 30,
    paddingRight: 20,
    paddingBottom: 40,
    paddingLeft: 90,
  },
  categoryTitle: {
    fontWeight: 600,
  },
  itemLink: {
    textDecoration: "none",
    color: theme.palette.text.primary,
  },
  categoryItem: {
    paddingTop: 5,
    paddingBottom: 5,
  },
  categoryAll: {
    color: "#000000",
    cursor: "pointer",
    fontWeight: "bold",
    textDecoration: "underline",
  },
}));

const CategoryDrawer = ({ categoryDrawerOpen, setCategoryDrawerOpen }) => {
  const classes = useStyles();
  const setSearch = useSetRecoilState(reviewSearchState);
  const setBrands = useSetRecoilState(brandsState);

  useEffect(() => {
    fetchBrands();
  }, []);

  const fetchBrands = async () => {
    try {
      const brandData = await API.graphql({
        query: getBrandsAlphabetically,
        authMode: "API_KEY",
        variables: {
          type: "Brand",
          sortDirection: "ASC",
        },
      });

      const brandsArray = brandData.data.getBrandsAlphabetically.items;
      const signedBrandsArray = await Promise.all(
        brandsArray.map(async (item) => {
          const signedUrl = await Storage.get(item.image);
          item.imageUrl = signedUrl;
          return item;
        })
      );
      setBrands(signedBrandsArray);
    } catch (error) {
      console.log("error fetching brands:", error);
    }
  };

  return (
    <div>
      <Drawer
        style={{ zIndex: 1200 }}
        anchor="top"
        open={categoryDrawerOpen}
        onClose={() => setCategoryDrawerOpen(false)}
      >
        <div className={classes.toolbar} />
        <Grid
          onClick={() => setCategoryDrawerOpen(false)}
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
        >
          <Grid
            className={classes.categoryContainer}
            container
            item
            xs={12}
            md={4}
          >
            <Grid item xs={12}>
              <Typography variant="h6" className={classes.categoryTitle}>
                Brands
              </Typography>
            </Grid>
            {featuredBrands.map((item) => (
              <Grid key={item.id} item xs={6}>
                <Link
                  className={classes.itemLink}
                  to="/reviews"
                  onClick={() => setSearch(item.name)}
                >
                  <Typography
                    variant="subtitle1"
                    className={classes.categoryItem}
                  >
                    {item.name}
                  </Typography>
                </Link>
              </Grid>
            ))}
            <Grid item xs={6}></Grid>
            <Grid item xs={6}>
              <Link className={classes.itemLink} to="/brands">
                <Typography variant="subtitle1" className={classes.categoryAll}>
                  View All
                </Typography>
              </Link>
            </Grid>
          </Grid>
          <Grid
            className={classes.categoryContainer}
            container
            item
            xs={12}
            md={4}
          >
            <Grid item xs={12}>
              <Typography variant="h6" className={classes.categoryTitle}>
                Clothing Type
              </Typography>
            </Grid>
            {clothingTypeOptions.map((item) => (
              <Grid key={item.id} item xs={6}>
                <Link
                  className={classes.itemLink}
                  to="/reviews"
                  onClick={() => setSearch(item.name)}
                >
                  <Typography
                    variant="subtitle1"
                    className={classes.categoryItem}
                  >
                    {item.name}
                  </Typography>
                </Link>
              </Grid>
            ))}
            {/* <Grid item xs={6}></Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1" className={classes.categoryAll}>
                View All
              </Typography>
            </Grid> */}
          </Grid>
          <Grid
            className={classes.categoryContainer}
            container
            item
            xs={12}
            md={4}
          >
            <Grid item xs={12}>
              <Typography variant="h6" className={classes.categoryTitle}>
                Occasion
              </Typography>
            </Grid>
            {occasionOptions.map((item) => (
              <Grid key={item.id} item xs={6}>
                <Link
                  className={classes.itemLink}
                  to="/reviews"
                  onClick={() => setSearch(item.name)}
                >
                  <Typography
                    variant="subtitle1"
                    className={classes.categoryItem}
                  >
                    {item.name}
                  </Typography>
                </Link>
              </Grid>
            ))}
            {/* <Grid item xs={6}></Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1" className={classes.categoryAll}>
                View All
              </Typography>
            </Grid> */}
          </Grid>
        </Grid>
      </Drawer>
    </div>
  );
};

export default CategoryDrawer;
