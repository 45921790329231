import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Card,
  CardContent,
  Grid,
  TableCell,
  TableHead,
  Table,
  TableBody,
  TableRow,
  Typography,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { useSnackbar } from "notistack";
import Loading from "../../components/Loading/Loading";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import { API, graphqlOperation } from "aws-amplify";
import { listSuggestedBrandsByCreatedAt } from "../../graphql/queries";
import { deleteSuggestedBrand } from "../../graphql/mutations";

const useStyles = makeStyles(() => ({
  table: {
    maxWidth: 700,
  },
}));

const BrandTable = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [suggestedBrands, setSuggestedBrands] = useState([]);

  const [nextToken, setNextToken] = useState(undefined);
  const [nextNextToken, setNextNextToken] = useState();
  const [previousTokens, setPreviousTokens] = useState([]);

  const handleNext = () => {
    setPreviousTokens((prev) => [...prev, nextToken]);
    setNextToken(nextNextToken);
    setNextNextToken(null);
  };

  const handlePrev = () => {
    setNextToken(previousTokens.pop());
    setPreviousTokens([...previousTokens]);
    setNextNextToken(null);
  };

  useEffect(() => {
    const fetchSuggestedBrands = async () => {
      try {
        setLoading(true);

        const suggestedBrandData = await API.graphql({
          query: listSuggestedBrandsByCreatedAt,
          authMode: "API_KEY",
          variables: {
            type: "SuggestedBrand",
            sortDirection: "ASC",
            limit: 10,
            nextToken,
          },
        });
        setNextNextToken(
          suggestedBrandData.data.listSuggestedBrandsByCreatedAt.nextToken
        );
        const brandsArray =
          suggestedBrandData.data.listSuggestedBrandsByCreatedAt.items;

        setSuggestedBrands(brandsArray);
        setLoading(false);
      } catch (error) {
        console.log("error fetching brands:", error);
        setLoading(false);
      }
    };

    fetchSuggestedBrands();
  }, [nextToken]);

  const handleDeleteBrand = async (brandId) => {
    try {
      await API.graphql(
        graphqlOperation(deleteSuggestedBrand, { input: { id: brandId } })
      );

      const filterBrands = suggestedBrands.filter(
        (item) => item.id !== brandId
      );
      setSuggestedBrands(filterBrands);
      enqueueSnackbar(`Brand successfully deleted`);
    } catch (error) {
      console.log("error deleting brand", error);
    }
  };

  return (
    <div>
      {loading ? (
        <Loading />
      ) : suggestedBrands.length === 0 ? (
        <Card style={{ padding: 16, margin: 16 }}>
          <CardContent>
            <Typography variant="h6">
              There are no new brand suggestions.
            </Typography>
            <Typography variant="body2" component="p">
              Check back later to see users suggestions.
            </Typography>
          </CardContent>
        </Card>
      ) : (
        <>
          <Grid container justify="center" alignItems="center">
            <Table className={classes.table} size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Suggested Brand</TableCell>
                  <TableCell align="left">Date Created</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {suggestedBrands.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell component="th" scope="row">
                      {item.brandName}
                    </TableCell>
                    <TableCell align="left">
                      {format(new Date(item.createdAt), "MMMM dd yyyy")}
                    </TableCell>
                    <TableCell align="right">
                      <DeleteIcon onClick={() => handleDeleteBrand(item.id)} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{ padding: 16 }}
          >
            <Button
              disabled={previousTokens.length < 1 || loading}
              onClick={handlePrev}
            >
              <ArrowBackIosIcon />
            </Button>
            <Button disabled={!nextNextToken || loading} onClick={handleNext}>
              <ArrowForwardIosIcon />
            </Button>
          </Grid>
        </>
      )}
    </div>
  );
};

export default BrandTable;
