import React, { useState, useEffect } from "react";
import { API, Storage } from "aws-amplify";
import {
  getUser,
  reviewsByAuthorId,
  likesByUser,
  followersByUser,
  followingByUser,
} from "../../graphql/queries";

import UserProfile from "../../components/UserProfile/UserProfile";
import Loading from "../../components/Loading/Loading";

const UserShow = (props) => {
  const [loading, setLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState(
    props.location?.state?.user || {}
  );
  const [signedProfilePic, setSignedProfilePic] = useState("");
  const [reviews, setReviews] = useState([]);
  const [likes, setLikes] = useState([]);
  const [followers, setFollowers] = useState([]);
  const [following, setFollowing] = useState([]);

  const userId = props.match.params.id;

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const dbUser = await API.graphql({
          query: getUser,
          authMode: "API_KEY",
          variables: {
            id: userId,
          },
        });
        const userPic = dbUser.data.getUser.profilePicture;
        const signedPic = await Storage.get(userPic);
        setSignedProfilePic(signedPic);
        setCurrentUser(dbUser.data.getUser);
        getUserReviews(userId);
        getUserLikes(userId);
        getUserFollowers(userId);
        getUserFollowing(userId);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    })();
  }, [userId]);

  // Reviews
  // ====================================================================
  const getUserReviews = async (userId) => {
    try {
      const reviewData = await API.graphql({
        query: reviewsByAuthorId,
        authMode: "API_KEY",
        variables: {
          authorId: userId,
        },
      });
      const reviewsArray = reviewData.data.reviewsByAuthorId.items;
      const signedReviewsArray = await Promise.all(
        reviewsArray.map(async (item) => {
          const signedUrls = await Promise.all(
            item.garmentImages.map(async (image) => {
              const signedUrl = await Storage.get(image);
              return signedUrl;
            })
          );
          item.imageUrls = signedUrls;
          if (item.author.profilePicture) {
            const signedProfilePicture = await Storage.get(
              item.author.profilePicture
            );
            item.author.profilePictureUrl = signedProfilePicture;
          }
          return item;
        })
      );
      setReviews(signedReviewsArray);
    } catch (error) {
      console.log("error", error);
    }
  };

  // Likes
  // ====================================================================
  const getUserLikes = async (userId) => {
    try {
      const likeData = await API.graphql({
        query: likesByUser,
        authMode: "API_KEY",
        variables: {
          userId: userId,
        },
      });
      const likedReviewsArray = likeData.data.likesByUser.items;
      const signedLikedReviewsArray = await Promise.all(
        likedReviewsArray.map(async (item) => {
          const signedUrls = await Promise.all(
            item.review.garmentImages.map(async (image) => {
              const signedUrl = await Storage.get(image);
              return signedUrl;
            })
          );
          item.review.imageUrls = signedUrls;
          if (item.review.author.profilePicture) {
            const signedProfilePicture = await Storage.get(
              item.review.author.profilePicture
            );
            item.review.author.profilePictureUrl = signedProfilePicture;
          }
          return item.review;
        })
      );
      setLikes(signedLikedReviewsArray);
    } catch (error) {
      console.log("error", error);
    }
  };

  // Followers
  // ====================================================================
  const getUserFollowers = async (userId) => {
    try {
      const followersData = await API.graphql({
        query: followersByUser,
        authMode: "API_KEY",
        variables: {
          followingId: userId,
        },
      });
      const followersArray = followersData.data.followersByUser.items;
      const signedFollowersArray = await Promise.all(
        followersArray.map(async (item) => {
          const signedUrl = await Storage.get(item.follower.profilePicture);
          item.follower.imageUrl = signedUrl;
          return item.follower;
        })
      );
      setFollowers(signedFollowersArray);
    } catch (error) {
      console.log("error", error);
    }
  };

  // Following
  // ====================================================================
  const getUserFollowing = async (userId) => {
    try {
      const followingData = await API.graphql({
        query: followingByUser,
        authMode: "API_KEY",
        variables: {
          followerId: userId,
        },
      });
      const followingArray = followingData.data.followingByUser.items;
      const signedFollowingArray = await Promise.all(
        followingArray.map(async (item) => {
          const signedUrl = await Storage.get(item.following.profilePicture);
          item.following.imageUrl = signedUrl;
          return item.following;
        })
      );
      setFollowing(signedFollowingArray);
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <UserProfile
          user={currentUser}
          signedProfilePic={signedProfilePic}
          reviews={reviews}
          likes={likes}
          followers={followers}
          following={following}
        />
      )}
    </div>
  );
};

export default UserShow;
