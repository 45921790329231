import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Checkbox,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Typography,
  Paper,
  Avatar,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Favorite from "@material-ui/icons/Favorite";
import FavoriteBorder from "@material-ui/icons/FavoriteBorder";
import Carousel from "react-material-ui-carousel";

import MeasurementsCard from "../MeasurementsCard/MeasurementsCard";
import StarRatingDisplay from "../StarRatingDisplay/StarRatingDisplay";

const useStyles = makeStyles((theme) => ({
  side: {
    padding: theme.spacing(2),
  },
  img: {
    width: "100%",
  },
  title: {
    textTransform: "uppercase",
    fontWeight: "bold",
    marginRight: 10,
    fontSize: "15px",
  },
  thumbnail: { width: 65, padding: 5 },
  description: { lineHeight: "30px", letterSpacing: ".14px", fontSize: "17px" },
  name: {
    fontSize: "20px",
    textTransform: "uppercase",
    marginLeft: 10,
    color: theme.palette.text.primary,
    textDecoration: "none",
  },
  nameCard: { width: "100%" },
  paper: {
    width: "100%",
    padding: theme.spacing(2),
    margin: "16px auto",
  },
}));

const ReviewDisplay = ({
  open = false,
  handleClose,
  review,
  liked,
  handleLike,
  likes,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      scroll="body"
      open={open}
      onClose={handleClose}
    >
      <DialogContent>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid container>
          <Grid container item xs={12} sm={6} className={classes.side}>
            <Grid container justify="center" alignItems="center">
              <Carousel autoPlay={true} interval={4000} indicators={false}>
                {review?.imageUrls.map((image, index) => {
                  return (
                    <img
                      key={index}
                      className={classes.img}
                      alt="garment"
                      src={image}
                    />
                  );
                })}
              </Carousel>
            </Grid>
            <Grid container justify="flex-start" alignItems="center">
              {review?.imageUrls.map((url, index) => {
                return (
                  <img
                    key={index}
                    className={classes.thumbnail}
                    alt="garment thumbnail"
                    src={url}
                  />
                );
              })}
            </Grid>
            <Paper className={classes.paper}>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <Grid container item direction="column" xs={12} sm={9}>
                  <Link
                    to={{
                      pathname: `/user/show/${review.authorId}`,
                      state: {
                        user: review.author,
                      },
                    }}
                    style={{ textDecoration: "none" }}
                  >
                    <Grid
                      container
                      item
                      justify="flex-start"
                      alignItems="center"
                    >
                      <Avatar
                        alt={`${review?.author?.firstName} ${review?.author?.lastName}`}
                        src={review?.author?.profilePictureUrl}
                      />
                      <Typography variant="h5" className={classes.name}>
                        {`${review?.author?.firstName} ${review?.author?.lastName}`}
                      </Typography>
                    </Grid>
                  </Link>
                  <Grid
                    container
                    item
                    justify="flex-start"
                    alignItems="center"
                    style={{ marginTop: 15 }}
                  >
                    <Grid item>
                      <Typography variant="subtitle1" className={classes.title}>
                        followers:
                      </Typography>
                    </Grid>
                    <Grid item style={{ paddingRight: 20 }}>
                      <Typography variant="subtitle1">
                        {review?.author?.following?.items?.length}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle1" className={classes.title}>
                        reviews:
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle1">
                        {review?.author?.reviews?.items?.length}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container alignItems="center" item xs={12} sm={3}>
                  <Checkbox
                    icon={<FavoriteBorder />}
                    checkedIcon={<Favorite />}
                    name="favorite"
                    checked={liked}
                    onChange={() => handleLike(review)}
                  />
                  <Typography variant="subtitle1">
                    {likes} {likes === 1 ? "Like" : "Likes"}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid
            container
            item
            direction="column"
            justify="space-between"
            alignItems="flex-start"
            xs={12}
            sm={6}
            className={classes.side}
          >
            <Grid item container direction="column">
              <Typography variant="h3">{review?.garmentTitle}</Typography>
              <StarRatingDisplay rating={review?.garmentRating} />
            </Grid>

            <Grid item container direction="column" justify="space-between">
              <Typography variant="h4" style={{ fontWeight: 600 }}>
                {review?.garmentBrand}
              </Typography>
              <Typography variant="h5" style={{ fontWeight: 600 }}>
                {review?.garmentName}
              </Typography>
              <Typography variant="h6">{review?.garmentType}</Typography>
            </Grid>

            <Grid item container direction="column" justify="space-between">
              <Grid container item xs={12} sm={6}>
                <Typography className={classes.title} variant="subtitle1">
                  Size Worn:
                </Typography>
                <Typography variant="subtitle1">
                  {review?.garmentSize}
                </Typography>
              </Grid>
              <Grid container item xs={12} sm={6}>
                <Typography className={classes.title} variant="subtitle1">
                  Overall Fit:
                </Typography>
                <Typography variant="subtitle1">
                  {review?.garmentFit}
                </Typography>
              </Grid>
              <Grid container item xs={12} sm={6}>
                <Typography className={classes.title} variant="subtitle1">
                  Occasion:
                </Typography>
                <Typography variant="subtitle1">
                  {review?.garmentOccasion}
                </Typography>
              </Grid>
            </Grid>

            <Grid item container direction="column" justify="flex-start">
              <Typography className={classes.title} variant="h6">
                Overall Fit:
              </Typography>
              <Typography variant="subtitle2" className={classes.description}>
                {review?.garmentDetails}
              </Typography>
            </Grid>

            <Grid
              style={{ marginBottom: 16 }}
              item
              container
              direction="column"
              justify="center"
            >
              <MeasurementsCard user={review?.author} showFit={true} />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ReviewDisplay;
