import React, { useState } from "react";
import { useRecoilValue } from "recoil";
import { authUserIdState, brandsState } from "../../recoil/atoms";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  IconButton,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import HelpIcon from "@material-ui/icons/Help";
import CloseIcon from "@material-ui/icons/Close";
import { useSnackbar } from "notistack";
import { API, graphqlOperation } from "aws-amplify";
import { createSuggestedProduct } from "../../graphql/mutations";
import Loading from "../Loading/Loading";

const useStyles = makeStyles(() => ({}));

const SuggestedProductForm = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [productName, setProductName] = useState("");
  const [brandName, setBrandName] = useState("");
  const brandOptions = useRecoilValue(brandsState);
  const authUserId = useRecoilValue(authUserIdState);

  const [loading, setLoading] = useState(false);
  const [validation, setValidation] = useState({});

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setBrandName("");
    setProductName("");
    setValidation({});
  };

  // Submit Suggested Product
  //==============================================================================
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!validate()) {
      setLoading(false);
      return;
    }

    try {
      const productData = {
        authorId: authUserId,
        brandName,
        productName,
        type: "SuggestedProduct",
      };
      await API.graphql(
        graphqlOperation(createSuggestedProduct, { input: productData })
      );

      enqueueSnackbar("Thank you for your product suggestion.", {
        variant: "success",
      });
      setLoading(false);
      handleClose();
    } catch (error) {
      setLoading(false);
      console.warn(error);
      setValidation({ error: error.message });
      enqueueSnackbar(
        "There was an error, please review your form and try again.",
        {
          variant: "error",
        }
      );
    }
  };

  // Validate
  //==============================================================================

  const validate = () => {
    const validationErrors = {};

    if (!brandName) {
      validationErrors.brandName = "Brand is required";
    }

    if (!productName) {
      validationErrors.productName = "Product name is required";
    }

    if (Object.keys(validationErrors).length > 0) {
      setValidation(validationErrors);
      return false;
    }

    return true;
  };

  //==============================================================================
  return (
    <div>
      <IconButton variant="outlined" color="primary" onClick={handleClickOpen}>
        <HelpIcon />
      </IconButton>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <Grid container justify="flex-start" alignItems="center">
          <Grid item>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>

        <DialogTitle>Can’t find your product? Let us know.</DialogTitle>
        <DialogContent>
          <Grid item xs={12}>
            <FormControl
              fullWidth
              margin="dense"
              variant="outlined"
              error={validation?.brandName ? true : false}
            >
              <FormLabel component="legend" style={{ paddingBottom: 8 }}>
                Brand
              </FormLabel>
              <Autocomplete
                freeSolo
                autoSelect
                options={brandOptions.map((item) => item.name)}
                onChange={(event, newValue) => setBrandName(newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Brand Name"
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                )}
              />
              {validation?.brandName && (
                <FormHelperText>{validation?.brandName}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormLabel component="legend">Product Name</FormLabel>
            <TextField
              fullWidth
              margin="dense"
              variant="outlined"
              placeholder="Name of garment"
              autoComplete="off"
              value={productName}
              onChange={(event) => setProductName(event.target.value)}
              error={validation?.productName ? true : false}
              helperText={validation?.productName || ""}
            />
          </Grid>
        </DialogContent>
        <DialogActions className={classes.content}>
          <Grid container justify="center">
            {loading ? (
              <Loading />
            ) : (
              <Button
                variant="outlined"
                onClick={handleSubmit}
                className={classes.button}
              >
                Submit
              </Button>
            )}
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SuggestedProductForm;
