import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, TextField } from "@material-ui/core";
import UsersTable from "../../components/UsersTable/UsersTable";
import Loading from "../../components/Loading/Loading";
import SearchIcon from "@material-ui/icons/Search";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import { listUsers } from "../../graphql/queries";
import { API, Storage } from "aws-amplify";
import Heading from "../../components/Heading/Heading";

const useStyles = makeStyles((theme) => ({
  headingContainer: {
    paddingLeft: "35px",
  },
  subtitle: {
    marginBottom: 25,
  },
}));

const UserList = (props) => {
  const classes = useStyles();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState(search);

  const [nextToken, setNextToken] = useState(undefined);
  const [nextNextToken, setNextNextToken] = useState();
  const [previousTokens, setPreviousTokens] = useState([]);

  const handleNext = () => {
    setPreviousTokens((prev) => [...prev, nextToken]);
    setNextToken(nextNextToken);
    setNextNextToken(null);
  };

  const handlePrev = () => {
    setNextToken(previousTokens.pop());
    setPreviousTokens([...previousTokens]);
    setNextNextToken(null);
  };

  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedSearch(search);
    }, 700);
    return () => {
      clearTimeout(timerId);
    };
  }, [search]);

  useEffect(() => {
    const getUsers = async () => {
      try {
        setLoading(true);

        let variables = {
          limit: 40,
          nextToken,
        };

        if (debouncedSearch) {
          variables.filter = {
            searchField: { contains: debouncedSearch.toLowerCase() },
          };
        }

        const userData = await API.graphql({
          query: listUsers,
          authMode: "API_KEY",
          variables,
        });
        setNextNextToken(userData.data.listUsers.nextToken);
        const usersArray = userData.data.listUsers.items;
        const signedUsersArray = await Promise.all(
          usersArray.map(async (item) => {
            const signedUrl = await Storage.get(item.profilePicture);
            item.imageUrl = signedUrl;
            return item;
          })
        );
        setUsers(signedUsersArray);
        setLoading(false);
      } catch (error) {
        console.log("error", error);
        setLoading(false);
      }
    };
    getUsers();
  }, [nextToken, debouncedSearch]);

  return (
    <div>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="flex-start"
        className={classes.headingContainer}
      >
        <Grid item xs={12}>
          <Heading>
            {props.location?.state?.fromSurvey
              ? "Follow your friends and top reviewers!"
              : "Browse Reviewers"}
          </Heading>
        </Grid>
        <Grid item xs={12} style={{ paddingTop: 10, paddingBottom: 20 }}>
          <TextField
            id="search"
            variant="outlined"
            margin="dense"
            InputProps={{
              startAdornment: <SearchIcon style={{ color: "#D1D1D6" }} />,
            }}
            placeholder="Search for reviewers"
            autoComplete="off"
            value={search}
            onChange={(event) => setSearch(event.target.value)}
          />
        </Grid>
      </Grid>
      {loading ? <Loading /> : <UsersTable users={users} />}
      <Grid
        container
        justify="center"
        alignItems="center"
        style={{ padding: 16 }}
      >
        <Button
          disabled={previousTokens.length < 1 || loading}
          onClick={handlePrev}
        >
          <ArrowBackIosIcon />
        </Button>
        <Button disabled={!nextNextToken || loading} onClick={handleNext}>
          <ArrowForwardIosIcon />
        </Button>
      </Grid>
    </div>
  );
};

export default UserList;
