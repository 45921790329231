import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, TextField, Typography } from "@material-ui/core";
import ProductForm from "./ProductForm";
import ProductTable from "./ProductTable";
import SuggestedProductTable from "./SuggestedProductTable";
import SearchIcon from "@material-ui/icons/Search";
import Loading from "../../components/Loading/Loading";

import { API, graphqlOperation, Storage } from "aws-amplify";
import { listProducts } from "../../graphql/queries";
import { onCreateProduct } from "../../graphql/subscriptions";

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 600,
  },
  tableContainer: {
    margin: 8,
    width: "75%",
  },
  tableName: {
    padding: 8,
    margin: 8,
    fontWeight: 600,
  },
}));

const Products = () => {
  const classes = useStyles();
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);

  const [nextToken, setNextToken] = useState(undefined);
  const [nextNextToken, setNextNextToken] = useState();
  const [previousTokens, setPreviousTokens] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setLoading(true);

        const productData = await API.graphql({
          query: listProducts,
          authMode: "API_KEY",
          variables: {
            filter: { name: { contains: search } },
            limit: 15,
            nextToken,
          },
        });
        setNextNextToken(productData.data.listProducts.nextToken);
        const productsArray = productData.data.listProducts.items;
        const signedProductsArray = await Promise.all(
          productsArray.map(async (item) => {
            const signedUrl = await Storage.get(item.image);
            item.imageUrl = signedUrl;
            return item;
          })
        );

        setProducts(signedProductsArray);
        setLoading(false);
      } catch (error) {
        console.log("error fetching products:", error);
        setLoading(false);
      }
    };

    fetchProducts();

    const subscription = API.graphql(
      graphqlOperation(onCreateProduct)
    ).subscribe({
      next: async (product) => {
        const newProduct = product.value.data.onCreateProduct;
        const signedUrl = await Storage.get(newProduct.image);
        newProduct.imageUrl = signedUrl;
        setProducts((prevState) => [...prevState, newProduct]);
      },
    });
    return () => subscription.unsubscribe();
  }, [nextToken, search]);

  return (
    <Grid container direction="column" justify="center" alignItems="center">
      <Grid container item justify="space-between" alignItems="center">
        <Grid container item justify="flex-start" alignItems="center" xs={6}>
          <Grid item>
            <Typography variant="h5" className={classes.title}>
              Products
            </Typography>
          </Grid>
          <Grid item>
            <ProductForm />
          </Grid>
        </Grid>

        <Grid container item justify="flex-end" alignItems="center" xs={6}>
          <TextField
            id="search"
            InputProps={{
              startAdornment: <SearchIcon style={{ color: "#D1D1D6" }} />,
            }}
            variant="outlined"
            margin="dense"
            placeholder="Search"
            autoComplete="off"
            value={search}
            onChange={(event) => setSearch(event.target.value)}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.tableContainer}>
        <Paper>
          <Typography className={classes.tableName} variant="subtitle1">
            Recently Added
          </Typography>
          <SuggestedProductTable />
        </Paper>
      </Grid>
      <Grid item xs={12} className={classes.tableContainer}>
        <Paper>
          <Typography className={classes.tableName} variant="subtitle1">
            Approved
          </Typography>
          {loading ? (
            <Loading />
          ) : (
            <ProductTable
              products={products}
              setProducts={setProducts}
              loading={loading}
              nextToken={nextToken}
              setNextToken={setNextToken}
              nextNextToken={nextNextToken}
              setNextNextToken={setNextNextToken}
              previousTokens={previousTokens}
              setPreviousTokens={setPreviousTokens}
            />
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Products;
