const featuredBrands = [
  { id: 1, name: "Lululemon" },
  { id: 2, name: "Free People" },
  { id: 3, name: "H&M" },
  { id: 4, name: "Zara" },
  { id: 5, name: "Nike" },
  { id: 6, name: "Old Navy" },
  { id: 7, name: "Ralph Lauren" },
  { id: 8, name: "Madewell" },
  { id: 9, name: "J. Crew" },
  { id: 10, name: "Adidas" },
  { id: 11, name: "Banana Republic" },
  { id: 12, name: "Anthropologie" },
  { id: 13, name: "Ann Taylor" },
  { id: 14, name: "Calvin Klein" },
  { id: 15, name: "Forever 21" },
  { id: 16, name: "Gap" },
  { id: 17, name: "J. Jill" },
  { id: 18, name: "Urban Outfitters" },
];

export default featuredBrands;
