import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, Typography } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Heading from "../../components/Heading/Heading";
import bodyTypeOptions from "../../data/bodyTypeOptions";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: theme.spacing(1),
    width: 165,
    height: 200,
    textAlign: "center",
    border: "1px solid #DFC6BF",
  },
  active: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: theme.spacing(1),
    width: 165,
    height: 200,
    textAlign: "center",
    backgroundColor: "white",
    color: "#DFC6BF",
  },
}));

const BasicsBodyType = ({ bodyType, setBodyType }) => {
  const classes = useStyles();

  const handleBodyTypeChange = (type) => {
    setBodyType(type);
  };

  return (
    <Grid
      container
      item
      xs={10}
      direction="column"
      justify="center"
      alignItems="center"
    >
      <Grid item>
        <Heading>How would you describe your body type?</Heading>
      </Grid>
      <Grid item container direction="row" justify="center" alignItems="center">
        {bodyTypeOptions.map((type) => (
          <Paper
            key={type.id}
            className={type.name === bodyType ? classes.active : classes.paper}
            elevation={0}
            onClick={() => handleBodyTypeChange(type.name)}
          >
            <Grid container>
              <Grid item style={{ position: "absolute" }}>
                {type.name === bodyType && <CheckCircleIcon />}
              </Grid>
              <Grid
                item
                container
                direction="column"
                justify="flex-start"
                alignItems="center"
              >
                <Grid item>
                  <img
                    src={type.img.default}
                    alt={type.name}
                    style={{ height: 150 }}
                  />
                </Grid>
                <Grid item>
                  <Typography variant="subtitle2">{type.name}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        ))}
      </Grid>
    </Grid>
  );
};

export default BasicsBodyType;
