import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { reviewSearchState } from "../../recoil/atoms";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper } from "@material-ui/core";
import Loading from "../../components/Loading/Loading";
import { API, Storage } from "aws-amplify";
import { getBrandsAlphabetically } from "../../graphql/queries";
import Heading from "../../components/Heading/Heading";

const useStyles = makeStyles((theme) => ({
  headingContainer: {
    paddingLeft: "35px",
  },
  paper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: theme.spacing(1),
    width: 220,
    height: 110,
    textAlign: "center",
    border: "1px solid #DFC6BF",
  },
}));

const BrandsPage = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [brands, setBrands] = useState([]);
  const setSearch = useSetRecoilState(reviewSearchState);

  useEffect(() => {
    fetchBrands();
  }, []);

  const fetchBrands = async () => {
    try {
      setLoading(true);

      const brandData = await API.graphql({
        query: getBrandsAlphabetically,
        authMode: "API_KEY",
        variables: {
          type: "Brand",
          sortDirection: "ASC",
        },
      });

      const brandsArray = brandData.data.getBrandsAlphabetically.items;
      const signedBrandsArray = await Promise.all(
        brandsArray.map(async (item) => {
          const signedUrl = await Storage.get(item.image);
          item.imageUrl = signedUrl;
          return item;
        })
      );

      setBrands(signedBrandsArray);
      setLoading(false);
    } catch (error) {
      console.log("error fetching brands:", error);
      setLoading(false);
    }
  };

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <div>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="flex-start"
            className={classes.headingContainer}
          >
            <Grid item xs={12}>
              <Heading>Browse Brands</Heading>
            </Grid>
          </Grid>
          <Grid container justify="center" alignItems="center">
            {brands.map((brand) => (
              <Link
                key={brand.id}
                className={classes.brandLink}
                to="/reviews"
                onClick={() => setSearch(brand.name)}
              >
                <Paper key={brand.id} className={classes.paper} elevation={0}>
                  <Grid container>
                    <Grid item container justify="center" alignItems="center">
                      <img
                        src={brand.imageUrl}
                        alt={brand.name}
                        style={{ maxHeight: 90, maxWidth: 90 }}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Link>
            ))}
          </Grid>
        </div>
      )}
    </div>
  );
};

export default BrandsPage;
