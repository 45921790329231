import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useRecoilValue, useSetRecoilState, useRecoilState } from "recoil";
import {
  authUserIdState,
  authUserState,
  reviewSearchState,
} from "../../recoil/atoms";

import { Button, Typography, LinearProgress } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { v4 as uuid } from "uuid";
import { subYears } from "date-fns";

import Loading from "../../components/Loading/Loading";
import BasicsSizes from "./BasicsSizes";
import BasicsBodyType from "./BasicsBodyType";
import BasicsHeight from "./BasicsHeight";
import ProfileForm from "./ProfileForm";

import { API, graphqlOperation, Auth, Storage } from "aws-amplify";
import { getUser } from "../../graphql/queries";
import { updateUser } from "../../graphql/mutations";
import StyleBrands from "./StyleBrands";
import StyleTypes from "./StyleTypes";
import ConfirmAccount from "./ConfirmAccount";

const TopLinearProgress = withStyles(() => ({
  root: {
    height: 40,
  },
}))(LinearProgress);

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },

  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: "flex",
    justifyContent: "center",
  },
  button: {
    margin: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
    fontWeight: "bold",
    fontSize: 16,
  },
  noThanks: {},
}));

const ProfileSurvey = () => {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [validation, setValidation] = useState({});
  const [activeStep, setActiveStep] = useState(0);
  const authUserId = useRecoilValue(authUserIdState);
  const [authUser, setAuthUser] = useRecoilState(authUserState);
  const setSearch = useSetRecoilState(reviewSearchState);

  const [userId, setUserId] = useState("");

  const [authCode, setAuthCode] = useState("");

  const [password, setPassword] = useState("");
  const [sizes, setSizes] = useState([]);
  const [bustSizes, setBustSizes] = useState([]);
  const [bodyType, setBodyType] = useState("");
  const [height, setHeight] = useState(65);
  const [brands, setBrands] = useState([]);
  const [userStyles, setUserStyles] = useState([]);
  const [profilePicture, setProfilePicture] = useState("");
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [userLocation, setUserLocation] = useState("");
  const [userBirthday, setUserBirthday] = useState("");
  const [bio, setBio] = useState("");
  const [acceptTerms, setAcceptTerms] = useState(false);

  const [signedProfilePic, setSignedProfilePic] = useState("");

  const [pictureFile, setPictureFile] = useState({});
  const [files, setFiles] = useState([]);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  useEffect(() => {
    fetchCurrentUser();
  }, []);

  const fetchCurrentUser = async () => {
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      setLoading(true);
      const dbUser = await API.graphql(
        graphqlOperation(getUser, { id: currentUser.attributes.sub })
      );

      const userPic = dbUser.data.getUser.profilePicture;
      const signedPic = await Storage.get(userPic);
      setSignedProfilePic(signedPic);

      const user = dbUser.data.getUser;

      setUserId(user.id);
      setEmail(user.email);
      setFirstName(user.firstName);
      setLastName(user.lastName);
      setSizes(user.sizes || []);
      setBustSizes(user.bustSizes || []);
      setBodyType(user.bodyType || "");
      setHeight(user.height || 65);
      setBrands(user.brands || []);
      setUserStyles(user.userStyles || []);
      setProfilePicture(user.profilePicture || "");
      setPhone(user.phone || "");
      setUserLocation(user.userLocation || "");
      setUserBirthday(user.userBirthday || "");
      setBio(user.bio || "");
      setAcceptTerms(user.acceptTerms || false);
      setLoading(false);
    } catch (error) {
      if (error === "not authenticated") {
        setLoading(false);
        return;
      }
      console.log("error fetching current user:", error);
      setLoading(false);
    }
  };

  // Validate
  //==============================================================================
  const validate = () => {
    const validationErrors = {};

    if (activeStep === 5) {
      if (!email) {
        validationErrors.email = "Email is required";
      }

      if (!firstName) {
        validationErrors.firstName = "First Name is required";
      }

      if (!lastName) {
        validationErrors.lastName = "Last Name is required";
      }

      if (!userBirthday || userBirthday > subYears(new Date(), 13)) {
        validationErrors.userBirthday =
          "Birthday is required and you must be over 13.";
      }

      if (!password || password?.length < 8) {
        validationErrors.password =
          "Password is required and must be 8 characters.";
      }
      if (!acceptTerms) {
        validationErrors.acceptTerms =
          "Please accept our terms of use agreement.";
      }
    }

    if (activeStep === 6) {
      if (!email) {
        validationErrors.email = "Email is required";
      }

      if (!authCode) {
        validationErrors.authCode = "Please check your email for code.";
      }
    }

    if (Object.keys(validationErrors).length > 0) {
      setValidation(validationErrors);
      return false;
    }

    return true;
  };

  // Handle user skipping sign up
  //==============================================================================
  const handleSkipSignUp = () => {
    let searchString = "";

    // if (sizes.length > 0) {
    //   const sz = sizes.join(" ");
    //   searchString = searchString + " " + sz;
    // }
    // if (bustSizes.length > 0) {
    //   const bs = bustSizes.join(" ");
    //   searchString = searchString + " " + bs;
    // }
    // if (bodyType) {
    //   const bt = bodyType;
    //   searchString = searchString + " " + bt;
    // }
    // if (brands.length > 0) {
    //   const br = brands.join(" ");
    //   searchString = searchString + " " + br;
    // }
    // if (userStyles.length > 0) {
    //   const st = userStyles.join(" ");
    //   searchString = searchString + " " + st;
    // }
    setSearch(searchString);
    history.push("/reviews");
  };

  // Update Profile
  //==============================================================================
  const handleUpdateProfile = async () => {
    setLoading(true);

    const userData = {
      sizes: sizes.sort(),
      bustSizes: bustSizes.sort(),
      bodyType,
      height,
      brands,
      userStyles,
      firstName,
      lastName,
      email,
      phone,
      userLocation,
      userBirthday,
      bio,
      acceptTerms,
      searchField: `${firstName} ${lastName} ${userLocation} ${bodyType}`.toLowerCase(),
    };

    if (files.length === 1) {
      const imageKey =
        uuid() + pictureFile?.name?.replace(/\s/g, "-").toLowerCase();
      userData.profilePicture = imageKey;
      await Storage.put(imageKey, pictureFile);
    }

    try {
      await API.graphql(
        graphqlOperation(updateUser, { input: { id: userId, ...userData } })
      );

      setAuthUser({ ...authUser, ...userData });

      enqueueSnackbar("Beautiful!  You have set up your profile.", {
        variant: "success",
      });
      history.push("/users");
      setLoading(false);
    } catch (error) {
      console.log("error: ", error);
      setLoading(false);
      enqueueSnackbar(
        "There was an error, please review your profile form and try again.",
        {
          variant: "error",
        }
      );
    }
  };

  // Sign up
  //==============================================================================
  const handleSignUp = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!validate()) {
      setLoading(false);
      return;
    }

    try {
      await Auth.signUp({
        username: email.toLowerCase(),
        password,
        attributes: {
          email: email.toLowerCase(),
          given_name: firstName,
          family_name: lastName,
        },
      });
      setActiveStep(activeStep + 1);
      enqueueSnackbar("Please enter the code from your email.", {
        variant: "success",
      });
      setValidation({});
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      setValidation({ error: error.message });
      enqueueSnackbar("There was an error, please try again.", {
        variant: "error",
      });
    }
  };

  // Confirm Sign Up
  //==============================================================================
  const handleConfirmSignUp = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!validate()) {
      setLoading(false);
      return;
    }

    try {
      await Auth.confirmSignUp(email.toLowerCase(), authCode);
      await Auth.signIn(email.toLowerCase(), password);

      const currentUser = await Auth.currentAuthenticatedUser();
      const currentUserId = currentUser.attributes.sub;

      const userData = {
        sizes,
        bustSizes,
        bodyType,
        height,
        brands,
        userStyles,
        firstName,
        lastName,
        email,
        phone,
        userLocation,
        userBirthday,
        bio,
        acceptTerms,
      };

      if (files.length === 1) {
        const imageKey =
          uuid() + pictureFile?.name?.replace(/\s/g, "-").toLowerCase();
        userData.profilePicture = imageKey;
        await Storage.put(imageKey, pictureFile);
      }

      await API.graphql(
        graphqlOperation(updateUser, {
          input: { id: currentUserId, ...userData },
        })
      );

      const dbUser = await API.graphql(
        graphqlOperation(getUser, { id: currentUserId })
      );
      const userDetails = dbUser.data.getUser;

      const userPic = userDetails?.profilePicture;
      const signedPic = await Storage.get(userPic);

      userDetails.signedUrl = signedPic;

      setAuthUser(userDetails);

      enqueueSnackbar(
        "Beautiful!  You are all set to start sharing your clothing reviews.",
        {
          variant: "success",
        }
      );
      history.push({
        pathname: "/users",
        state: { fromSurvey: true },
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      setValidation({ error: error.message });
      enqueueSnackbar("There was an error, please try again.", {
        variant: "error",
      });
    }
  };

  // Render Step Content
  //==============================================================================

  function getStepContent() {
    switch (activeStep) {
      case 0:
        return (
          <BasicsSizes
            sizes={sizes}
            setSizes={setSizes}
            bustSizes={bustSizes}
            setBustSizes={setBustSizes}
          />
        );
      case 1:
        return <BasicsBodyType bodyType={bodyType} setBodyType={setBodyType} />;
      case 2:
        return <BasicsHeight height={height} setHeight={setHeight} />;
      case 3:
        return <StyleBrands brands={brands} setBrands={setBrands} />;
      case 4:
        return (
          <StyleTypes userStyles={userStyles} setUserStyles={setUserStyles} />
        );
      case 5:
        return (
          <ProfileForm
            profilePicture={profilePicture}
            setProfilePicture={setProfilePicture}
            firstName={firstName}
            setFirstName={setFirstName}
            lastName={lastName}
            setLastName={setLastName}
            email={email}
            setEmail={setEmail}
            password={password}
            setPassword={setPassword}
            phone={phone}
            setPhone={setPhone}
            userLocation={userLocation}
            setUserLocation={setUserLocation}
            userBirthday={userBirthday}
            setUserBirthday={setUserBirthday}
            bio={bio}
            setBio={setBio}
            acceptTerms={acceptTerms}
            setAcceptTerms={setAcceptTerms}
            signedProfilePic={signedProfilePic}
            files={files}
            setFiles={setFiles}
            pictureFile={pictureFile}
            setPictureFile={setPictureFile}
            validation={validation}
          />
        );
      case 6:
        return (
          <ConfirmAccount
            email={email}
            setEmail={setEmail}
            authCode={authCode}
            setAuthCode={setAuthCode}
            handleConfirmSignUp={handleConfirmSignUp}
            validation={validation}
          />
        );
      default:
        return <h3>Nothing to see here :)</h3>;
    }
  }

  return (
    <main>
      <TopLinearProgress variant="determinate" value={(activeStep / 7) * 100} />
      {loading ? (
        <Loading />
      ) : (
        <>
          <Grid container>
            <Grid item xs></Grid>
            {getStepContent()}
            <Grid item xs></Grid>
          </Grid>
          <Grid
            container
            item
            direction="column"
            justify="center"
            alignItems="center"
            className={classes.buttons}
          >
            {activeStep < 6 && (
              <Grid item container justify="center" alignItems="center">
                {activeStep !== 0 && (
                  <Button onClick={handleBack} className={classes.button}>
                    Back
                  </Button>
                )}
                {activeStep === 5 ? (
                  <Button
                    variant="outlined"
                    onClick={authUserId ? handleUpdateProfile : handleSignUp}
                    className={classes.button}
                  >
                    Start Exploring
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    onClick={handleNext}
                    className={classes.button}
                  >
                    Continue
                  </Button>
                )}
              </Grid>
            )}
            {!authUserId && activeStep === 5 && (
              <Grid
                container
                item
                justify="center"
                alignItems="center"
                style={{ padding: 8 }}
              >
                <Button className={classes.noThanks} onClick={handleSkipSignUp}>
                  <Typography variant="subtitle1">
                    No thanks, I want to browse without creating a profile
                  </Typography>
                </Button>
              </Grid>
            )}
          </Grid>
        </>
      )}
    </main>
  );
};

export default ProfileSurvey;
