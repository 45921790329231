const bodyTypeOptions = [
  {
    id: 1,
    name: "Apple",
    img: require("../assets/images/bodyTypes/Apple_color.svg"),
  },
  {
    id: 2,
    name: "Athletic",
    img: require("../assets/images/bodyTypes/Athletic_color.svg"),
  },
  {
    id: 3,
    name: "Curvy",
    img: require("../assets/images/bodyTypes/Curvy_color.svg"),
  },
  {
    id: 4,
    name: "Hourglass",
    img: require("../assets/images/bodyTypes/Hourglass_color.svg"),
  },
  {
    id: 5,
    name: "Pear",
    img: require("../assets/images/bodyTypes/Pear_color.svg"),
  },
  {
    id: 6,
    name: "Petite",
    img: require("../assets/images/bodyTypes/Petite_color.svg"),
  },
  {
    id: 7,
    name: "Straight",
    img: require("../assets/images/bodyTypes/Straight_color.svg"),
  },
];

export default bodyTypeOptions;
