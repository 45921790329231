import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  signUpModalOpenState,
  authUserIdState,
  createReviewModalOpenState,
  reviewToEditState,
} from "../../recoil/atoms";
import {
  Avatar,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardMedia,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import { useSnackbar } from "notistack";

// Icons
import Favorite from "@material-ui/icons/Favorite";
import FavoriteBorder from "@material-ui/icons/FavoriteBorder";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

// Components
import StarRatingDisplay from "../StarRatingDisplay/StarRatingDisplay";
import ReviewDisplay from "../ReviewDisplay/ReviewDisplay";

// Amplify
import { API, graphqlOperation } from "aws-amplify";
import {
  deleteReview,
  createReviewLike,
  deleteReviewLike,
} from "../../graphql/mutations";
import { likesByUser, getReview } from "../../graphql/queries";

const useStyles = makeStyles((theme) => ({
  mediaArea: {
    position: "relative",
    minHeight: 200,
    "&:hover": {
      "& $media": {
        opacity: 0.1,
      },
      "& $overlay": {
        opacity: 1,
      },
    },
  },
  media: {
    opacity: 1,
    transition: ".25s ease",
  },
  overlay: {
    position: "absolute",
    top: "40%",
    opacity: 0,
  },
  avatar: {
    height: 28,
    width: 28,
    margin: 4,
  },
  name: {
    textTransform: "uppercase",
    color: theme.palette.text.primary,
    textDecoration: "none",
    "&:hover": {
      color: "#DFC6BF",
    },
  },
}));

const ReviewCard = ({ review, browse = false }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  let location = useLocation();
  const [liked, setLiked] = useState(false);
  const [likes, setLikes] = useState(review?.likes?.items?.length);
  const [open, setOpen] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const authUserId = useRecoilValue(authUserIdState);

  const setSignUpModalOpen = useSetRecoilState(signUpModalOpenState);
  const setCreateReviewModalOpen = useSetRecoilState(
    createReviewModalOpenState
  );
  const setReviewToEdit = useSetRecoilState(reviewToEditState);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenConfirmation = () => {
    setConfirmationOpen(true);
  };

  const handleCloseConfirmation = () => {
    setConfirmationOpen(false);
  };

  useEffect(() => {
    const fetchUserLikes = async () => {
      if (!authUserId) {
        return;
      }

      const userLikes = await API.graphql({
        query: likesByUser,
        variables: {
          userId: authUserId,
        },
      });

      const likesArr = userLikes.data.likesByUser.items.filter(
        (item) => item.reviewId === review.id
      );

      if (likesArr.length > 0) {
        setLiked(true);
      }
    };

    fetchUserLikes();
  }, [authUserId, review]);

  const handleLike = async (review) => {
    if (!authUserId) {
      setSignUpModalOpen(true);
      return;
    }

    setLiked(!liked);

    const checkReview = await API.graphql({
      query: getReview,
      variables: {
        id: review.id,
      },
    });

    if (!checkReview.data.getReview) {
      console.log("review no longer exists");
      return;
    }

    const userLikes = await API.graphql({
      query: likesByUser,
      variables: {
        userId: authUserId,
      },
    });

    const idx = userLikes.data.likesByUser.items
      .map((item) => {
        return item.reviewId;
      })
      .indexOf(review.id);

    if (!liked) {
      if (idx >= 0) {
        console.log("you are already liking this review");
        return;
      }
      try {
        await API.graphql(
          graphqlOperation(createReviewLike, {
            input: {
              userId: authUserId,
              reviewId: review.id,
            },
          })
        );
        setLikes((prevLikes) => prevLikes + 1);
      } catch (error) {
        console.log(error);
      }
    }

    if (liked) {
      const removeLike = userLikes.data.likesByUser.items.filter(
        (item) => item.reviewId === review.id
      );

      if (removeLike.length === 0) {
        console.log("not currently liking this review so you can not unlike");
        return;
      }

      if (removeLike.length > 0) {
        try {
          await API.graphql(
            graphqlOperation(deleteReviewLike, {
              input: {
                id: removeLike[0].id,
              },
            })
          );
          setLikes((prevLikes) => prevLikes - 1);
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  const handleEditReview = () => {
    setReviewToEdit(review);
    setCreateReviewModalOpen(true);
  };

  const handleDeleteReview = async () => {
    setConfirmationOpen(false);

    try {
      const rli = review.likes.items;

      // delete the review likes if there are any
      if (review.likes.items.length > 0) {
        await Promise.all(
          rli.map(async (item) => {
            await API.graphql(
              graphqlOperation(deleteReviewLike, {
                input: item,
              })
            );
          })
        );
      }

      // delete the review
      await API.graphql(
        graphqlOperation(deleteReview, { input: { id: review.id } })
      );

      enqueueSnackbar(`Review successfully deleted`, {
        variant: "success",
      });
    } catch (error) {
      console.log("error deleting review", error);
      enqueueSnackbar(`There was an error when trying to delete your review.`, {
        variant: "error",
      });
    }
  };

  return (
    <div>
      <Card
        style={{
          width: 300,
          margin: 8,
        }}
      >
        <CardActionArea onClick={handleClickOpen} className={classes.mediaArea}>
          <CardMedia
            component="img"
            alt={`${review.garmentColor} ${review.garmentBrand} ${review.garmentName}`}
            height={browse ? "" : "325"}
            image={review?.imageUrls[0]}
            title={`${review?.garmentBrand} ${review?.garmentName}`}
            className={classes.media}
          />
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            className={classes.overlay}
          >
            <Grid item xs={12}>
              <StarRatingDisplay rating={review.garmentRating} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" style={{ padding: 8 }}>
                {review.garmentTitle}
              </Typography>
            </Grid>
          </Grid>
        </CardActionArea>
        <CardActions style={{ padding: 4 }}>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="flex-start"
          >
            <Grid item xs={12} style={{ paddingLeft: 14 }}>
              <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
                {review.garmentBrand}
              </Typography>
            </Grid>

            <Grid
              container
              justify="space-between"
              alignItems="center"
              item
              xs={12}
              style={{ paddingLeft: 10 }}
            >
              <Link
                to={{
                  pathname: `/user/show/${review.authorId}`,
                  state: {
                    user: review.author,
                  },
                }}
                style={{ textDecoration: "none" }}
              >
                <Grid container justify="flex-start" alignItems="center">
                  <Avatar
                    alt={`${review?.author?.firstName} ${review?.author?.lastName}`}
                    src={review?.author?.profilePictureUrl}
                    className={classes.avatar}
                  />
                  <Typography variant="subtitle2" className={classes.name}>
                    {`${review?.author?.firstName} ${review?.author?.lastName}`}
                  </Typography>
                </Grid>
              </Link>
            </Grid>

            <Grid
              item
              container
              xs={12}
              justify="space-between"
              alignItems="center"
              style={{ paddingLeft: 10 }}
            >
              <Grid item xs container justify="flex-start" alignItems="center">
                <Checkbox
                  icon={<FavoriteBorder />}
                  checkedIcon={<Favorite />}
                  name="favorite"
                  checked={liked}
                  onChange={() => handleLike(review)}
                  style={{ padding: 4 }}
                />
                <Typography variant="subtitle2">
                  {likes} {likes === 1 ? "Like" : "Likes"}
                </Typography>
              </Grid>
              {authUserId === review?.authorId &&
                location.pathname !== "/reviews" && (
                  <Grid
                    item
                    xs={4}
                    container
                    justify="center"
                    alignItems="center"
                  >
                    <IconButton onClick={handleEditReview}>
                      <EditIcon fontSize="small" />
                    </IconButton>
                    <IconButton onClick={handleOpenConfirmation}>
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Grid>
                )}
            </Grid>
          </Grid>
        </CardActions>

        <Dialog open={confirmationOpen} onClose={handleCloseConfirmation}>
          <DialogTitle>Are you sure you want to Delete?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Deleting this review will remove it from the site forever, this
              can not be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              onClick={handleCloseConfirmation}
            >
              Cancel
            </Button>
            <Button color="primary" onClick={handleDeleteReview}>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Card>
      {open && (
        <ReviewDisplay
          review={review}
          open={open}
          handleClose={handleClose}
          liked={liked}
          handleLike={handleLike}
          likes={likes}
        />
      )}
    </div>
  );
};

export default ReviewCard;
