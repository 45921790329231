import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, Typography } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Heading from "../../components/Heading/Heading";
import styleOptions from "../../data/styleOptions";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: theme.spacing(1),
    width: 165,
    height: 200,
    textAlign: "center",
    border: "1px solid #DFC6BF",
  },
  active: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: theme.spacing(1),
    width: 165,
    height: 200,
    textAlign: "center",
    backgroundColor: "white",
    color: "#DFC6BF",
  },
}));

const StyleTypes = ({ userStyles, setUserStyles }) => {
  const classes = useStyles();

  const handleStyleChange = (style) => {
    if (userStyles?.includes(style)) {
      setUserStyles(userStyles.filter((s) => s !== style));
    } else {
      setUserStyles((prevStyles) => [...prevStyles, style]);
    }
  };

  return (
    <Grid
      container
      item
      xs={10}
      direction="column"
      justify="center"
      alignItems="center"
    >
      <Grid item>
        <Heading>
          Tell us about your style! What do you find yourself in?
        </Heading>
      </Grid>
      <Grid item container direction="row" justify="center" alignItems="center">
        {styleOptions.map((style) => (
          <Paper
            key={style.id}
            className={
              userStyles?.includes(style.name) ? classes.active : classes.paper
            }
            elevation={0}
            onClick={() => handleStyleChange(style.name)}
          >
            <Grid container>
              <Grid item style={{ position: "absolute" }}>
                {userStyles?.includes(style.name) && <CheckCircleIcon />}
              </Grid>
              <Grid
                item
                container
                direction="column"
                justify="flex-start"
                alignItems="center"
              >
                <Grid item>
                  <img
                    src={style.img.default}
                    alt={style.name}
                    style={{ height: 150 }}
                  />
                </Grid>
                <Grid item>
                  <Typography variant="subtitle2">{style.name}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        ))}
      </Grid>
    </Grid>
  );
};

export default StyleTypes;
