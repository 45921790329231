import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  TableCell,
  TableHead,
  Table,
  TableBody,
  TableRow,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import LinkIcon from "@material-ui/icons/Link";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { useSnackbar } from "notistack";

import { API, graphqlOperation } from "aws-amplify";
import { deleteProduct } from "../../graphql/mutations";

const useStyles = makeStyles((theme) => ({
  table: {
    maxWidth: 700,
  },
}));

const ProductTable = ({
  products,
  setProducts,
  loading,
  nextToken,
  setNextToken,
  nextNextToken,
  setNextNextToken,
  previousTokens,
  setPreviousTokens,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(false);
  const [product, setProduct] = useState({});

  const handleNext = () => {
    setPreviousTokens((prev) => [...prev, nextToken]);
    setNextToken(nextNextToken);
    setNextNextToken(null);
  };

  const handlePrev = () => {
    setNextToken(previousTokens.pop());
    setPreviousTokens([...previousTokens]);
    setNextNextToken(null);
  };

  const handleOpen = (product) => {
    setOpen(true);
    setProduct(product);
  };

  const handleClose = () => {
    setOpen(false);
    setProduct({});
  };

  const handleDeleteProduct = async () => {
    setOpen(false);

    try {
      await API.graphql(
        graphqlOperation(deleteProduct, { input: { id: product.id } })
      );

      const filterProducts = products.filter((item) => item.id !== product.id);
      setProducts(filterProducts);
      setProduct({});
      enqueueSnackbar(`Product successfully deleted`);
    } catch (error) {
      console.log("error deleting product", error);
    }
  };

  return (
    <>
      <Grid container justify="center" alignItems="center">
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell>Product Name</TableCell>
              <TableCell align="right">Brand</TableCell>
              <TableCell align="right">Type</TableCell>
              <TableCell align="right">Link</TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products.map((item) => (
              <TableRow key={item.id}>
                <TableCell component="th" scope="row">
                  {item.name}
                </TableCell>
                <TableCell align="right">{item.brand}</TableCell>
                <TableCell align="right">{item.type}</TableCell>
                <TableCell align="right">
                  {item.link && (
                    <a href={item.link} target="blank">
                      <LinkIcon />
                    </a>
                  )}
                </TableCell>
                <TableCell align="right">
                  <img
                    style={{ height: 50 }}
                    src={item.imageUrl}
                    alt={`${item.brand} ${item.name}`}
                  />
                </TableCell>
                <TableCell align="right">
                  <DeleteIcon onClick={() => handleOpen(item)} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Are you sure you want to Delete?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Deleting this product will remove it from the site.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" onClick={handleClose}>
              Cancel
            </Button>
            <Button color="primary" onClick={() => handleDeleteProduct()}>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
      <Grid
        container
        justify="center"
        alignItems="center"
        style={{ padding: 16 }}
      >
        <Button
          disabled={previousTokens.length < 1 || loading}
          onClick={handlePrev}
        >
          <ArrowBackIosIcon />
        </Button>
        <Button disabled={!nextNextToken || loading} onClick={handleNext}>
          <ArrowForwardIosIcon />
        </Button>
      </Grid>
    </>
  );
};

export default ProductTable;
