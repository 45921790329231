import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import { API, Storage } from "aws-amplify";
import { listUsers } from "../../graphql/queries";
import Loading from "../../components/Loading/Loading";

const Users = () => {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const userData = await API.graphql({
        query: listUsers,
        authMode: "API_KEY",
      });
      const usersArray = userData.data.listUsers.items;
      const signedUsersArray = await Promise.all(
        usersArray.map(async (item) => {
          const signedUrl = await Storage.get(item.profilePicture);
          item.imageUrl = signedUrl;
          return item;
        })
      );
      setUsers(signedUsersArray);
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Grid container direction="column" justify="center" alignItems="center">
          <Grid item xs={12}>
            <Typography variant="h5">Users</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2">
              Total number of users: {users.length}
            </Typography>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default Users;
