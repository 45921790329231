import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ContactFrom from "./ContactFrom";

const useStyles = makeStyles((theme) => ({
  title: { padding: theme.spacing(2) },
  paragraph: { padding: "20px 0" },
}));

const ContactUsPage = () => {
  const classes = useStyles();

  return (
    <div>
      <Grid container>
        <Grid item xs md></Grid>
        <Grid container direction="column" justify="center" item xs={10}>
          <Grid
            item
            container
            justify="center"
            alignItems="center"
            className={classes.title}
          >
            <Typography variant="h3" className={classes.h3}>
              Contact Us
            </Typography>
          </Grid>

          <Grid item>
            <ContactFrom />
          </Grid>
          {/* <Grid item className={classes.paragraph}>
            <a
              style={{ color: "black", textDecoration: "none", fontSize: 18 }}
              href="mailto:wewearreview@gmail.com"
            >
              wewearreview@gmail.com
            </a>
          </Grid> */}
        </Grid>
        <Grid item xs md></Grid>
      </Grid>
    </div>
  );
};

export default ContactUsPage;
