import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { Grid, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";

import SignUp from "./SignUp";
import ConfirmSignUp from "./ConfirmSignUp";
import SignIn from "./SignIn";
import ForgotPassword from "./ForgotPassword";
import ForgotPasswordSubmit from "./ForgotPasswordSubmit";
import Loading from "../../components/Loading/Loading";
import WeWearHangers from "../../assets/images/wewear-hangers.png";
import ResendCode from "./ResendCode";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: `url(${WeWearHangers})`,
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const AuthForm = (props) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [authCode, setAuthCode] = useState("");

  const [formType, setFormType] = useState(
    props.location?.state?.formType || "signIn"
  );
  const [validation, setValidation] = useState({});
  const [loading, setLoading] = useState(false);

  // Validate
  //==============================================================================

  const validate = () => {
    const validationErrors = {};

    if (formType === "signUp") {
      if (!email) {
        validationErrors.email = "Email is required";
      }

      if (!firstName) {
        validationErrors.firstName = "First Name is required";
      }

      if (!lastName) {
        validationErrors.lastName = "Last Name is required";
      }

      if (!password || password?.length < 8) {
        validationErrors.password =
          "Password is required and must be 8 characters.";
      }
    }

    if (formType === "confirmSignUp") {
      if (!email) {
        validationErrors.email = "Email is required";
      }

      if (!authCode) {
        validationErrors.authCode = "Please check your email for code.";
      }
    }

    if (formType === "resendCode") {
      if (!email) {
        validationErrors.email = "Email is required";
      }
    }

    if (formType === "signIn") {
      if (!email) {
        validationErrors.email = "Email is required";
      }

      if (!password || password?.length < 8) {
        validationErrors.password =
          "Password is required and must be 8 characters.";
      }
    }

    if (formType === "forgotPassword") {
      if (!email) {
        validationErrors.email = "Email is required";
      }
    }

    if (formType === "forgotPasswordSubmit") {
      if (!email) {
        validationErrors.email = "Email is required";
      }

      if (!password || password?.length < 8) {
        validationErrors.password =
          "Password is required and must be 8 characters.";
      }

      if (!authCode) {
        validationErrors.authCode = "Please check your email for code.";
      }
    }

    if (Object.keys(validationErrors).length > 0) {
      setValidation(validationErrors);
      return false;
    }

    return true;
  };

  // Sign Up
  //==============================================================================

  const handleSignUp = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!validate()) {
      setLoading(false);
      return;
    }

    try {
      await Auth.signUp({
        username: email.toLowerCase(),
        password,
        attributes: {
          email: email.toLowerCase(),
          given_name: firstName,
          family_name: lastName,
        },
      });
      setFormType("confirmSignUp");
      enqueueSnackbar("Success!", {
        variant: "success",
      });
      setValidation({});
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("error signing up a user", error);
      setValidation({ error: error.message });
      enqueueSnackbar(`${error.message}`, {
        variant: "error",
      });
      if (
        error.code === "UserNotConfirmedException" ||
        error.code === "UsernameExistsException"
      ) {
        await Auth.resendSignUp(email.toLowerCase());
        setFormType("confirmSignUp");
        enqueueSnackbar("Please check your email for a confirmation code.", {
          variant: "success",
        });
        return;
      }
    }
  };

  // Confirm Sign Up
  //==============================================================================

  const handleConfirmSignUp = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!validate()) {
      setLoading(false);
      return;
    }

    try {
      await Auth.confirmSignUp(email.toLowerCase(), authCode);
      await Auth.signIn(email.toLowerCase(), password);
      enqueueSnackbar("Success!", {
        variant: "success",
      });
      setFormType("signIn");
      setValidation({});
      setLoading(false);
      props.history.push("/profile-survey");
    } catch (error) {
      setLoading(false);
      console.log("error confirming sign up", error);
      if (
        error.code === "InvalidParameterException" ||
        error.code === "NotAuthorizedException"
      ) {
        setFormType("signIn");
        return;
      }

      setValidation({ error: error.message });
      enqueueSnackbar(error.message, {
        variant: "error",
      });
    }
  };

  // Sign In
  //==============================================================================
  const handleSignIn = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!validate()) {
      setLoading(false);
      return;
    }

    try {
      await Auth.signIn(email.toLowerCase(), password);

      setValidation({});
      setLoading(false);

      props.history.push("/reviews");
    } catch (error) {
      setLoading(false);
      console.log("error on sign in", error);
      setValidation({ error: error.message });
      enqueueSnackbar(error.message, {
        variant: "error",
      });
      if (error.code === "UserNotConfirmedException") {
        await Auth.resendSignUp(email.toLowerCase());
        setFormType("confirmSignUp");
        enqueueSnackbar("Please check your email for a confirmation code.", {
          variant: "success",
        });
        return;
      }
    }
  };

  // Forgot Password
  //==============================================================================

  const handleForgotPassword = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!validate()) {
      setLoading(false);
      return;
    }

    try {
      await Auth.forgotPassword(email.toLowerCase());
      setValidation({});
      setLoading(false);
      setFormType("forgotPasswordSubmit");
    } catch (error) {
      setLoading(false);
      console.log("error on forgot password", error);
      setValidation({ error: error.message });
      enqueueSnackbar(error.message, {
        variant: "error",
      });
    }
  };

  // Forgot Password Submit
  //==============================================================================

  const handleForgotPasswordSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!validate()) {
      setLoading(false);
      return;
    }

    try {
      await Auth.forgotPasswordSubmit(email.toLowerCase(), authCode, password);
      await Auth.signIn(email.toLowerCase(), password);
      setValidation({});
      setLoading(false);
      setFormType("signIn");
      props.history.push("/reviews");
    } catch (error) {
      setLoading(false);
      console.log("error on forgot password submit", error);
      setValidation({ error: error.message });
      enqueueSnackbar(error.message, {
        variant: "error",
      });
    }
  };

  // Resend Confirmation Code
  //==============================================================================

  const handleResendCode = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!validate()) {
      setLoading(false);
      return;
    }

    try {
      await Auth.resendSignUp(email.toLowerCase());
      setFormType("confirmSignUp");
      enqueueSnackbar("Please check your email for a confirmation code.", {
        variant: "success",
      });
      setFormType("confirmSignUp");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("error on resend code", error);
      setValidation({ error: error.message });
      enqueueSnackbar(error.message, {
        variant: "error",
      });
    }
  };

  // Render Auth Form
  //==============================================================================

  function renderForm() {
    switch (formType) {
      case "signUp":
        return (
          <SignUp
            firstName={firstName}
            setFirstName={setFirstName}
            lastName={lastName}
            setLastName={setLastName}
            email={email}
            setEmail={setEmail}
            password={password}
            setPassword={setPassword}
            handleSignUp={handleSignUp}
            setFormType={setFormType}
            validation={validation}
          />
        );
      case "confirmSignUp":
        return (
          <ConfirmSignUp
            email={email}
            setEmail={setEmail}
            authCode={authCode}
            setAuthCode={setAuthCode}
            handleConfirmSignUp={handleConfirmSignUp}
            validation={validation}
          />
        );
      case "resendCode":
        return (
          <ResendCode
            email={email}
            setEmail={setEmail}
            handleResendCode={handleResendCode}
            setFormType={setFormType}
            validation={validation}
          />
        );
      case "signIn":
        return (
          <SignIn
            email={email}
            setEmail={setEmail}
            password={password}
            setPassword={setPassword}
            handleSignIn={handleSignIn}
            setFormType={setFormType}
            validation={validation}
          />
        );
      case "forgotPassword":
        return (
          <ForgotPassword
            email={email}
            setEmail={setEmail}
            handleForgotPassword={handleForgotPassword}
            setFormType={setFormType}
            validation={validation}
          />
        );
      case "forgotPasswordSubmit":
        return (
          <ForgotPasswordSubmit
            email={email}
            password={password}
            authCode={authCode}
            setPassword={setPassword}
            setAuthCode={setAuthCode}
            handleForgotPasswordSubmit={handleForgotPasswordSubmit}
            validation={validation}
          />
        );
      default:
        return null;
    }
  }

  return (
    <Grid container component="main" className={classes.root}>
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={0} square>
        {loading ? <Loading /> : renderForm()}
      </Grid>
    </Grid>
  );
};

export default AuthForm;
