import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  title: { padding: theme.spacing(2) },
  paragraph: { padding: "8px 0" },
}));

const TermsPage = () => {
  const classes = useStyles();

  return (
    <div>
      <Grid container>
        <Grid item xs md></Grid>
        <Grid container direction="column" justify="center" item xs={10}>
          <Grid
            item
            container
            justify="center"
            alignItems="center"
            className={classes.title}
          >
            <Typography variant="h3" className={classes.h3}>
              Terms of Use
            </Typography>
          </Grid>

          <p>
            Welcome to the WeWear, LLC websites and mobile properties located at
            wewearreview.com and related software applications (sometimes
            referred to as &ldquo;apps&rdquo;), data; more generally, the WeWear
            websites and mobile properties shall hereinafter be referred to
            herein as &ldquo;websites&rdquo;). The Services are offered to you
            conditioned upon your acceptance of the terms, conditions, and
            notices set forth below (collectively, this
            &ldquo;Agreement&rdquo;). By accessing or using the Services, you
            agree to be bound by this Agreement and represent that you have read
            and understood its terms. Please read this Agreement carefully, as
            it contains information concerning your legal rights and limitations
            on these rights, as well as a section regarding applicable law and
            jurisdiction of disputes. If you do not accept all of these terms
            and conditions, you are not authorized to use the Services. If you
            have a WeWear account and wish to terminate this Agreement, you can
            do so at any time by closing your account and no longer accessing or
            using the Services.
          </p>
          <p>
            Any information, text, links, graphics, photos, audio, videos, data,
            code or other materials or arrangements of materials that you can
            view on, access or otherwise interact with through the Services
            shall be referred to as &ldquo;Content&rdquo;. The terms
            &ldquo;we&rdquo;, &ldquo;us&rdquo;, &ldquo;our&rdquo; and
            &ldquo;WeWear&rdquo; refer to WeWear, LLC, an Iowa limited liability
            company located in the United States of America
            (&ldquo;WeWear&rdquo;). &ldquo;Services&rdquo; as defined above
            refers to those provided by WeWeare. For the avoidance of doubt, the
            websites are all owned and controlled by WeWear.&nbsp;
          </p>
          <p>
            The term &ldquo;you&rdquo; refers to the individual, company,
            business organization, or other legal entity using the Services
            and/or contributing Content to them. The Content that you
            contribute, submit, transmit and/or post to or through the Services
            shall be referred to variously as &ldquo;your Content&rdquo;,
            &ldquo;Content of yours&rdquo;, and/or &ldquo;Content you
            submit.&rdquo;
          </p>
          <p>
            The Services are provided solely to assist customers in gathering
            clothing information, posting Content, and searching for clothing
            items.
          </p>
          <p>
            We may change or otherwise modify this Agreement in the future in
            accordance with the terms and conditions herein, and you understand
            and agree that your continued access or use of the Services after
            such change signifies your acceptance of the updated or modified
            Agreement. We will note the date that revisions were last made to
            this Agreement at the bottom of this Agreement, and any revisions
            will take effect upon posting.
          </p>
          <p>
            <strong>USE OF THE SERVICES</strong>
          </p>
          <p>
            As a condition&nbsp;of&nbsp;your use&nbsp;of&nbsp;the Services, you
            warrant that (i) all information supplied by via the Services to
            WeWear is true, accurate, current and complete, (ii) if you are an
            Account Holder, you will safeguard your account information and will
            supervise and be completely responsible for any
            use&nbsp;of&nbsp;your account by anyone other than you, (iii) you
            are 13 years&nbsp;of&nbsp;age&nbsp;or older&nbsp;(in some
            jurisdictions, local laws may have an
            older&nbsp;age&nbsp;requirement)&nbsp;in order to register for an
            account, use the Services and contribute to our websites, and (iv)
            you possess the legal authority to enter into this Agreement and to
            use the Services, including our websites in accordance with all
            terms and conditions herein. WeWear does not knowingly collect the
            information of anyone under the age of 13. We retain the right at
            our sole discretion to deny anyone access to the Services, at any
            time and for any reason, including, but not limited to, for
            violation of this Agreement. By using the Services, including any
            products or services that facilitate the sharing of Content to or
            from third party sites, you understand that you are solely
            responsible for any information that you share with WeWear. You may
            access the Services solely as intended through the provided
            functionality of the Services and as permitted under this Agreement.
          </p>
          <p>
            Copying, transmission, reproduction, replication, posting or
            redistribution of (a) Content or any portion thereof and/or (b) the
            Services more generally is strictly prohibited without the prior
            written permission of WeWear.
          </p>
          <p>
            In order to access certain features of the Services, you will need
            to become an Account Holder by creating an account. When you create
            an account, you must provide complete and accurate information. You
            are solely responsible for the activity that occurs on your account,
            including your interaction and communication with others, and you
            must safeguard your account. Towards this end, if you are an Account
            Holder, you agree to keep your contact information up to
            date.&nbsp;&nbsp;
          </p>
          <p>
            If you are creating a WeWear account for commercial purposes and are
            accepting this Agreement on behalf of a company, organization, or
            other legal entity, you represent and warrant that you are
            authorized to do so and have the authority to bind such entity to
            this Agreement, in which case the words &ldquo;you&rdquo; and
            &ldquo;your&rdquo; as used in this Agreement shall refer to such
            entity and the individual acting on behalf of the company shall be
            referred to as a &ldquo;Business Representative.&rdquo;
          </p>
          <p>
            Through your use of the Services you may encounter links to third
            party sites and apps or be able to interact with third party sites
            and apps. This may include the ability to share Content from the
            Services, including your Content, with such third party sites and
            apps. Please be aware that third party sites and apps may publicly
            display such shared Content. Any interactions with third party sites
            and apps are at your own risk. You expressly acknowledge and agree
            that WeWear is in no way responsible or liable for any such third
            party sites or apps.
          </p>
          <p>
            You agree and understand that WeWear may place advertising and
            promotions on the Services alongside, near, adjacent, or otherwise
            in close proximity to your Content (including, for video or other
            dynamic content, before, during or after its presentation), as well
            as the Content of others.
          </p>
          <p>
            WeWear may, from time to time, decide to change, update or
            discontinue certain features of the Services. You agree and
            understand that WeWear has no obligation to store or maintain your
            Content or other information you provide, except to the extent
            required by applicable law.
          </p>
          <p>
            <strong>PROHIBITED ACTIVITIES</strong>
          </p>
          <p>
            The Content and information available on and through the Services
            (including, but not limited to, messages, data, information, text,
            music, sound, photos, graphics, video, maps, icons, software, code
            or other material), as well as the infrastructure used to provide
            such Content and information, is proprietary to WeWear or licensed
            to WeWear by third parties. For all Content other than your Content,
            you agree not to otherwise modify, copy, distribute, transmit,
            display, perform, reproduce, publish, license, create derivative
            works from, transfer, or sell or re-sell any information, software,
            products, or services obtained from or through the Services.
            Additionally, you agree not to:
          </p>
          <ul>
            <li>(i) use the Services or Content for any commercial purpose;</li>
            <li>
              (ii) access, monitor, reproduce, distribute, transmit, broadcast,
              display, sell, license, copy or otherwise exploit any Content of
              the Services, including but not limited to, user profiles and
              photos, using any robot, spider, scraper or other automated means
              or any manual process for any purpose not in accordance with this
              Agreement or without our express written permission;
            </li>
            <li>
              (iii) violate the restrictions in any robot exclusion headers on
              the Services or bypass or circumvent other measures employed to
              prevent or limit access to the Services;
            </li>
            <li>
              (iv) take any action that imposes, or may impose, in our
              discretion, an unreasonable or disproportionately large load on
              our infrastructure;
            </li>
            <li>
              (v) deep-link to any portion of the Services for any purpose
              without our express written permission;
            </li>
            <li>
              (vi) "frame", "mirror" or otherwise incorporate any part of the
              Services into any other websites or service without our prior
              written authorization;
            </li>
            <li>
              (vii) attempt to modify, translate, adapt, edit, decompile,
              disassemble, or reverse engineer any software programs used by
              WeWear in connection with the Services;
            </li>
            <li>
              (viii) circumvent, disable or otherwise interfere with
              security-related features of the Services or features that prevent
              or restrict use or copying of any Content; or
            </li>
            <li>
              (ix) download any Content unless it&rsquo;s expressly made
              available for download by WeWear.
            </li>
          </ul>
          <p>
            <strong>PRIVACY POLICY AND DISCLOSURES</strong>
          </p>
          <p>
            Any personal information you post on or otherwise submit in
            connection with the Services will be used in accordance with our
            Privacy Policy.
          </p>
          <p>
            <strong>
              REVIEWS, COMMENTS AND USE OF OTHER INTERACTIVE AREAS; LICENSE
              GRANT
            </strong>
          </p>
          <p>
            We appreciate hearing from you. Please be aware that by providing
            your Content to or through the Services, be it via email, posting
            via the services and applications of others, or otherwise, including
            any of your Content that is transmitted to your WeWear account by
            virtue of any WeWear service, reviews, questions, photographs or
            videos, comments, suggestions, ideas or the like contained in any of
            your Content, you grant WeWear a nonexclusive, royalty-free,
            perpetual, transferable, irrevocable and fully sublicensable right
            to (a) host, use, reproduce, modify, run, adapt, translate,
            distribute, publish, create derivative works from and publicly
            display and perform such Content of yours throughout the world in
            any media, now known or hereafter devised; (b) make your Content
            available to the rest of the world and to let others do the same;
            (c) to provide, promote, and improve the Services and to make your
            Content shared on the Services available to other companies,
            organizations or individuals for the syndication, broadcast,
            distribution, promotion or publication of such Content of yours on
            other media and services, subject to our Privacy Policy and this
            Agreement; and (d) use the name and/or trademark that you submit in
            connection with such Content of yours. You acknowledge that WeWear
            may choose to provide attribution of your Content at our discretion.
            You further grant WeWear the right to pursue at law any person or
            entity that violates your or WeWear&rsquo;s rights in your Content
            by a breach of this Agreement. You acknowledge and agree that your
            Content is non-confidential and non-proprietary. You affirm,
            represent, and warrant that you own or have the necessary licenses,
            rights (including copyright and other proprietary rights), consents,
            and permissions to publish and otherwise use (and for WeWear to
            publish and otherwise use) your Content as authorized under this
            Agreement.
          </p>
          <p>
            Note that any feedback and other suggestions you provide may be used
            at any time and we are under no obligation to keep them
            confidential.
          </p>
          <p>
            The Services may contain discussion forums, bulletin boards, review
            services, or other forums in which you may post your Content, such
            as reviews of clothing items or accessories, messages, materials or
            other items ("Interactive Areas"). If WeWear provides such
            Interactive Areas on the websites, you are solely responsible for
            your use of such Interactive Areas and use them at your own risk.
            WeWear does not guarantee any confidentiality with respect to any of
            your Content you provide to the Services or in any Interactive
            Area.&nbsp; WeWear nevertheless reserve the right to remove without
            notice any such messaging or other Content from the Services, where
            they believe in good faith that such Content breaches this Agreement
            or otherwise believe the removal is reasonably necessary to
            safeguard the rights of WeWear and/or other users of the Services.
            Should you disagree with the removal of your Content from the
            websites, you may contact WeWear to make your objections.&nbsp; By
            using any Interactive Areas, you expressly agree only to submit
            Content of yours that complies with WeWear&rsquo;s published
            guidelines, as are in force at the time of submission and made
            available to you by WeWear.&nbsp; You expressly agree not to post,
            upload to, transmit, distribute, store, create or otherwise publish
            through the Services any Content of yours that:
          </p>
          <ol>
            <li>
              Is false, unlawful, misleading, libelous, defamatory, obscene,
              pornographic, indecent, lewd, suggestive, harassing (or advocates
              harassment of another person), threatening, invasive of privacy or
              publicity rights, abusive, inflammatory, fraudulent or otherwise
              objectionable;
            </li>
            <li>
              Would constitute, encourage, promote or provide instructions for
              conduct of an illegal activity, a criminal offense, give rise to
              civil liability, violate the rights of any party in any country of
              the world, or that would otherwise create liability or violate any
              local, state, national or international law;
            </li>
            <li>
              Provides instructional information about illegal activities such
              as making or buying illegal weapons, violating someone&rsquo;s
              privacy, or providing or creating computer viruses;
            </li>
            <li>
              May infringe any patent, trademark, trade secret, copyright or
              other intellectual or proprietary right of any party. In
              particular, content that promotes an illegal or unauthorized copy
              of another&rsquo;s copyrighted work, such as providing pirated
              computer programs or links to them, providing information to
              circumvent manufacturer-installed copy-protect devices, or
              providing pirated music or links to pirated music files;
            </li>
            <li>
              Constitutes mass mailings or &ldquo;spamming&rdquo;, &ldquo;junk
              mail&rdquo;, &ldquo;chain letters&rdquo; or &ldquo;pyramid
              schemes&rdquo;;
            </li>
            <li>
              Impersonates any person or entity or otherwise misrepresents your
              affiliation with a person or entity, including WeWear;
            </li>
            <li>
              Is private information of any third party, including, without
              limitation, addresses, phone numbers, email addresses, Social
              Security numbers and credit card numbers.&nbsp; Note that an
              individual&rsquo;s surname (family name) may be posted to our
              websites, but only where express permission of the identified
              individual has been secured beforehand;
            </li>
            <li>
              Contains restricted or password only access pages, or hidden pages
              or images (those not linked to or from another accessible page);
            </li>
            <li>
              Include or are intended to facilitate viruses, corrupted data or
              other harmful, disruptive or destructive files;
            </li>
            <li>
              Is unrelated to the topic of the Interactive Area(s) in which such
              Content is posted; or
            </li>
            <li>
              In the sole judgment of WeWear, (a) violates the previous
              subsections herein, (b) violates WeWear&rsquo;s related guidelines
              as made available to you by WeWear, (c) is objectionable, (d)
              restricts or inhibits any other person from using or enjoying the
              Interactive Areas or any other aspect of the Services, or (e) may
              expose any of WeWear or their users to any harm or liability of
              any type.
            </li>
          </ol>
          <p>
            WeWear take no responsibility and assume no liability for any
            Content posted, stored, transmitted or uploaded to the Services by
            you (in the case of your Content) or any third party (in the case of
            any and all Content more generally), or for any loss or damage
            thereto, nor is WeWear liable for any mistakes, defamation, slander,
            libel, omissions, falsehoods, obscenity, pornography or profanity
            you may encounter. As a provider of interactive services, WeWear is
            not liable for any statements, representations or any other Content
            provided by its users (including you as to your Content) in the
            websites or any other forum. Although WeWear has no obligation to
            screen, edit or monitor any of the Content posted to or distributed
            through any Interactive Area, WeWear reserves the right, and has
            absolute discretion, to remove, screen, translate or edit without
            notice any Content posted or stored on the Services at any time and
            for any reason, or to have such actions performed by third parties
            on their behalf, and you are solely responsible for creating backup
            copies of and replacing any Content you post or otherwise submit to
            us or store on the Services at your sole cost and expense.
          </p>
          <p>
            Any use of the Interactive Areas or other aspects of the Services in
            violation of the foregoing violates the terms of this Agreement and
            may result in, among other things, termination or suspension of your
            rights to use the Interactive Areas and/or the Services more
            generally.
          </p>
          <p>
            <strong>LIABILITY DISCLAIMER</strong>
          </p>
          <p>
            Please read this section carefully. This section limits WeWear
            liability to you for issues that may arise in connection with your
            use of the services. If you do not understand the terms in this
            section or elsewhere in this agreement, please consult a lawyer for
            clarification before accessing or using the services.
          </p>
          <p>
            The information, software, products, and services published on or
            otherwise provided via the services may include inaccuracies or
            errors, including reservation availability and pricing errors.
            WeWear does not guarantee the accuracy of, and disclaims all
            liability for, any errors or other inaccuracies relating to the
            information and description of the clothing items or accessories,
            experiences, or any other products displayed on the services
            (including, without limitation, the pricing, availability,
            photographs, experience, general product descriptions, reviews and
            ratings, etc.).
          </p>
          <p>
            WeWear makes no representations of any kind about the suitability of
            the services, including the information contained on its websites or
            any portion thereof, for any purpose, and the inclusion or offering
            of any products or service offerings on its websites or otherwise
            through the services does not constitute any endorsement or
            recommendation of such products or service offerings by WeWear. All
            such information, software, products, and service offerings made
            available by or through the services are provided "as is" without
            warranty of any kind. To the maximum extent permitted under
            applicable law, WeWear hereby disclaims all warranties and
            conditions with regard to this information, software, products, and
            the services, including all implied warranties and conditions or
            terms of any kind as to of merchantability, fitness for a particular
            purpose, title, and noninfringement.
          </p>
          <p>
            WeWear also expressly disclaims any warranty, representation, or
            other term of any kind as to the accuracy or proprietary character
            of the content available by and through the services.
          </p>
          <p>
            Subject to the foregoing, you use the services at your own risk and
            in no event shall WeWear (or their officers, directors and/or
            employees) be liable for any direct, indirect, punitive, incidental,
            special, or consequential losses or damages or any loss of income,
            profits, goodwill, data, contracts, use of money, or loss or damages
            arising from or connected in any way to business interruption of any
            type arising out of, or in any way connected with, your access to,
            display of or use of the services or with the delay or inability to
            access, display or use the services (including, but not limited to,
            your reliance upon reviews and opinions appearing on or through the
            services; any viruses, bugs, trojan horses, information, software,
            linked sites, products, and services obtained through the services;
            personal injury or property damage, of any nature whatsoever,
            resulting from your use of the services&rsquo; servers and/or any
            and all personal information and/or financial information stored
            therein; any errors or omissions in any content or for any loss or
            damage of any kind incurred as a result of the use of any content;
            or otherwise arising out of the access to, display of or use of the
            services) whether based on a theory of negligence, contract, tort,
            strict liability, or otherwise, and even if WeWear has been advised
            of the possibility of such damages.
          </p>
          <p>
            If WeWear are found liable for any loss or damage that arises out of
            or is in any way connected with your use of the services, then the
            WeWear&rsquo;s liability will in no event exceed, in the aggregate,
            one-hundred dollars (us $100.00).
          </p>
          <p>
            The limitation of liability reflects the allocation of risk between
            the parties. The limitations specified in this section will survive
            and apply even if any limited remedy specified in these terms is
            found to have failed of its essential purpose. The limitations of
            liability provided in these terms inure to the benefit of WeWear
          </p>
          <p>
            These terms and conditions do not affect mandatory legal rights that
            cannot be excluded under applicable law, for example under consumer
            protection laws in place in certain countries.
          </p>
          <p>
            <strong>INDEMNIFICATION</strong>
          </p>
          <p>
            You agree to defend and indemnify WeWear and any of their officers,
            directors, employees and agents from and against any claims, causes
            of action, demands, recoveries, losses, damages, fines, penalties or
            other costs or expenses of any kind or nature including but not
            limited to reasonable legal and accounting fees, brought by third
            parties as a result of:
          </p>
          <ul>
            <li>
              (i) your breach of this Agreement or the documents referenced
              herein;
            </li>
            <li>
              (ii) your violation of any law or the rights of a third party; or
            </li>
            <li>
              (iii) your use of the Services, including the WeWear websites and
              Apps.
            </li>
          </ul>
          <p>
            <strong>LINKS TO THIRD-PARTY SITES</strong>
          </p>
          <p>
            The Services may contain hyperlinks to websites operated by parties
            other than WeWear. Such hyperlinks are provided for your reference
            only. WeWear does not control such websites and are not responsible
            for their contents or the privacy or other practices of such
            websites. WeWear&rsquo;s inclusion of hyperlinks to such websites
            does not imply any endorsement of the material on such third party
            websites or apps or any association with their operators.
          </p>
          <p>
            <strong>MOBILE APPLICATIONS</strong>
          </p>
          <p>
            As noted above, the Services include software, which at times may be
            referred to as &ldquo;apps&rdquo;.&nbsp; Any software that is made
            available to download from the Services ("Software") is the
            copyrighted work of WeWear. You may not install or use any Software
            that is accompanied by or includes a license agreement unless you
            first agree to the terms of such license agreement. For any Software
            made available for download by way of the Services and which is not
            accompanied by a license agreement, we hereby grant to you, the
            user, a limited, personal, nontransferable license to use the
            Software for viewing and otherwise using the Services in accordance
            with this Agreement&rsquo;s terms and conditions (including those
            policies referenced herein) and for no other purpose.
          </p>
          <p>
            Without limiting the foregoing, copying or reproduction of the
            software to any other server or location for further reproduction or
            redistribution is expressly prohibited. The software is warranted,
            if at all, only according to the terms of the license agreement or
            this agreement (as applicable).
          </p>
          <p>
            <strong>COPYRIGHT AND TRADEMARK NOTICES</strong>
          </p>
          <p>
            WeWear and the WeWear logos and all other service names or slogans
            displayed on the Services are registered and/or common law
            trademarks of WeWear, LLC, and may not be copied, imitated or used,
            in whole or in part, without the prior written permission of WeWear
            or the applicable trademark holder. In addition, the look and feel
            of the Services, including our websites, as well as all page
            headers, custom graphics, button icons and scripts related to same,
            is the service mark, trademark and/or trade dress of WeWear and may
            not be copied, imitated or used, in whole or in part, without the
            prior written permission of WeWear. All other trademarks, registered
            trademarks, product names and company names or logos mentioned on
            the Services are the property of their respective owners. Except to
            the extent noted elsewhere in this Agreement, reference to any
            products, services, processes or other information, by trade name,
            trademark, manufacturer, supplier or otherwise does not constitute
            or imply endorsement, sponsorship or recommendation thereof by
            WeWear.
          </p>
          <p>
            All rights reserved. WeWear is not responsible for content on
            websites operated by parties other than WeWear.
          </p>
          <p>
            <strong>Notice and Take-Down Policy for Illegal Content</strong>
          </p>
          <p>
            WeWear operates on a "notice and takedown" basis. If you have any
            complaints or objections to Content, including user messages posted
            on the Services, or if you believe that material or content posted
            on the Services infringes a copyright that you hold, please contact
            us immediately by following our notice and takedown procedure, which
            can be viewed under our Copyright Complaint Policy. Once this
            procedure has been followed, WeWear will respond to valid and
            properly substantiated complaints by making all reasonable efforts
            to remove manifestly illegal content within a reasonable time.
          </p>
          <p>
            <strong>MODIFICATIONS TO THE SERVICES; TERMINATION</strong>
          </p>
          <p>
            WeWear may change, add or delete these terms and conditions of this
            Agreement or any portion thereof from time to time in its sole
            discretion where we deem it necessary for legal, general regulatory
            and technical purposes, or due to changes in the Services provided
            or nature or layout of Services. Thereafter, you expressly agree to
            be bound by the terms and conditions of this Agreement as amended.
          </p>
          <p>
            WeWear may change, suspend or discontinue any aspect of the Services
            at any time, including availability of any of the Services&rsquo;
            features, databases or Content. WeWear may also impose limits or
            otherwise restrict your access to all or parts of the Services
            without notice or liability for technical or security reasons, to
            prevent against unauthorized access, loss of, or destruction of data
            or where WeWear consider(s) in its/their sole discretion that you
            are in breach of any provision of this Agreement or of any law or
            regulation and where WeWear and/or its corporate affiliates decide
            to discontinue providing any aspect of the Services.Your continued
            use of the services now, or following the posting of any such notice
            of any changes, will indicate acceptance by you of such
            modifications.
          </p>
          <p>
            WeWear may terminate this Agreement with you at any time, without
            advanced notice, where it believes in good faith that you have
            breached this Agreement or otherwise believes that termination is
            reasonably necessary to safeguard the rights of WeWear and/or others
            users of the Services. That means that we may stop providing you
            with Services.
          </p>
          <p>
            <strong>JURISDICTION AND GOVERNING LAW</strong>
          </p>
          <p>
            This website is owned and controlled by WeWear LLC, a U.S. limited
            liability company. This Agreement and any dispute or claim
            (including non-contractual disputes or claims) arising out of or in
            connection with it or its subject matter or formation&nbsp;shall be
            governed by and construed in accordance with the law of the State of
            Iowa, USA. You hereby consent to the exclusive jurisdiction and
            venue of courts in Iowa, USA and stipulate to the fairness and
            convenience of proceedings in such courts for all disputes, both
            contractual and non-contractual, arising out of or relating to the
            use of the Services by you or any third party. You agree that all
            claims you may have against WeWear, LLC arising from or relating to
            the Services must be heard and resolved in a court of competent
            subject matter jurisdiction located in the State of Iowa. Use of the
            Services is unauthorized in any jurisdiction that does not give
            effect to all provisions of these terms and conditions, including,
            without limitation, this paragraph. Nothing in this clause shall
            limit the right of WeWear, LLC to take proceedings against you in
            any other court, or courts, of competent jurisdiction.
          </p>
          <p>
            <strong>GENERAL PROVISIONS</strong>
          </p>
          <p>
            We reserve the right to reclaim any username, account name,
            nickname, handle or any other user identifier for any reason without
            liability to you.
          </p>
          <p>
            You agree that no joint venture, agency, partnership, or employment
            relationship exists between you and WeWear as a result of this
            Agreement or use of the Services.
          </p>
          <p>
            Our performance of this Agreement is subject to existing laws and
            legal process, and nothing contained in this Agreement limits our
            right to comply with law enforcement or other governmental or legal
            requests or requirements relating to your use of the Services or
            information provided to or gathered by us with respect to such use.
            To the extent allowed by applicable law, you agree that you will
            bring any claim or cause of action arising from or relating to your
            access or use of the Services within two (2) years from the date on
            which such claim or action arose or accrued or such claim or cause
            of action will be irrevocably waived.
          </p>
          <p>
            If any part of this Agreement is determined to be invalid or
            unenforceable pursuant to applicable law including, but not limited
            to, the warranty disclaimers and liability limitations set forth
            above, then the invalid or unenforceable provision will be deemed
            superseded by a valid, enforceable provision that most closely
            matches the intent of the original provision and the remaining
            provisions in this Agreement shall continue in effect.
          </p>
          <p>
            This Agreement (and any other terms and conditions referenced
            herein) constitutes the entire agreement between you and WeWear with
            respect to the Services and it supersedes all prior or
            contemporaneous communications and proposals, whether electronic,
            oral, or written, between you and WeWear with respect to the
            Services. A printed version of this Agreement and of any notice
            given in electronic form shall be admissible in judicial or
            administrative proceedings based upon or relating to this Agreement
            to the same extent and subject to the same conditions as other
            business documents and records originally generated and maintained
            in printed form.
          </p>
          <p>
            The terms and conditions of this Agreement are available in the
            language of WeWear websites and/or apps on which Services may be
            accessed.
          </p>
          <p>
            The websites and/or apps on which Services may be accessed may not
            always be updated on a periodic or regular basis and consequently
            are not required to register as editorial product under any relevant
            law.
          </p>
          <p>
            Nothing in this Agreement shall be deemed to confer any third-party
            rights or benefits. You are prohibited from transferring any of your
            rights or obligations under this Agreement to anyone else without
            our consent. Any rights not expressly granted herein are reserved.
          </p>
          <p>
            <strong>&nbsp;SERVICE HELP</strong>
          </p>
          <p>
            For answers to your questions or ways to contact us, visit
            our&nbsp;Contact Us page.
          </p>

          <p>
            <strong>Copyright Complaint Policy</strong>
          </p>
          <p>
            <strong>Infringement Notification:</strong>
          </p>
          <p>
            If you believe in good faith that materials hosted by us infringe
            your copyright, please provide the written information requested
            below. The procedure outlined below is exclusively for notifying
            WeWear that your copyrighted material has been infringed.
          </p>
          <p>
            Please provide the following information in the following format
            (including Section Numbers):
          </p>
          <ol>
            <li>
              A clear identification of the copyrighted work you claim was
              infringed.
            </li>
            <li>
              A clear identification of the material you claim is infringing the
              copyrighted work, and information that will allow us to locate
              that material on the Website, such as a link to the infringing
              material.
            </li>
            <li>
              Your contact information so that we can reply to your complaint,
              preferably including an email address and telephone number.
            </li>
            <li>
              Include the following statement: "I have a good faith belief that
              the material that is claimed as copyright infringement is not
              authorized by the copyright owner, its agent, or the law."
            </li>
            <li>
              Include the following statement: "I swear, under penalty of
              perjury, that the information in the notification is accurate and
              I am the copyright owner, or am authorized to act on behalf of the
              owner, of an exclusive right that is allegedly infringed."
            </li>
            <li>
              The notice must be signed by the person authorized to act on
              behalf of the owner of an exclusive right that is allegedly
              infringed.
            </li>
          </ol>
          <p>
            Notices of claims of copyright infringement on this website should
            be emailed or mailed to:
          </p>
          <ul>
            <li>
              <a href="mailto:wewearreview@gmail.com">wewearreview@gmail.com</a>
            </li>
          </ul>
          <p>
            We suggest that you consult your legal advisor before filing a
            notice or counter-notice. Also, please note that you may be liable
            for damages (including costs and attorneys fees) if you make a false
            claim of copyright infringement.
          </p>
          <p>
            We will review and address all notices that comply with the
            requirements above.
          </p>
          <p>
            By submitting a notice of claim of copyright infringement to WeWear:
            (a) you acknowledge and agree we may provide a copy of the notice,
            including the name, email address, and other contact information of
            the reporting party, to the uploading party; and (b) you consent to
            us providing a copy of the content at issue in our correspondence to
            the uploading party.
          </p>
          <p>
            <strong>REPEAT INFRINGER POLICY</strong>
          </p>
          <p>
            In accordance with the Digital Millennium Copyright Act ("DMCA") and
            other applicable law, WeWear has adopted a policy of terminating, in
            appropriate circumstances and at WeWear&rsquo;s sole discretion,
            subscribers or account holders who are deemed to be repeat
            infringers. WeWear may also at its sole discretion limit access to
            the Site and/or terminate the accounts of any users who infringe any
            intellectual property rights of others, whether or not there is any
            repeat infringement.
          </p>
        </Grid>
        <Grid item xs md></Grid>
      </Grid>
    </div>
  );
};

export default TermsPage;
