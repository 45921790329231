import { createMuiTheme } from "@material-ui/core";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#DFC6BF",
    },
    text: {
      primary: "#000000",
    },
    background: {
      default: "#FFFFFF",
      paper: "#FFFFFF",
    },
  },
  typography: {
    fontFamily: "Montserrat",
    h3: {
      fontFamily: "Frank Ruhl Libre",
      fontWeight: "bold",
    },
  },
  overrides: {
    MuiAppBar: {
      root: {
        elevation: 0,
      },
    },
    MuiButton: {
      root: {
        textTransform: "none",
        borderRadius: "40px",
        "&:hover": {
          color: "black",
          backgroundColor: "#DFC6BF",
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 40,
        backgroundColor: "white",
      },
    },
    MuiFormLabel: {
      root: {
        color: "#000000",
      },
    },
    MuiAvatar: {
      root: {
        color: "black",
        backgroundColor: "#DFC6BF",
      },
      colorDefault: {
        color: "black",
        backgroundColor: "#DFC6BF",
      },
    },
  },
});

export default theme;
