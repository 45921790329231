import React, { useState, useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { Button, Divider, Grid, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import LandingImage from "../../assets/images/LandingImage.png";
import OurStoryImage from "../../assets/images/our-story-photo.jpg";
import { Link } from "react-router-dom";
import occasionOptions from "../../data/occasionOptions";
import { listReviewsByCreatedAt } from "../../graphql/queries";
import { API, Storage } from "aws-amplify";
import Loading from "../../components/Loading/Loading";
import ReviewCard from "../../components/ReviewCard/ReviewCard";
import { reviewSearchState, authUserIdState } from "../../recoil/atoms";

const useStyles = makeStyles((theme) => ({
  top: {
    height: "80vh",
    backgroundImage: `url(${LandingImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: { padding: 0 },
  img: { width: "100%" },
  start: { textAlign: "center" },
  divider: {
    height: "3px",
    width: "70px",
    backgroundColor: "#000000",
    margin: "8px auto",
  },
  joinButton: {
    borderRadius: 0,
  },
  storyTitle: { paddingTop: 15 },
  storyParagraph: { padding: 15 },
  occasionArea: {
    backgroundColor: "#F5F5F5",
    padding: "100px 0",
  },
  storyArea: { padding: "100px 0" },
  reviewArea: {
    padding: "100px 0",
  },
  occasionCard: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: theme.spacing(2),
    width: 230,
    height: 250,
    textAlign: "center",
    border: "1px solid #8497AC",
  },
  cardArea: {
    paddingTop: theme.spacing(4),
  },
}));

const LandingPage = (props) => {
  const classes = useStyles();
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(false);
  const setSearch = useSetRecoilState(reviewSearchState);
  const authUserId = useRecoilValue(authUserIdState);

  useEffect(() => {
    fetchReviews();
  }, []);

  const fetchReviews = async () => {
    try {
      setLoading(true);
      const reviewData = await API.graphql({
        query: listReviewsByCreatedAt,
        authMode: "API_KEY",
        variables: {
          type: "Review",
          sortDirection: "DESC",
          limit: 3,
        },
      });
      const reviewsArray = reviewData.data.listReviewsByCreatedAt.items;
      const signedReviewsArray = await Promise.all(
        reviewsArray.map(async (item) => {
          const signedUrls = await Promise.all(
            item.garmentImages.map(async (image) => {
              const signedUrl = await Storage.get(image);
              return signedUrl;
            })
          );
          item.imageUrls = signedUrls;
          if (item.author.profilePicture) {
            const signedProfilePicture = await Storage.get(
              item.author.profilePicture
            );
            item.author.profilePictureUrl = signedProfilePicture;
          }
          return item;
        })
      );
      setReviews(signedReviewsArray);
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  const browseCategory = (item) => {
    setSearch(item.name);
    props.history.push("/reviews");
  };

  return (
    <div>
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.top}
      >
        <Grid item>
          <Paper className={classes.paper}>
            <Grid
              container
              justify="center"
              alignItems="center"
              direction="column"
            >
              <Grid item style={{ padding: "70px 70px 10px" }}>
                <Typography variant="h3" className={classes.start}>
                  {!authUserId ? "Get Started" : "Find Your Next Outfit"}
                </Typography>
              </Grid>
              <Divider className={classes.divider} variant="middle" />
              <Grid item style={{ margin: 35 }}>
                <Typography variant="h6">
                  Shop with confidence and never return a clothing item again
                </Typography>
              </Grid>
              <Grid container item justify="flex-end">
                <Link to={authUserId ? "/reviews" : "/profile-survey"}>
                  <Button
                    className={classes.joinButton}
                    variant="contained"
                    color="primary"
                  >
                    <ArrowForwardIcon />
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Grid container direction="column" className={classes.occasionArea}>
        <Grid container item style={{ paddingTop: 24 }}>
          <Grid item xs={1}></Grid>
          <Grid item xs={8}>
            <Typography variant="h3" className={classes.h3}>
              Browse by Occasion
            </Typography>
          </Grid>
          <Grid item xs></Grid>
        </Grid>
        <Grid
          item
          container
          direction="row"
          justify="center"
          alignItems="center"
          className={classes.cardArea}
        >
          {occasionOptions.map((occasion) => (
            <Paper
              key={occasion.id}
              className={classes.occasionCard}
              elevation={0}
              onClick={() => browseCategory(occasion)}
            >
              <Grid container>
                <Grid
                  item
                  container
                  direction="column"
                  justify="flex-start"
                  alignItems="center"
                >
                  <Grid item>
                    <img
                      src={occasion.img.default}
                      alt={occasion.name}
                      style={{ height: 200 }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle2">{occasion.name}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          ))}
        </Grid>
      </Grid>
      <Grid container direction="column" className={classes.reviewArea}>
        <Grid container item style={{ paddingTop: 24 }}>
          <Grid item xs={1}></Grid>
          <Grid item xs={8}>
            <Typography variant="h3" className={classes.h3}>
              Most Popular Reviews
            </Typography>
          </Grid>
          <Grid item xs></Grid>
        </Grid>
        <Grid
          item
          container
          direction="row"
          justify="center"
          alignItems="center"
          className={classes.cardArea}
        >
          {loading ? (
            <Loading />
          ) : (
            reviews.map((review) => {
              return <ReviewCard key={review.id} review={review} />;
            })
          )}
        </Grid>
      </Grid>
      <Grid container className={classes.storyArea}>
        <Grid item xs md></Grid>
        <Grid container justify="center" alignItems="center" item xs={8} md={6}>
          <Grid item xs></Grid>
          <Grid item xs={10}>
            <img className={classes.img} alt="complex" src={OurStoryImage} />
          </Grid>
          <Grid item xs></Grid>
        </Grid>
        <Grid
          container
          style={{ padding: 20 }}
          direction="column"
          justify="center"
          item
          xs={12}
          md={5}
        >
          <Grid item className={classes.storyTitle}>
            <Typography variant="h3" className={classes.h3}>
              Our Story
            </Typography>
          </Grid>
          <Grid item className={classes.storyParagraph}>
            <Typography variant="subtitle1" style={{ lineHeight: 2 }}>
              No two brands are created equal. How can a woman be a size small
              at one store and a size large at another? That’s where we come in!
            </Typography>
          </Grid>
          <Grid item className={classes.storyParagraph}>
            <Typography variant="subtitle1" style={{ lineHeight: 2 }}>
              We believe shopping online should be a stress-free experience.
              That’s why we created a community for real women who look like you
              and shop like you to post reviews and eliminate the guesswork. No
              more worrying about whether something that looks good on a model
              will look good on you; you can find out from us before you even
              add it to your cart.
            </Typography>
          </Grid>
          <Grid item className={classes.storyParagraph}>
            <Typography variant="subtitle1" style={{ lineHeight: 2 }}>
              When we wear what makes us feel our best, we can be our best
              selves.
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs md></Grid>
      </Grid>
    </div>
  );
};

export default LandingPage;
