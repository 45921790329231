/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateMessage = /* GraphQL */ `
  subscription OnCreateMessage {
    onCreateMessage {
      id
      name
      email
      subject
      body
      type
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateMessage = /* GraphQL */ `
  subscription OnUpdateMessage {
    onUpdateMessage {
      id
      name
      email
      subject
      body
      type
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteMessage = /* GraphQL */ `
  subscription OnDeleteMessage {
    onDeleteMessage {
      id
      name
      email
      subject
      body
      type
      createdAt
      updatedAt
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser {
    onCreateUser {
      id
      firstName
      lastName
      email
      bio
      phone
      sizes
      bustSizes
      bodyType
      height
      brands
      userStyles
      userBirthday
      userLocation
      profilePicture
      acceptTerms
      searchField
      createdAt
      updatedAt
      reviews {
        nextToken
        scannedCount
        count
      }
      following {
        nextToken
      }
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser {
    onUpdateUser {
      id
      firstName
      lastName
      email
      bio
      phone
      sizes
      bustSizes
      bodyType
      height
      brands
      userStyles
      userBirthday
      userLocation
      profilePicture
      acceptTerms
      searchField
      createdAt
      updatedAt
      reviews {
        nextToken
        scannedCount
        count
      }
      following {
        nextToken
      }
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser {
    onDeleteUser {
      id
      firstName
      lastName
      email
      bio
      phone
      sizes
      bustSizes
      bodyType
      height
      brands
      userStyles
      userBirthday
      userLocation
      profilePicture
      acceptTerms
      searchField
      createdAt
      updatedAt
      reviews {
        nextToken
        scannedCount
        count
      }
      following {
        nextToken
      }
    }
  }
`;
export const onCreateReview = /* GraphQL */ `
  subscription OnCreateReview {
    onCreateReview {
      id
      authorId
      garmentBrand
      garmentName
      garmentTitle
      garmentOccasion
      garmentType
      garmentDetails
      garmentRating
      garmentColor
      garmentSize
      garmentFit
      garmentImages
      searchField
      productId
      type
      createdAt
      updatedAt
      author {
        id
        firstName
        lastName
        email
        bio
        phone
        sizes
        bustSizes
        bodyType
        height
        brands
        userStyles
        userBirthday
        userLocation
        profilePicture
        acceptTerms
        searchField
        createdAt
        updatedAt
      }
      likes {
        nextToken
      }
      photo {
        nextToken
      }
    }
  }
`;
export const onUpdateReview = /* GraphQL */ `
  subscription OnUpdateReview {
    onUpdateReview {
      id
      authorId
      garmentBrand
      garmentName
      garmentTitle
      garmentOccasion
      garmentType
      garmentDetails
      garmentRating
      garmentColor
      garmentSize
      garmentFit
      garmentImages
      searchField
      productId
      type
      createdAt
      updatedAt
      author {
        id
        firstName
        lastName
        email
        bio
        phone
        sizes
        bustSizes
        bodyType
        height
        brands
        userStyles
        userBirthday
        userLocation
        profilePicture
        acceptTerms
        searchField
        createdAt
        updatedAt
      }
      likes {
        nextToken
      }
      photo {
        nextToken
      }
    }
  }
`;
export const onDeleteReview = /* GraphQL */ `
  subscription OnDeleteReview {
    onDeleteReview {
      id
      authorId
      garmentBrand
      garmentName
      garmentTitle
      garmentOccasion
      garmentType
      garmentDetails
      garmentRating
      garmentColor
      garmentSize
      garmentFit
      garmentImages
      searchField
      productId
      type
      createdAt
      updatedAt
      author {
        id
        firstName
        lastName
        email
        bio
        phone
        sizes
        bustSizes
        bodyType
        height
        brands
        userStyles
        userBirthday
        userLocation
        profilePicture
        acceptTerms
        searchField
        createdAt
        updatedAt
      }
      likes {
        nextToken
      }
      photo {
        nextToken
      }
    }
  }
`;
export const onCreateReviewLike = /* GraphQL */ `
  subscription OnCreateReviewLike {
    onCreateReviewLike {
      id
      reviewId
      userId
      createdAt
      updatedAt
      user {
        id
        firstName
        lastName
        email
        bio
        phone
        sizes
        bustSizes
        bodyType
        height
        brands
        userStyles
        userBirthday
        userLocation
        profilePicture
        acceptTerms
        searchField
        createdAt
        updatedAt
      }
      review {
        id
        authorId
        garmentBrand
        garmentName
        garmentTitle
        garmentOccasion
        garmentType
        garmentDetails
        garmentRating
        garmentColor
        garmentSize
        garmentFit
        garmentImages
        searchField
        productId
        type
        createdAt
        updatedAt
      }
    }
  }
`;
export const onUpdateReviewLike = /* GraphQL */ `
  subscription OnUpdateReviewLike {
    onUpdateReviewLike {
      id
      reviewId
      userId
      createdAt
      updatedAt
      user {
        id
        firstName
        lastName
        email
        bio
        phone
        sizes
        bustSizes
        bodyType
        height
        brands
        userStyles
        userBirthday
        userLocation
        profilePicture
        acceptTerms
        searchField
        createdAt
        updatedAt
      }
      review {
        id
        authorId
        garmentBrand
        garmentName
        garmentTitle
        garmentOccasion
        garmentType
        garmentDetails
        garmentRating
        garmentColor
        garmentSize
        garmentFit
        garmentImages
        searchField
        productId
        type
        createdAt
        updatedAt
      }
    }
  }
`;
export const onDeleteReviewLike = /* GraphQL */ `
  subscription OnDeleteReviewLike {
    onDeleteReviewLike {
      id
      reviewId
      userId
      createdAt
      updatedAt
      user {
        id
        firstName
        lastName
        email
        bio
        phone
        sizes
        bustSizes
        bodyType
        height
        brands
        userStyles
        userBirthday
        userLocation
        profilePicture
        acceptTerms
        searchField
        createdAt
        updatedAt
      }
      review {
        id
        authorId
        garmentBrand
        garmentName
        garmentTitle
        garmentOccasion
        garmentType
        garmentDetails
        garmentRating
        garmentColor
        garmentSize
        garmentFit
        garmentImages
        searchField
        productId
        type
        createdAt
        updatedAt
      }
    }
  }
`;
export const onCreateFollowing = /* GraphQL */ `
  subscription OnCreateFollowing {
    onCreateFollowing {
      id
      followerId
      followingId
      createdAt
      updatedAt
      follower {
        id
        firstName
        lastName
        email
        bio
        phone
        sizes
        bustSizes
        bodyType
        height
        brands
        userStyles
        userBirthday
        userLocation
        profilePicture
        acceptTerms
        searchField
        createdAt
        updatedAt
      }
      following {
        id
        firstName
        lastName
        email
        bio
        phone
        sizes
        bustSizes
        bodyType
        height
        brands
        userStyles
        userBirthday
        userLocation
        profilePicture
        acceptTerms
        searchField
        createdAt
        updatedAt
      }
    }
  }
`;
export const onUpdateFollowing = /* GraphQL */ `
  subscription OnUpdateFollowing {
    onUpdateFollowing {
      id
      followerId
      followingId
      createdAt
      updatedAt
      follower {
        id
        firstName
        lastName
        email
        bio
        phone
        sizes
        bustSizes
        bodyType
        height
        brands
        userStyles
        userBirthday
        userLocation
        profilePicture
        acceptTerms
        searchField
        createdAt
        updatedAt
      }
      following {
        id
        firstName
        lastName
        email
        bio
        phone
        sizes
        bustSizes
        bodyType
        height
        brands
        userStyles
        userBirthday
        userLocation
        profilePicture
        acceptTerms
        searchField
        createdAt
        updatedAt
      }
    }
  }
`;
export const onDeleteFollowing = /* GraphQL */ `
  subscription OnDeleteFollowing {
    onDeleteFollowing {
      id
      followerId
      followingId
      createdAt
      updatedAt
      follower {
        id
        firstName
        lastName
        email
        bio
        phone
        sizes
        bustSizes
        bodyType
        height
        brands
        userStyles
        userBirthday
        userLocation
        profilePicture
        acceptTerms
        searchField
        createdAt
        updatedAt
      }
      following {
        id
        firstName
        lastName
        email
        bio
        phone
        sizes
        bustSizes
        bodyType
        height
        brands
        userStyles
        userBirthday
        userLocation
        profilePicture
        acceptTerms
        searchField
        createdAt
        updatedAt
      }
    }
  }
`;
export const onCreatePhoto = /* GraphQL */ `
  subscription OnCreatePhoto {
    onCreatePhoto {
      id
      reviewId
      image
      createdAt
      updatedAt
      review {
        id
        authorId
        garmentBrand
        garmentName
        garmentTitle
        garmentOccasion
        garmentType
        garmentDetails
        garmentRating
        garmentColor
        garmentSize
        garmentFit
        garmentImages
        searchField
        productId
        type
        createdAt
        updatedAt
      }
    }
  }
`;
export const onUpdatePhoto = /* GraphQL */ `
  subscription OnUpdatePhoto {
    onUpdatePhoto {
      id
      reviewId
      image
      createdAt
      updatedAt
      review {
        id
        authorId
        garmentBrand
        garmentName
        garmentTitle
        garmentOccasion
        garmentType
        garmentDetails
        garmentRating
        garmentColor
        garmentSize
        garmentFit
        garmentImages
        searchField
        productId
        type
        createdAt
        updatedAt
      }
    }
  }
`;
export const onDeletePhoto = /* GraphQL */ `
  subscription OnDeletePhoto {
    onDeletePhoto {
      id
      reviewId
      image
      createdAt
      updatedAt
      review {
        id
        authorId
        garmentBrand
        garmentName
        garmentTitle
        garmentOccasion
        garmentType
        garmentDetails
        garmentRating
        garmentColor
        garmentSize
        garmentFit
        garmentImages
        searchField
        productId
        type
        createdAt
        updatedAt
      }
    }
  }
`;
export const onCreateProduct = /* GraphQL */ `
  subscription OnCreateProduct {
    onCreateProduct {
      id
      brand
      type
      name
      image
      link
      createdAt
      updatedAt
      reviews {
        nextToken
        scannedCount
        count
      }
    }
  }
`;
export const onUpdateProduct = /* GraphQL */ `
  subscription OnUpdateProduct {
    onUpdateProduct {
      id
      brand
      type
      name
      image
      link
      createdAt
      updatedAt
      reviews {
        nextToken
        scannedCount
        count
      }
    }
  }
`;
export const onDeleteProduct = /* GraphQL */ `
  subscription OnDeleteProduct {
    onDeleteProduct {
      id
      brand
      type
      name
      image
      link
      createdAt
      updatedAt
      reviews {
        nextToken
        scannedCount
        count
      }
    }
  }
`;
export const onCreateBrand = /* GraphQL */ `
  subscription OnCreateBrand {
    onCreateBrand {
      id
      type
      name
      image
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateBrand = /* GraphQL */ `
  subscription OnUpdateBrand {
    onUpdateBrand {
      id
      type
      name
      image
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteBrand = /* GraphQL */ `
  subscription OnDeleteBrand {
    onDeleteBrand {
      id
      type
      name
      image
      createdAt
      updatedAt
    }
  }
`;
export const onCreateSuggestedBrand = /* GraphQL */ `
  subscription OnCreateSuggestedBrand {
    onCreateSuggestedBrand {
      id
      authorId
      brandName
      type
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSuggestedBrand = /* GraphQL */ `
  subscription OnUpdateSuggestedBrand {
    onUpdateSuggestedBrand {
      id
      authorId
      brandName
      type
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSuggestedBrand = /* GraphQL */ `
  subscription OnDeleteSuggestedBrand {
    onDeleteSuggestedBrand {
      id
      authorId
      brandName
      type
      createdAt
      updatedAt
    }
  }
`;
export const onCreateSuggestedProduct = /* GraphQL */ `
  subscription OnCreateSuggestedProduct {
    onCreateSuggestedProduct {
      id
      authorId
      brandName
      productName
      type
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSuggestedProduct = /* GraphQL */ `
  subscription OnUpdateSuggestedProduct {
    onUpdateSuggestedProduct {
      id
      authorId
      brandName
      productName
      type
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSuggestedProduct = /* GraphQL */ `
  subscription OnDeleteSuggestedProduct {
    onDeleteSuggestedProduct {
      id
      authorId
      brandName
      productName
      type
      createdAt
      updatedAt
    }
  }
`;
