import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { signUpModalOpenState, authUserIdState } from "../../recoil/atoms";
import {
  Avatar,
  Button,
  ButtonGroup,
  Container,
  Grid,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import defaultProfilePicture from "../../assets/images/female-user.png";

import reviewsEmpty from "../../assets/images/empty/reviews-empty@2x.png";
import followersEmpty from "../../assets/images/empty/followers-empty@2x.png";
import likesEmpty from "../../assets/images/empty/likes-empty@2x.png";

import MeasurementsCard from "../MeasurementsCard/MeasurementsCard";
import UsersTable from "../../components/UsersTable/UsersTable";
import ReviewsTable from "../../components/ReviewsTable/ReviewsTable";

import { Auth, API, graphqlOperation } from "aws-amplify";
import { createFollowing, deleteFollowing } from "../../graphql/mutations";
import { followingByUser } from "../../graphql/queries";

const useStyles = makeStyles((theme) => ({
  name: { fontFamily: "Playfair Display", fontWeight: "bold" },
  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  subtitle: {
    fontFamily: "Montserrat",
  },

  image: {
    height: 148,
    width: 148,
  },
  heading: {
    paddingTop: 25,
    paddingBottom: 20,
  },
  buttonContainer: {
    margin: "25px auto",
  },
  button: {
    borderRadius: 0,
  },
  active: {
    backgroundColor: "#DFC6BF",
    color: "#000000",
  },
  reviewArea: {
    display: "flex",
    justifyContent: "space-around",
    flexWrap: "wrap",
  },
  emptyContainer: {
    padding: theme.spacing(7),
  },
  emptyText: {
    textAlign: "center",
  },
  emptyImage: {
    height: 125,
  },
}));

const UserProfile = ({
  user,
  signedProfilePic,
  reviews,
  likes,
  followers,
  following,
}) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [viewing, setViewing] = useState("reviews");
  const [isFollowing, setIsFollowing] = useState(false);
  const authUserId = useRecoilValue(authUserIdState);
  const setSignUpModalOpen = useSetRecoilState(signUpModalOpenState);

  const handleSignOut = () => {
    Auth.signOut();
    history.push("/");
  };

  useEffect(() => {
    fetchUserFollowing();
  }, []);

  const fetchUserFollowing = async () => {
    const userFollowing = await API.graphql({
      query: followingByUser,
      variables: {
        followerId: authUserId,
      },
    });

    const followingArr = userFollowing.data.followingByUser.items.filter(
      (item) => item.followingId === user.id
    );

    if (followingArr.length > 0) {
      setIsFollowing(true);
    }
  };

  const displayViewing = () => {
    switch (viewing) {
      case "reviews":
        return reviews?.length > 0 ? (
          <ReviewsTable reviews={reviews} />
        ) : (
          <Grid
            className={classes.emptyContainer}
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Grid item xs={12}>
              <img
                className={classes.emptyImage}
                src={reviewsEmpty}
                alt="Empty Reviews"
              />
            </Grid>
            <Grid item xs={12} style={{ paddingTop: 15 }}>
              <Typography className={classes.emptyText} variant="h5">
                {authUserId === user.id
                  ? "You haven’t written any reviews yet. Post a Review to get started"
                  : `${user?.firstName} ${user?.lastName} has not written any reviews yet.`}
              </Typography>
            </Grid>
          </Grid>
        );
      case "likes":
        return likes?.length > 0 ? (
          <ReviewsTable reviews={likes} />
        ) : (
          <Grid
            className={classes.emptyContainer}
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Grid item xs={12}>
              <img
                className={classes.emptyImage}
                src={likesEmpty}
                alt="Empty Reviews"
              />
            </Grid>
            <Grid item xs={12} style={{ paddingTop: 15 }}>
              <Typography className={classes.emptyText} variant="h5">
                {authUserId === user.id
                  ? "You haven’t liked any reviews yet. Browse Reviews to get started."
                  : `${user?.firstName} ${user?.lastName} has not liked any reviews yet.`}
              </Typography>
            </Grid>
          </Grid>
        );
      case "followers":
        return followers?.length > 0 ? (
          <UsersTable users={followers} />
        ) : (
          <Grid
            className={classes.emptyContainer}
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Grid item xs={12}>
              <img
                className={classes.emptyImage}
                src={followersEmpty}
                alt="Empty Reviews"
              />
            </Grid>
            <Grid item xs={12} style={{ paddingTop: 15 }}>
              <Typography className={classes.emptyText} variant="h5">
                {authUserId === user.id
                  ? "You don’t have any followers yet. Post more reviews and check back later to find followers."
                  : `${user?.firstName} ${user?.lastName} is not being followed by anyone yet.`}
              </Typography>
            </Grid>
          </Grid>
        );
      case "following":
        return following?.length > 0 ? (
          <UsersTable users={following} />
        ) : (
          <Grid
            className={classes.emptyContainer}
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Grid item xs={12}>
              <img
                className={classes.emptyImage}
                src={followersEmpty}
                alt="Empty Reviews"
              />
            </Grid>
            <Grid item xs={12} style={{ paddingTop: 15 }}>
              <Typography className={classes.emptyText} variant="h5">
                {authUserId === user.id
                  ? "You aren't following any reviewers yet."
                  : `${user?.firstName} ${user?.lastName} is not following anyone yet.`}
              </Typography>
            </Grid>
          </Grid>
        );
      default:
        throw new Error("Unknown tab");
    }
  };

  const handleFollowing = async (user) => {
    if (!authUserId) {
      setSignUpModalOpen(true);
      return;
    }

    setIsFollowing(!isFollowing);

    const userFollowing = await API.graphql({
      query: followingByUser,
      variables: {
        followerId: authUserId,
      },
    });

    const idx = userFollowing.data.followingByUser.items
      .map((item) => {
        return item.followingId;
      })
      .indexOf(user.id);

    if (!isFollowing) {
      if (idx >= 0) {
        console.log("you are already following");
        return;
      }
      try {
        await API.graphql(
          graphqlOperation(createFollowing, {
            input: {
              followerId: authUserId,
              followingId: user.id,
            },
          })
        );
      } catch (error) {
        console.log(error);
      }
    }

    if (isFollowing) {
      const removeFollowing = userFollowing.data.followingByUser.items.filter(
        (item) => item.followingId === user.id
      );

      if (removeFollowing.length === 0) {
        console.log("not currently following this user");
        return;
      }

      if (removeFollowing.length > 0) {
        try {
          await API.graphql(
            graphqlOperation(deleteFollowing, {
              input: {
                id: removeFollowing[0].id,
              },
            })
          );
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  return (
    <Container component="main">
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.heading}
      >
        <Grid item sm={2}></Grid>
        <Grid
          style={{ padding: "0 20px" }}
          container
          justify="center"
          alignItems="center"
          item
          xs={12}
          sm={2}
        >
          <Avatar
            alt={`${user?.firstName} ${user?.lastName}`}
            src={
              !user?.profilePicture ? defaultProfilePicture : signedProfilePic
            }
            className={classes.image}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h3" className={classes.name}>
            {`${user.firstName} ${user.lastName}`}
          </Typography>
          <Typography variant="subtitle1" className={classes.subtitle}>
            {user?.bio}
          </Typography>

          {user.id !== authUserId ? (
            <div>
              <Button
                variant={!isFollowing ? "outlined" : "contained"}
                onClick={() => handleFollowing(user)}
                color={isFollowing ? "primary" : "default"}
              >
                {!isFollowing ? "Follow" : "Following"}
              </Button>
            </div>
          ) : (
            location.pathname === "/profile" && (
              <Typography
                variant="subtitle2"
                style={{ cursor: "pointer", textDecoration: "underline" }}
                onClick={handleSignOut}
              >
                Sign Out
              </Typography>
            )
          )}
        </Grid>
        <Grid item sm={2}></Grid>
      </Grid>

      <Grid container justify="center" alignItems="center">
        <MeasurementsCard user={user} showFit={false} />
      </Grid>

      <Grid container>
        <Grid
          container
          justify="center"
          alignItems="center"
          item
          xs={12}
          className={classes.buttonContainer}
        >
          <ButtonGroup aria-label="contained primary button group" size="large">
            <Button
              onClick={() => setViewing("reviews")}
              className={viewing === "reviews" ? classes.active : ""}
            >
              Reviews
            </Button>
            <Button
              onClick={() => setViewing("likes")}
              className={viewing === "likes" ? classes.active : ""}
            >
              Likes
            </Button>
            <Button
              onClick={() => setViewing("followers")}
              className={viewing === "followers" ? classes.active : ""}
            >
              Followers
            </Button>
            <Button
              onClick={() => setViewing("following")}
              className={viewing === "following" ? classes.active : ""}
            >
              Following
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid item xs={12}>
          {displayViewing()}
        </Grid>
      </Grid>
    </Container>
  );
};

export default UserProfile;
