import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CookieConsent from "react-cookie-consent";
import ReactGA from "react-ga";

// Site Nav Bar
import SiteNav from "../SiteNav/SiteNav";

// Authentication Pages
import AuthForm from "../../pages/authentication/AuthForm";

// Admin Dashboard
import AdminDashboard from "../../pages/admin/AdminDashboard";

// Landing Page
import LandingPage from "../../pages/landingPage/LandingPage";

// Home Page
// import HomePage from "../../pages/homePage/HomePage";

// Profile Survey
import ProfileSurvey from "../../pages/profileSurvey/ProfileSurvey";

// Review Pages
import ReviewList from "../../pages/reviews/ReviewList";

// Page Not Found
import PageNotFound from "../../pages/pageNotFound/PageNotFound";
import Footer from "../Footer/Footer";

// Profile
import Profile from "../../pages/profile/Profile";
import ProfileEdit from "../../pages/profile/ProfileEdit";

// User Pages
import UserList from "../../pages/users/UserList";
import UserShow from "../../pages/users/UserShow";

// Modals
import SignUpModal from "../SignUpModal/SignUpModal";
import ReviewForm from "../ReviewForm/ReviewForm";

// Protected and Admin Route
import ProtectedRoute from "./ProtectedRoute";
import AdminRoute from "./AdminRoute";

import BrandsPage from "../../pages/brandsPage/BrandsPage";
import TermsPage from "../../pages/termsPage/TermsPage";
import PrivacyPage from "../../pages/privacyPage/PrivacyPage";
import ContactUsPage from "../../pages/contactUsPage/ContactUsPage";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
  },
  main: { flex: "1 0 auto" },
}));

const App = () => {
  const classes = useStyles();

  useEffect(() => {
    ReactGA.initialize("UA-188259519-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <Container className={classes.root} disableGutters maxWidth={"lg"}>
      <BrowserRouter>
        <SiteNav />
        <div className={classes.main}>
          <Switch>
            <Route path="/" exact component={LandingPage} />
            <Route path="/brands" component={BrandsPage} />

            <Route path="/login" component={AuthForm} />

            {/* <Route path="/home" component={HomePage} /> */}
            <Route path="/terms" component={TermsPage} />
            <Route path="/privacy-policy" component={PrivacyPage} />
            <Route path="/contact-us" component={ContactUsPage} />
            <ProtectedRoute path="/profile" component={Profile} />
            <ProtectedRoute path="/profile-edit" component={ProfileEdit} />
            <Route path="/profile-survey" component={ProfileSurvey} />

            <Route path="/reviews" component={ReviewList} />

            <Route path="/users" component={UserList} />
            <Route path="/user/show/:id" component={UserShow} />

            <AdminRoute path="/admin-dashboard" component={AdminDashboard} />

            <Route path="/" component={PageNotFound} />
          </Switch>
        </div>
        <Footer />

        <SignUpModal />
        <ReviewForm />
      </BrowserRouter>
      <CookieConsent
        location="bottom"
        buttonText="I accept!"
        cookieName="weWearCookieConsent"
        style={{
          background: "#F3E8E4",
          color: "black",
          border: "1px solid black",
        }}
        buttonStyle={{
          backgroundColor: "#FFFFFF",
          fontSize: "13px",
          padding: "8px",
          borderRadius: "15px",
          border: "1px solid black",
        }}
        expires={150}
      >
        This site uses cookies to personalize your experience, analyze site
        usage, and offer tailored promotions.
      </CookieConsent>
    </Container>
  );
};

export default App;
