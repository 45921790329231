import React from "react";
import { NavLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Avatar,
  Button,
  Divider,
  Drawer,
  Grid,
  Typography,
} from "@material-ui/core";
import { useSetRecoilState } from "recoil";
import {
  createReviewModalOpenState,
  signUpModalOpenState,
} from "../../recoil/atoms";

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  container: {
    width: 250,
  },
  image: {
    height: 130,
    width: 130,
    borderRadius: "50%",
  },
  link: {
    textDecoration: "none",
    color: theme.palette.text.primary,
  },
  active: {
    fontWeight: "bold",
    borderBottom: "3px solid #DFC6BF",
  },
  menuLinks: {
    padding: 8,
  },
  menuItems: {
    padding: 4,
  },
  divider: {
    height: "3px",
    width: "90px",
    backgroundColor: "#000000",
    margin: "10px auto",
  },
}));

const MobileDrawer = ({
  user,
  authUserId,
  isAdmin,
  setCategoryDrawerOpen,
  categoryDrawerOpen,
  mobileDrawerOpen,
  setMobileDrawerOpen,
  handleSignOut,
  userImage,
}) => {
  const classes = useStyles();

  const setCreateReviewModalOpen = useSetRecoilState(
    createReviewModalOpenState
  );
  const setSignUpModalOpen = useSetRecoilState(signUpModalOpenState);

  return (
    <div>
      <Drawer
        anchor="right"
        open={mobileDrawerOpen}
        onClose={() => setMobileDrawerOpen(false)}
      >
        <div className={classes.toolbar} />
        <Grid
          className={classes.container}
          onClick={() => setMobileDrawerOpen(false)}
          container
          direction="column"
          justify="center"
          alignItems="flex-start"
        >
          {authUserId ? (
            <Grid
              container
              item
              direction="column"
              justify="center"
              alignItems="center"
            >
              <Grid item>
                <Avatar
                  alt={`${user?.firstName} ${user?.lastName}`}
                  src={userImage}
                  style={{ height: 100, width: 100, marginBottom: 20 }}
                />
              </Grid>
              <Grid item>
                <Typography variant="subtitle1" className={classes.name}>
                  {`${user?.firstName} ${user?.lastName}`}
                </Typography>
              </Grid>
              <Grid item>
                <NavLink to="/profile" className={classes.link}>
                  <Typography
                    className={classes.navItem}
                    variant="h6"
                    color="inherit"
                    noWrap
                  >
                    Profile
                  </Typography>
                </NavLink>
              </Grid>
              {isAdmin && (
                <Grid item>
                  <NavLink to="/admin-dashboard" className={classes.link}>
                    <Typography
                      className={classes.navItem}
                      variant="h6"
                      color="inherit"
                      noWrap
                    >
                      Admin
                    </Typography>
                  </NavLink>
                </Grid>
              )}
              <Grid item>
                <Button onClick={handleSignOut}>Sign out</Button>
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              item
              direction="column"
              justify="center"
              alignItems="center"
            >
              <NavLink
                to="/login"
                className={classes.link}
                activeClassName={classes.active}
              >
                <Typography
                  className={classes.navItem}
                  variant="h6"
                  color="inherit"
                  noWrap
                >
                  Sign in
                </Typography>
              </NavLink>
            </Grid>
          )}
          <Divider className={classes.divider} variant="middle" />
          <Grid
            container
            item
            direction="column"
            justify="center"
            alignItems="flex-start"
            className={classes.menuLinks}
          >
            <Grid item className={classes.menuItems}>
              <Typography
                className={classes.navItem}
                style={{ cursor: "pointer" }}
                variant="h6"
                color="inherit"
                noWrap
                onClick={
                  authUserId
                    ? () => setCreateReviewModalOpen(true)
                    : () => setSignUpModalOpen(true)
                }
              >
                Post a Review
              </Typography>
            </Grid>

            <Grid item className={classes.menuItems}>
              <Typography
                className={classes.navItem}
                style={{ cursor: "pointer" }}
                variant="h6"
                color="inherit"
                noWrap
                onClick={() => setCategoryDrawerOpen(!categoryDrawerOpen)}
              >
                Browse by Category
              </Typography>
            </Grid>
            <Grid item className={classes.menuItems}>
              <NavLink
                activeClassName={classes.active}
                to="/reviews"
                className={classes.link}
              >
                <Typography
                  className={classes.navItem}
                  variant="h6"
                  color="inherit"
                  noWrap
                >
                  Browse Reviews
                </Typography>
              </NavLink>
            </Grid>
          </Grid>
        </Grid>
      </Drawer>
    </div>
  );
};

export default MobileDrawer;
