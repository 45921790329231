import React from "react";
import {
  Button,
  Container,
  Grid,
  FormLabel,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    paddingLeft: 50,
    paddingRight: 50,
  },
  account: {
    cursor: "pointer",
    textDecoration: "underline",
  },
}));

const SignIn = ({
  email,
  password,
  setEmail,
  setPassword,
  handleSignIn,
  setFormType,
  validation,
}) => {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Grid container justify="flex-start" alignItems="center">
          <Typography variant="h3">Welcome Back</Typography>
          <Typography component="p" variant="subtitle1">
            New here?{" "}
            <span
              className={classes.account}
              onClick={() => setFormType("signUp")}
            >
              Create an Account
            </span>
          </Typography>
        </Grid>

        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormLabel component="legend">Email Address</FormLabel>
              <TextField
                required
                fullWidth
                variant="outlined"
                name="email"
                id="email"
                autoComplete="email"
                margin="normal"
                autoFocus
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                error={validation?.email?.length > 0 ? true : false}
                helperText={validation?.email || ""}
              />
            </Grid>
            <Grid item xs={12}>
              <FormLabel component="legend">Password</FormLabel>
              <TextField
                required
                fullWidth
                variant="outlined"
                margin="normal"
                name="password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                error={validation?.password?.length > 0 ? true : false}
                helperText={validation?.password || ""}
              />
            </Grid>
            <Grid item container justify="space-between" alignItems="center">
              <Typography component="p" variant="subtitle2">
                <span
                  className={classes.account}
                  onClick={() => setFormType("forgotPassword")}
                >
                  Forgot Password
                </span>
              </Typography>
              <Typography component="p" variant="subtitle2">
                <span
                  className={classes.account}
                  onClick={() => setFormType("resendCode")}
                >
                  Resend Code
                </span>
              </Typography>
            </Grid>
            <Button
              type="submit"
              variant="outlined"
              className={classes.submit}
              onClick={handleSignIn}
            >
              Sign In
            </Button>
          </Grid>
        </form>
      </div>
    </Container>
  );
};

export default SignIn;
