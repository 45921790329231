import { atom } from "recoil";

export const authUserState = atom({
  key: "authUserState",
  default: {},
});

export const authUserIdState = atom({
  key: "authUserIdState",
  default: "",
});

export const isAdminState = atom({
  key: "isAdminState",
  default: false,
});

export const reviewSearchState = atom({
  key: "reviewSearchState",
  default: "",
});

export const createReviewModalOpenState = atom({
  key: "createReviewModalOpenState",
  default: false,
});

export const signUpModalOpenState = atom({
  key: "signUpModalOpenState",
  default: false,
});

export const brandsState = atom({
  key: "brandsState",
  default: [],
});

export const reviewToEditState = atom({
  key: "reviewToEditState",
  default: {},
});
