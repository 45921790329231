/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:59db322b-9b3b-454b-a812-c00db8d48be0",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_PfbFPaXX7",
    "aws_user_pools_web_client_id": "5p7hr8pbacc1tgfsg5gclhto7j",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://6vd5dgw6m5dt5apbnov6gatf6y.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-je35jj4qqzdatpr6petdrypgbm",
    "aws_user_files_s3_bucket": "weweara004c8776218417d838910dc962a02fe211552-prod",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
