import React from "react";
import { Grid, CircularProgress } from "@material-ui/core";

const Loading = () => {
  return (
    <Grid container direction="row" justify="center" alignItems="stretch">
      <Grid
        item
        xs={12}
        style={{ textAlign: "center", paddingTop: 30, paddingBottom: 30 }}
      >
        <CircularProgress />
      </Grid>
    </Grid>
  );
};

export default Loading;
