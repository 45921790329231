import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import { API, Storage } from "aws-amplify";
import { listReviews } from "../../graphql/queries";
import Loading from "../../components/Loading/Loading";

const Reviews = () => {
  const [loading, setLoading] = useState(false);
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    fetchReviews();
  }, []);

  const fetchReviews = async () => {
    try {
      setLoading(true);

      const reviewData = await API.graphql({
        query: listReviews,
        authMode: "API_KEY",
      });
      const reviewsArray = reviewData.data.listReviews.items;
      const signedReviewsArray = await Promise.all(
        reviewsArray.map(async (item) => {
          const signedUrls = await Promise.all(
            item.garmentImages.map(async (image) => {
              const signedUrl = await Storage.get(image);
              return signedUrl;
            })
          );
          item.imageUrls = signedUrls;
          return item;
        })
      );
      setReviews(signedReviewsArray);
      setLoading(false);
    } catch (error) {
      console.log("error fetching reviews:", error);
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Grid container direction="column" justify="center" alignItems="center">
          <Grid item xs={12}>
            <Typography variant="h5">Reviews</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2">
              Total number of reviews: {reviews.length}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2">
              New reviews (weekly basis).
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2">
              Top 10 most liked reviews (every week).
            </Typography>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default Reviews;
