import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { authUserState } from "../../recoil/atoms";
import { makeStyles } from "@material-ui/core/styles";
import { useDropzone } from "react-dropzone";
import {
  Avatar,
  Button,
  Grid,
  Slider,
  TextField,
  Typography,
  Paper,
} from "@material-ui/core";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { v4 as uuid } from "uuid";
import { useSnackbar } from "notistack";
import Heading from "../../components/Heading/Heading";
import Loading from "../../components/Loading/Loading";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { API, graphqlOperation, Storage } from "aws-amplify";
import { updateUser } from "../../graphql/mutations";
import { getBrandsAlphabetically } from "../../graphql/queries";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import styleOptions from "../../data/styleOptions";
import sizeOptions from "../../data/sizeOptions";
import bustSizeOptions from "../../data/bustSizeOptions";
import bodyTypeOptions from "../../data/bodyTypeOptions";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
    fontWeight: "bold",
    fontSize: 16,
  },
  field: {
    backgroundColor: "white",
    margin: 8,
  },
  image: {
    height: 148,
    width: 148,
  },
  mediaArea: {
    position: "relative",
    height: "150px",
    width: "150px",
    alignItems: "center",
    borderRadius: "50%",
    border: "1px solid black",
    cursor: "pointer",
    "&:hover": {
      "& $media": {
        opacity: 0.2,
      },
      "& $overlay": {
        opacity: 1,
      },
    },
  },
  media: {
    opacity: 1,
    transition: ".25s ease",
  },
  overlay: {
    position: "absolute",
    top: "30%",
    opacity: 0,
    color: "black",
  },
  sizePaper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: theme.spacing(1),
    width: 50,
    height: 50,
    textAlign: "center",
    border: "1px solid #DFC6BF",
  },
  sizeActive: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: theme.spacing(1),
    width: 50,
    height: 50,
    textAlign: "center",
    backgroundColor: "#DFC6BF",
    color: "black",
  },
  bodyPaper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: theme.spacing(2),
    width: 135,
    height: 155,
    textAlign: "center",
    border: "1px solid #DFC6BF",
  },
  bodyActive: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: theme.spacing(2),
    width: 135,
    height: 155,
    textAlign: "center",
    backgroundColor: "white",
    color: "#DFC6BF",
  },
  brandPaper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: theme.spacing(1),
    width: 150,
    height: 100,
    textAlign: "center",
    border: "1px solid #DFC6BF",
  },
  brandActive: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: theme.spacing(1),
    width: 150,
    height: 100,
    textAlign: "center",
    backgroundColor: "white",
    color: "#DFC6BF",
  },
  stylePaper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: theme.spacing(2),
    width: 135,
    height: 155,
    textAlign: "center",
    border: "1px solid #DFC6BF",
  },
  styleActive: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: theme.spacing(2),
    width: 135,
    height: 155,
    textAlign: "center",
    backgroundColor: "white",
    color: "#DFC6BF",
  },
}));

const marks = [
  {
    value: 48,
    label: "4ft",
  },
  {
    value: 60,
    label: "5ft",
  },
  {
    value: 72,
    label: "6ft",
  },
  {
    value: 84,
    label: "7ft",
  },
];

const InchesFeetConvert = ({ height }) => {
  let feet = Math.floor(height / 12);
  let inches = height - feet * 12;

  return (
    <Typography variant="h5">
      {feet}ft {inches > 0 ? `${inches}in` : ""}
    </Typography>
  );
};

const ProfileEdit = (props) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [loadingBrands, setLoadingBrands] = useState(false);
  const [brandOptions, setBrandOptions] = useState([]);

  const [nextToken, setNextToken] = useState(undefined);
  const [nextNextToken, setNextNextToken] = useState();
  const [previousTokens, setPreviousTokens] = useState([]);

  const handleNext = () => {
    setPreviousTokens((prev) => [...prev, nextToken]);
    setNextToken(nextNextToken);
    setNextNextToken(null);
  };

  const handlePrev = () => {
    setNextToken(previousTokens.pop());
    setPreviousTokens([...previousTokens]);
    setNextNextToken(null);
  };

  const [authUser, setAuthUser] = useRecoilState(authUserState);

  const [sizes, setSizes] = useState(authUser.sizes || []);
  const [bustSizes, setBustSizes] = useState(authUser.bustSizes || []);
  const [bodyType, setBodyType] = useState(authUser.bodyType || "");
  const [height, setHeight] = useState(authUser.height || 65);
  const [brands, setBrands] = useState(authUser.brands || []);
  const [userStyles, setUserStyles] = useState(authUser.userStyles || []);
  const [profilePicture, setProfilePicture] = useState(
    authUser.profilePicture || ""
  );
  const [firstName, setFirstName] = useState(authUser.firstName || "");
  const [email, setEmail] = useState(authUser.email || "");
  const [lastName, setLastName] = useState(authUser.lastName || "");
  const [phone, setPhone] = useState(authUser.phone || "");
  const [userLocation, setUserLocation] = useState(authUser.userLocation || "");
  const [userBirthday, setUserBirthday] = useState(authUser.userBirthday || "");
  const [bio, setBio] = useState(authUser.bio || "");

  const [pictureFile, setPictureFile] = useState({});
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 1) {
        enqueueSnackbar(
          "Only one image can be used for your profile.  Please choose one image and try again.",
          {
            variant: "error",
          }
        );
        return;
      }
      setPictureFile(acceptedFiles[0]);
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  useEffect(() => {
    const fetchBrands = async () => {
      try {
        setLoadingBrands(true);
        const brandData = await API.graphql({
          query: getBrandsAlphabetically,
          authMode: "API_KEY",
          variables: {
            type: "Brand",
            sortDirection: "ASC",
            limit: 6,
            nextToken,
          },
        });
        setNextNextToken(brandData.data.getBrandsAlphabetically.nextToken);
        const brandsArray = brandData.data.getBrandsAlphabetically.items;
        const signedBrandsArray = await Promise.all(
          brandsArray.map(async (item) => {
            const signedUrl = await Storage.get(item.image);
            item.imageUrl = signedUrl;
            return item;
          })
        );
        setBrandOptions(signedBrandsArray);
        setLoadingBrands(false);
      } catch (error) {
        console.log("error fetching brands:", error);
        setLoadingBrands(false);
      }
    };

    fetchBrands();
  }, [nextToken]);

  const handleEditProfile = async () => {
    setLoading(true);

    const userData = {
      sizes,
      bustSizes,
      bodyType,
      height,
      brands,
      userStyles,
      firstName,
      lastName,
      email,
      phone,
      userLocation,
      userBirthday,
      bio,
      searchField: `${firstName} ${lastName} ${userLocation} ${bodyType}`.toLowerCase(),
    };

    if (files.length === 1) {
      const imageKey =
        uuid() + pictureFile?.name?.replace(/\s/g, "-").toLowerCase();
      userData.profilePicture = imageKey;
      await Storage.put(imageKey, pictureFile);
    }

    try {
      await API.graphql(
        graphqlOperation(updateUser, {
          input: { id: authUser.id, ...userData },
        })
      );

      setAuthUser({ ...authUser, ...userData });

      enqueueSnackbar("Success! Your profile has been updated.", {
        variant: "success",
      });
      props.history.push("/profile");
      setLoading(false);
    } catch (error) {
      console.log("error: ", error);
      setLoading(false);
      enqueueSnackbar(
        "There was an error, please review your profile form and try again.",
        {
          variant: "error",
        }
      );
    }
  };

  // Fit handlers
  // =================================================================
  const handleSizeChange = (size) => {
    if (sizes?.includes(size)) {
      setSizes(sizes?.filter((s) => s !== size));
    } else {
      setSizes((prevSizes) => [...prevSizes, size]);
    }
  };

  const handleBustChange = (bust) => {
    if (bustSizes?.includes(bust)) {
      setBustSizes(bustSizes?.filter((b) => b !== bust));
    } else {
      setBustSizes((prevBustSizes) => [...prevBustSizes, bust]);
    }
  };

  const handleBodyTypeChange = (type) => {
    setBodyType(type);
  };

  const handleHeightChange = (event, value) => {
    setHeight(value);
  };

  const handleBrandChange = (brand) => {
    if (brands?.includes(brand)) {
      setBrands(brands.filter((b) => b !== brand));
    } else {
      setBrands((prevBrands) => [...prevBrands, brand]);
    }
  };

  const handleStyleChange = (style) => {
    if (userStyles?.includes(style)) {
      setUserStyles(userStyles.filter((s) => s !== style));
    } else {
      setUserStyles((prevStyles) => [...prevStyles, style]);
    }
  };

  const handleRemovePicture = () => {
    setFiles([]);
    setProfilePicture(authUser.profilePicture);
  };

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <Grid container>
          <Grid item xs></Grid>
          <Grid
            container
            item
            xs={10}
            direction="column"
            justify="center"
            alignItems="center"
          >
            <form>
              <Grid container justify="center" alignItems="center">
                <Grid item xs={12}>
                  <Heading>User Profile</Heading>
                </Grid>

                <Grid
                  item
                  container
                  direction="column"
                  justify="center"
                  alignItems="center"
                  className={classes.mediaArea}
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  <Grid
                    className={classes.media}
                    item
                    container
                    justify="center"
                    alignItems="center"
                  >
                    {files.map((file) => (
                      <Grid item key={file.name}>
                        <Avatar
                          alt="profile picture preview"
                          src={file.preview}
                          className={classes.image}
                        />
                      </Grid>
                    ))}
                  </Grid>

                  <Grid
                    className={classes.media}
                    item
                    container
                    justify="center"
                    alignItems="center"
                  >
                    {!authUser.profilePicture
                      ? files.length === 0 && (
                          <Grid
                            item
                            container
                            direction="column"
                            justify="center"
                            alignItems="center"
                          >
                            <CloudUploadIcon />
                            <Typography variant="subtitle2">Upload</Typography>
                          </Grid>
                        )
                      : files.length === 0 && (
                          <Avatar
                            className={classes.image}
                            src={authUser.signedUrl}
                            alt={`${authUser.firstName} ${authUser.lastName}`}
                          />
                        )}
                  </Grid>

                  <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                    className={classes.overlay}
                  >
                    <Grid item xs={12}>
                      <CloudUploadIcon fontSize="large" />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle2"
                        style={{ padding: 4, fontWeight: "bold" }}
                      >
                        Upload Image
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                {files.length === 1 && (
                  <Grid
                    container
                    justify="center"
                    alignItems="center"
                    style={{ padding: 20, cursor: "pointer" }}
                  >
                    <Button variant="contained" color="primary">
                      <Typography
                        variant="subtitle2"
                        onClick={handleRemovePicture}
                      >
                        Undo Image
                      </Typography>
                    </Button>
                  </Grid>
                )}

                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  item
                  xs={12}
                ></Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    className={classes.field}
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    id="FirstName"
                    name="FirstName"
                    label="First Name"
                    autoComplete="off"
                    value={firstName}
                    onChange={(event) => setFirstName(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    disabled
                    className={classes.field}
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    id="Email"
                    name="Email"
                    label="Email Address"
                    autoComplete="off"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    className={classes.field}
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    id="LastName"
                    name="LastName"
                    label="Last Name"
                    autoComplete="off"
                    value={lastName}
                    onChange={(event) => setLastName(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    className={classes.field}
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    id="UserLocation"
                    name="UserLocation"
                    label="Location"
                    autoComplete="off"
                    value={userLocation}
                    onChange={(event) => setUserLocation(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      className={classes.field}
                      disableFuture
                      fullWidth
                      margin="dense"
                      autoComplete="off"
                      variant="inline"
                      inputVariant="outlined"
                      format="MM/dd/yyyy"
                      openTo="year"
                      views={["year", "month", "date"]}
                      label="Birthday"
                      value={userBirthday}
                      onChange={(date) => setUserBirthday(date)}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    className={classes.field}
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    id="Phone"
                    name="Phone"
                    label="Phone Number"
                    autoComplete="off"
                    value={phone}
                    onChange={(event) => setPhone(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    className={classes.field}
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    id="Bio"
                    name="Bio"
                    label="Bio"
                    autoComplete="off"
                    value={bio}
                    onChange={(event) => setBio(event.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid container justify="center" alignItems="center">
                <Grid item container justify="center" xs={12}>
                  <Typography variant="h4">My Fit</Typography>
                </Grid>

                <Grid container item>
                  <Grid item xs={12}>
                    <Typography variant="h5" style={{ textAlign: "center" }}>
                      Dresses & Pants
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                  >
                    {sizeOptions.map((size) => (
                      <Paper
                        key={size}
                        className={
                          sizes?.includes(size)
                            ? classes.sizeActive
                            : classes.sizePaper
                        }
                        elevation={0}
                        onClick={() => handleSizeChange(size)}
                      >
                        <Typography variant="subtitle1">{size}</Typography>
                      </Paper>
                    ))}
                  </Grid>
                </Grid>

                <Grid container item style={{ marginTop: 24 }}>
                  <Grid item xs={12}>
                    <Typography variant="h5" style={{ textAlign: "center" }}>
                      Bust Size
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                  >
                    {bustSizeOptions.map((bust) => (
                      <Paper
                        key={bust}
                        className={
                          bustSizes?.includes(bust)
                            ? classes.sizeActive
                            : classes.sizePaper
                        }
                        elevation={0}
                        onClick={() => handleBustChange(bust)}
                      >
                        <Typography variant="subtitle1">{bust}</Typography>
                      </Paper>
                    ))}
                  </Grid>
                </Grid>

                <Grid container item style={{ marginTop: 24 }}>
                  <Grid item xs={12}>
                    <Typography variant="h5" style={{ textAlign: "center" }}>
                      Body Type
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                  >
                    {bodyTypeOptions.map((type) => (
                      <Paper
                        key={type.id}
                        className={
                          type.name === bodyType
                            ? classes.bodyActive
                            : classes.bodyPaper
                        }
                        elevation={0}
                        onClick={() => handleBodyTypeChange(type.name)}
                      >
                        <Grid container>
                          <Grid item>
                            {type.name === bodyType && <CheckCircleIcon />}
                          </Grid>
                          <Grid
                            item
                            container
                            direction="column"
                            justify="flex-start"
                            alignItems="center"
                          >
                            <Grid item>
                              <img
                                src={type.img.default}
                                alt={type.name}
                                style={{ height: 100 }}
                              />
                            </Grid>
                            <Grid item>
                              <Typography variant="subtitle2">
                                {type.name}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Paper>
                    ))}
                  </Grid>
                </Grid>

                <Grid container item style={{ marginTop: 24 }}>
                  <Grid item xs={12}>
                    <Typography variant="h5" style={{ textAlign: "center" }}>
                      Height
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                  >
                    <Grid
                      container
                      item
                      justify="center"
                      alignItems="center"
                      style={{ marginBottom: 50 }}
                      xs={12}
                    >
                      {<InchesFeetConvert height={height} />}
                    </Grid>
                    <Grid
                      item
                      container
                      direction="row"
                      justify="center"
                      alignItems="center"
                    >
                      <Slider
                        value={height}
                        aria-labelledby="discrete-slider-small-steps"
                        step={1}
                        marks={marks}
                        min={48}
                        max={84}
                        valueLabelDisplay="auto"
                        onChange={handleHeightChange}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container item style={{ marginTop: 24 }}>
                  <Grid item xs={12}>
                    <Typography variant="h5" style={{ textAlign: "center" }}>
                      Brands
                    </Typography>
                  </Grid>
                  {loadingBrands ? (
                    <Loading />
                  ) : (
                    <Grid
                      item
                      container
                      direction="row"
                      justify="center"
                      alignItems="center"
                    >
                      {brandOptions.map((brand) => (
                        <Paper
                          key={brand.id}
                          className={
                            brands?.includes(brand.name)
                              ? classes.brandActive
                              : classes.brandPaper
                          }
                          elevation={0}
                          onClick={() => handleBrandChange(brand.name)}
                        >
                          <Grid container>
                            <Grid item>
                              {brands?.includes(brand.name) && (
                                <CheckCircleIcon />
                              )}
                            </Grid>
                            <Grid
                              item
                              container
                              justify="center"
                              alignItems="center"
                            >
                              <img
                                src={brand.imageUrl}
                                alt={brand.name}
                                style={{ height: "25px" }}
                              />
                            </Grid>
                          </Grid>
                        </Paper>
                      ))}
                    </Grid>
                  )}
                  <Grid
                    container
                    justify="center"
                    alignItems="center"
                    style={{ padding: 16 }}
                  >
                    <Button
                      disabled={previousTokens.length < 1 || loading}
                      onClick={handlePrev}
                    >
                      <ArrowBackIosIcon />
                    </Button>
                    <Button
                      disabled={!nextNextToken || loading}
                      onClick={handleNext}
                    >
                      <ArrowForwardIosIcon />
                    </Button>
                  </Grid>
                </Grid>

                <Grid container item style={{ marginTop: 24 }}>
                  <Grid item xs={12}>
                    <Typography variant="h5" style={{ textAlign: "center" }}>
                      Style
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                  >
                    {styleOptions.map((style) => (
                      <Paper
                        key={style.id}
                        className={
                          userStyles?.includes(style.name)
                            ? classes.styleActive
                            : classes.stylePaper
                        }
                        elevation={0}
                        onClick={() => handleStyleChange(style.name)}
                      >
                        <Grid container>
                          <Grid item>
                            {userStyles?.includes(style.name) && (
                              <CheckCircleIcon />
                            )}
                          </Grid>
                          <Grid
                            item
                            container
                            direction="column"
                            justify="center"
                            alignItems="center"
                          >
                            <Grid item>
                              <img
                                src={style.img.default}
                                alt={style.name}
                                style={{ height: 100 }}
                              />
                            </Grid>
                            <Grid item>
                              <Typography variant="subtitle2">
                                {style.name}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Paper>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
              <Grid container justify="center" alignItems="center">
                <Button
                  variant="outlined"
                  onClick={handleEditProfile}
                  className={classes.button}
                >
                  Save Profile
                </Button>
                <Link
                  to="./profile"
                  style={{
                    textDecoration: "none",
                    color: "#DFC6BF",
                    fontWeight: "bold",
                  }}
                >
                  <Typography variant="subtitle1">Cancel</Typography>
                </Link>
              </Grid>
            </form>
          </Grid>
          <Grid item xs></Grid>
        </Grid>
      )}
    </div>
  );
};

export default ProfileEdit;
