import React from "react";
import { Grid, Slider, Typography } from "@material-ui/core";
import Heading from "../../components/Heading/Heading";

const marks = [
  {
    value: 48,
    label: "4ft",
  },
  {
    value: 60,
    label: "5ft",
  },
  {
    value: 72,
    label: "6ft",
  },
  {
    value: 84,
    label: "7ft",
  },
];

const InchesFeetConvert = ({ height }) => {
  let feet = Math.floor(height / 12);
  let inches = height - feet * 12;

  return (
    <Typography variant="h5">
      {feet}ft {inches > 0 ? `${inches}in` : ""}
    </Typography>
  );
};

const BasicsHeight = ({ height, setHeight }) => {
  const handleHeightChange = (event, value) => {
    setHeight(value);
  };

  return (
    <Grid
      container
      item
      xs={10}
      direction="column"
      justify="center"
      alignItems="center"
    >
      <Grid item>
        <Heading>How tall are you?</Heading>
      </Grid>
      <Grid item>{<InchesFeetConvert height={height} />}</Grid>
      <Grid item container direction="row" justify="center" alignItems="center">
        <Slider
          value={height}
          aria-labelledby="discrete-slider-small-steps"
          step={1}
          marks={marks}
          min={48}
          max={84}
          valueLabelDisplay="auto"
          onChange={handleHeightChange}
        />
      </Grid>
    </Grid>
  );
};

export default BasicsHeight;
