import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar, Button, Grid, Typography } from "@material-ui/core";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import ChatOutlinedIcon from "@material-ui/icons/ChatOutlined";
import defaultProfilePicture from "../../assets/images/female-user.png";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { signUpModalOpenState, authUserIdState } from "../../recoil/atoms";

import { API, graphqlOperation } from "aws-amplify";
import { createFollowing, deleteFollowing } from "../../graphql/mutations";
import { followingByUser } from "../../graphql/queries";

const useStyles = makeStyles((theme) => ({
  card: {
    height: 200,
    width: 400,
    margin: theme.spacing(1),
    // border: "1px solid #CDCDCD",
    // borderRadius: "40px",
  },
  image: {
    height: 100,
    width: 100,
  },
  name: {
    fontWeight: 600,
    color: "#575757",
    fontSize: 20,
    "&:hover": {
      color: "#DFC6BF",
    },
  },
  info: {
    color: "#8497AC",
    fontSize: 16,
  },
  icon: {
    color: "#8497AC",
    paddingRight: 3,
  },
}));

const UserCard = ({ user }) => {
  const classes = useStyles();
  const [isFollowing, setIsFollowing] = useState(false);
  const authUserId = useRecoilValue(authUserIdState);
  const setSignUpModalOpen = useSetRecoilState(signUpModalOpenState);

  useEffect(() => {
    const fetchUserFollowing = async () => {
      try {
        const userFollowing = await API.graphql({
          query: followingByUser,
          variables: {
            followerId: authUserId,
          },
        });

        const followingArr = userFollowing.data.followingByUser.items.filter(
          (item) => item.followingId === user.id
        );

        if (followingArr.length > 0) {
          setIsFollowing(true);
        }
      } catch (error) {
        console.log("error fetching user following", error);
      }
    };

    if (!authUserId) {
      return;
    }
    fetchUserFollowing();
  }, []);

  const handleFollowing = async (user) => {
    if (!authUserId) {
      setSignUpModalOpen(true);
      return;
    }

    setIsFollowing(!isFollowing);

    const userFollowing = await API.graphql({
      query: followingByUser,
      variables: {
        followerId: authUserId,
      },
    });

    const idx = userFollowing.data.followingByUser.items
      .map((item) => {
        return item.followingId;
      })
      .indexOf(user.id);

    if (!isFollowing) {
      if (idx >= 0) {
        console.log("you are already following");
        return;
      }
      try {
        await API.graphql(
          graphqlOperation(createFollowing, {
            input: {
              followerId: authUserId,
              followingId: user.id,
            },
          })
        );
      } catch (error) {
        console.log(error);
      }
    }

    if (isFollowing) {
      const removeFollowing = userFollowing.data.followingByUser.items.filter(
        (item) => item.followingId === user.id
      );

      if (removeFollowing.length === 0) {
        console.log("not currently following this user");
        return;
      }

      if (removeFollowing.length > 0) {
        try {
          await API.graphql(
            graphqlOperation(deleteFollowing, {
              input: {
                id: removeFollowing[0].id,
              },
            })
          );
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  return (
    <Grid
      container
      alignItems="center"
      justify="space-around"
      className={classes.card}
    >
      <Grid item container justify="center" alignItems="center" xs={4}>
        {user?.profilePicture ? (
          <Avatar
            className={classes.image}
            alt={`${user.firstName} ${user.lastName}`}
            src={user?.imageUrl}
          />
        ) : (
          <img
            className={classes.image}
            src={defaultProfilePicture}
            alt="profile pic"
          />
        )}
      </Grid>

      <Grid container item xs={8}>
        <Grid item>
          <Link
            to={{
              pathname: `/user/show/${user.id}`,
              state: {
                user: user,
              },
            }}
            style={{ textDecoration: "none" }}
          >
            <Typography variant="h6" className={classes.name}>
              {`${user?.firstName} ${user?.lastName}`}
            </Typography>
          </Link>
        </Grid>
        {user.userLocation && (
          <Grid container item>
            <LocationOnOutlinedIcon className={classes.icon} />
            <Typography variant="subtitle1" className={classes.info}>
              {user?.userLocation}
            </Typography>
          </Grid>
        )}
        <Grid container item>
          <ChatOutlinedIcon className={classes.icon} />
          <Typography variant="subtitle1" className={classes.info}>
            {user?.reviews?.items?.length} Reviews
          </Typography>
        </Grid>
        <Grid container item>
          {user.id !== authUserId && (
            <div>
              <Button
                variant={!isFollowing ? "outlined" : "contained"}
                onClick={() => handleFollowing(user)}
                color={isFollowing ? "primary" : "default"}
              >
                {!isFollowing ? "Follow" : "Following"}
              </Button>
            </div>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UserCard;
