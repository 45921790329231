import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Container,
  Grid,
  FormLabel,
  TextField,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    paddingLeft: 50,
    paddingRight: 50,
  },
  account: {
    cursor: "pointer",
    textDecoration: "underline",
  },
}));

const ForgotPassword = ({
  email,
  setEmail,
  handleForgotPassword,
  setFormType,
  validation,
}) => {
  const classes = useStyles();
  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Grid container justify="flex-start" alignItems="center">
          <Typography variant="h3">Forgot Password</Typography>
          <Typography component="p" variant="subtitle1">
            Nevermind?{" "}
            <span
              className={classes.account}
              onClick={() => setFormType("signIn")}
            >
              Sign In
            </span>
          </Typography>
        </Grid>
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormLabel component="legend">Password</FormLabel>
              <TextField
                required
                fullWidth
                variant="outlined"
                margin="normal"
                name="email"
                label="Email Address"
                id="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                error={validation?.email?.length > 0 ? true : false}
                helperText={validation?.email || ""}
              />
            </Grid>

            <Button
              type="submit"
              variant="outlined"
              className={classes.submit}
              onClick={handleForgotPassword}
            >
              Send Code
            </Button>
          </Grid>
        </form>
      </div>
    </Container>
  );
};

export default ForgotPassword;
