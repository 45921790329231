import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import FacebookIcon from "../../assets/images/social/Facebook@2x.png";
import InstagramIcon from "../../assets/images/social/Instagram@2x.png";
import TwitterIcon from "../../assets/images/social/Twitter@2x.png";
// import LinkedInIcon from "../../assets/images/social/LinkedIn@2x.png";
// import PinterestIcon from "../../assets/images/social/Pinterest@2x.png";

import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  createReviewModalOpenState,
  authUserIdState,
  signUpModalOpenState,
} from "../../recoil/atoms";

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: "#DFC6BF",
    padding: theme.spacing(6),
    color: "#000000",
  },
  social: {
    height: 40,
  },
  icons: {
    textAlign: "center",
  },
  text: {
    textAlign: "center",
  },
  link: {
    textDecoration: "none",
    color: theme.palette.text.primary,
  },
}));

const Footer = () => {
  const classes = useStyles();

  const authUserId = useRecoilValue(authUserIdState);
  const setCreateReviewModalOpen = useSetRecoilState(
    createReviewModalOpenState
  );
  const setSignUpModalOpen = useSetRecoilState(signUpModalOpenState);

  return (
    <footer className={classes.footer}>
      <Grid
        container
        direction="row"
        justify="space-evenly"
        alignItems="center"
      >
        <Grid item xs></Grid>
        <Grid item xs>
          <Link to="/reviews" className={classes.link}>
            <Typography className={classes.text} variant="subtitle1" noWrap>
              Browse Reviews
            </Typography>
          </Link>
        </Grid>
        <Grid item xs>
          <Typography
            className={classes.text}
            style={{ cursor: "pointer" }}
            variant="subtitle1"
            noWrap
            onClick={
              authUserId
                ? () => setCreateReviewModalOpen(true)
                : () => setSignUpModalOpen(true)
            }
          >
            Post a Review
          </Typography>
        </Grid>
        <Grid item xs>
          <Link to="/brands" className={classes.link}>
            <Typography className={classes.text} variant="subtitle1" noWrap>
              Brands
            </Typography>
          </Link>
        </Grid>
        <Grid item xs>
          <Link to="/terms" className={classes.link}>
            <Typography className={classes.text} variant="subtitle1" noWrap>
              Terms or Use
            </Typography>
          </Link>
        </Grid>
        <Grid item xs>
          <Link to="/privacy-policy" className={classes.link}>
            <Typography className={classes.text} variant="subtitle1" noWrap>
              Privacy Policy
            </Typography>
          </Link>
        </Grid>
        <Grid item xs>
          <Link to="/contact-us" className={classes.link}>
            <Typography className={classes.text} variant="subtitle1" noWrap>
              Contact Us
            </Typography>
          </Link>
        </Grid>
        <Grid item xs></Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justify="space-evenly"
        alignItems="center"
        style={{ padding: "20px 0px" }}
      >
        <Grid item xs></Grid>
        <Grid item xs={2} sm={1} className={classes.icons}>
          <a
            href="https://www.facebook.com/wewearreview"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className={classes.social} alt="Facebook" src={FacebookIcon} />
          </a>
        </Grid>
        <Grid item xs={2} sm={1} className={classes.icons}>
          <a
            href="https://www.instagram.com/wewearreview"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className={classes.social}
              alt="Instagram"
              src={InstagramIcon}
            />
          </a>
        </Grid>
        <Grid item xs={2} sm={1} className={classes.icons}>
          <a
            href="https://twitter.com/wewearreview"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className={classes.social} alt="Twitter" src={TwitterIcon} />
          </a>
        </Grid>
        <Grid item xs></Grid>
      </Grid>

      <Typography variant="subtitle1" align="center" component="p">
        {" © "} WeWear {new Date().getFullYear()}. All rights reserved
      </Typography>
    </footer>
  );
};

export default Footer;
