import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, TextField, Typography } from "@material-ui/core";
import BrandForm from "./BrandForm";
import BrandTable from "./BrandTable";
import SuggestedBrandTable from "./SuggestedBrandTable";
import SearchIcon from "@material-ui/icons/Search";
import Loading from "../../components/Loading/Loading";

import { API, graphqlOperation, Storage } from "aws-amplify";
import { listBrands, getBrandsAlphabetically } from "../../graphql/queries";
import { onCreateBrand } from "../../graphql/subscriptions";

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 600,
  },
  tableContainer: {
    margin: 8,
    width: "75%",
  },
  tableName: {
    padding: 8,
    margin: 8,
    fontWeight: 600,
  },
}));

const Brands = () => {
  const classes = useStyles();
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [brands, setBrands] = useState([]);

  const [nextToken, setNextToken] = useState(undefined);
  const [nextNextToken, setNextNextToken] = useState();
  const [previousTokens, setPreviousTokens] = useState([]);

  const fetchBrands = async () => {
    try {
      setLoading(true);

      const brandData = await API.graphql({
        query: getBrandsAlphabetically,
        authMode: "API_KEY",
        variables: {
          type: "Brand",
          sortDirection: "ASC",
          limit: 15,
          nextToken,
        },
      });
      setNextNextToken(brandData.data.getBrandsAlphabetically.nextToken);
      const brandsArray = brandData.data.getBrandsAlphabetically.items;
      const signedBrandsArray = await Promise.all(
        brandsArray.map(async (item) => {
          const signedUrl = await Storage.get(item.image);
          item.imageUrl = signedUrl;
          return item;
        })
      );

      setBrands(signedBrandsArray);
      setLoading(false);
    } catch (error) {
      console.log("error fetching brands:", error);
      setLoading(false);
    }
  };

  const fetchFilteredBrands = async () => {
    try {
      setLoading(true);

      const brandData = await API.graphql({
        query: listBrands,
        authMode: "API_KEY",
        variables: {
          filter: { name: { contains: search } },
          limit: 15,
          nextToken,
        },
      });
      setNextNextToken(brandData.data.listBrands.nextToken);
      const brandsArray = brandData.data.listBrands.items;
      const signedBrandsArray = await Promise.all(
        brandsArray.map(async (item) => {
          const signedUrl = await Storage.get(item.image);
          item.imageUrl = signedUrl;
          return item;
        })
      );

      setBrands(signedBrandsArray);
      setLoading(false);
    } catch (error) {
      console.log("error fetching brands:", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (search) {
      fetchFilteredBrands();
    } else {
      fetchBrands();
    }

    const subscription = API.graphql(graphqlOperation(onCreateBrand)).subscribe(
      {
        next: async (brand) => {
          const newBrand = brand.value.data.onCreateBrand;
          const signedUrl = await Storage.get(newBrand.image);
          newBrand.imageUrl = signedUrl;
          setBrands((prevState) => [...prevState, newBrand]);
        },
      }
    );
    return () => subscription.unsubscribe();
  }, [nextToken, search]);

  return (
    <Grid container direction="column" justify="center" alignItems="center">
      <Grid container item justify="space-between" alignItems="center">
        <Grid container item justify="flex-start" alignItems="center" xs={6}>
          <Grid item>
            <Typography variant="h5" className={classes.title}>
              Brands
            </Typography>
          </Grid>
          <Grid item>
            <BrandForm />
          </Grid>
        </Grid>

        <Grid container item justify="flex-end" alignItems="center" xs={6}>
          <TextField
            id="search"
            InputProps={{
              startAdornment: <SearchIcon style={{ color: "#D1D1D6" }} />,
            }}
            variant="outlined"
            margin="dense"
            placeholder="Search"
            autoComplete="off"
            value={search}
            onChange={(event) => setSearch(event.target.value)}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.tableContainer}>
        <Paper>
          <Typography className={classes.tableName} variant="subtitle1">
            Recently Added
          </Typography>
          <SuggestedBrandTable />
        </Paper>
      </Grid>
      <Grid item xs={12} className={classes.tableContainer}>
        <Paper>
          <Typography className={classes.tableName} variant="subtitle1">
            Approved
          </Typography>
          {loading ? (
            <Loading />
          ) : (
            <BrandTable
              brands={brands}
              setBrands={setBrands}
              loading={loading}
              nextToken={nextToken}
              setNextToken={setNextToken}
              nextNextToken={nextNextToken}
              setNextNextToken={setNextNextToken}
              previousTokens={previousTokens}
              setPreviousTokens={setPreviousTokens}
            />
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Brands;
