import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import MuiPhoneNumber from "material-ui-phone-number";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { subYears } from "date-fns";
import { useDropzone } from "react-dropzone";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { useSnackbar } from "notistack";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import Heading from "../../components/Heading/Heading";

import { useRecoilValue } from "recoil";
import { authUserIdState } from "../../recoil/atoms";

const useStyles = makeStyles(() => ({
  field: { padding: 16 },
  image: {
    height: 148,
    width: 148,
    borderRadius: "50%",
  },
}));

const ProfileForm = ({
  profilePicture,
  setProfilePicture,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  email,
  setEmail,
  password,
  setPassword,
  phone,
  setPhone,
  userLocation,
  setUserLocation,
  userBirthday,
  setUserBirthday,
  bio,
  setBio,
  acceptTerms,
  setAcceptTerms,
  signedProfilePic,
  files,
  setFiles,
  pictureFile,
  setPictureFile,
  validation,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const authUserId = useRecoilValue(authUserIdState);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 1) {
        enqueueSnackbar(
          "Only one image can be used for your profile.  Please choose one image and try again.",
          {
            variant: "error",
          }
        );
        return;
      }
      setPictureFile(acceptedFiles[0]);
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  return (
    <Grid
      container
      item
      xs={10}
      direction="column"
      justify="center"
      alignItems="center"
    >
      <Grid container justify="center" alignItems="center">
        <Grid item xs={12}>
          <Heading>Save your information by creating a profile:</Heading>
        </Grid>

        <Grid container justify="center" alignItems="center" item xs={12}>
          <Grid
            item
            container
            direction="column"
            justify="center"
            alignItems="center"
            style={{
              height: "150px",
              width: "150px",
              alignItems: "center",
              borderRadius: "50%",
              border: "1px solid black",
              cursor: "pointer",
            }}
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            <Grid item container justify="center" alignItems="center">
              {files.map((file) => (
                <Grid
                  item
                  style={{
                    margin: 4,
                    width: 150,
                    height: 150,
                    padding: 4,
                  }}
                  key={file.name}
                >
                  <img
                    alt="preview"
                    src={file.preview}
                    style={{
                      display: "block",
                      width: "auto",
                      height: "100%",
                      borderRadius: "50%",
                    }}
                  />
                </Grid>
              ))}
            </Grid>

            <Grid item container>
              {!profilePicture
                ? files.length === 0 && (
                    <Grid
                      item
                      container
                      direction="column"
                      justify="center"
                      alignItems="center"
                    >
                      <CloudUploadIcon />
                      <Typography variant="subtitle2">Upload</Typography>
                    </Grid>
                  )
                : files.length === 0 && (
                    <img
                      className={classes.image}
                      src={signedProfilePic}
                      alt={`${firstName} ${lastName}`}
                    />
                  )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.field}>
          <TextField
            fullWidth
            variant="outlined"
            id="FirstName"
            name="FirstName"
            label="First Name"
            autoComplete="off"
            value={firstName}
            onChange={(event) => setFirstName(event.target.value)}
            error={validation?.firstName?.length > 0 ? true : false}
            helperText={validation?.firstName || ""}
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.field}>
          <TextField
            disabled={authUserId ? true : false}
            fullWidth
            variant="outlined"
            id="Email"
            name="Email"
            label="Email Address"
            autoComplete="off"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            error={validation?.email?.length > 0 ? true : false}
            helperText={validation?.email || ""}
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.field}>
          <TextField
            fullWidth
            variant="outlined"
            id="LastName"
            name="LastName"
            label="Last Name"
            autoComplete="off"
            value={lastName}
            onChange={(event) => setLastName(event.target.value)}
            error={validation?.lastName?.length > 0 ? true : false}
            helperText={validation?.lastName || ""}
          />
        </Grid>
        {!authUserId && (
          <Grid item xs={12} sm={6} className={classes.field}>
            <TextField
              disabled={authUserId ? true : false}
              fullWidth
              variant="outlined"
              id="Password"
              name="Password"
              label="Password"
              autoComplete="off"
              type="password"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              error={!!validation?.password}
              helperText={validation?.password || ""}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={6} className={classes.field}>
          <TextField
            fullWidth
            variant="outlined"
            id="UserLocation"
            name="UserLocation"
            label="Location"
            autoComplete="off"
            value={userLocation}
            onChange={(event) => setUserLocation(event.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.field}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              disableFuture
              fullWidth
              initialFocusedDate={subYears(new Date(), 13)}
              error={!!validation?.userBirthday}
              helperText={validation?.userBirthday || ""}
              autoComplete="off"
              variant="inline"
              inputVariant="outlined"
              invalidDateMessage={false}
              format="MM/dd/yyyy"
              openTo="year"
              views={["year", "month", "date"]}
              label="Birthday"
              value={userBirthday}
              onChange={(date) => setUserBirthday(date)}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.field}>
          <MuiPhoneNumber
            fullWidth
            variant="outlined"
            id="Phone"
            name="Phone"
            label="Phone Number"
            defaultCountry={"us"}
            value={phone}
            onChange={(event) => setPhone(event)}
          />
        </Grid>
        <Grid item xs={12} sm={12} className={classes.field}>
          <TextField
            fullWidth
            variant="outlined"
            id="Bio"
            name="Bio"
            label="Bio"
            autoComplete="off"
            value={bio}
            onChange={(event) => setBio(event.target.value)}
          />
        </Grid>
        <Grid
          container
          justify="center"
          alignItems="center"
          item
          xs={12}
          sm={12}
          className={classes.field}
        >
          <FormControl
            required
            error={!!validation?.acceptTerms}
            component="fieldset"
            className={classes.formControl}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={acceptTerms}
                  onChange={() => setAcceptTerms(!acceptTerms)}
                  name="acceptTerms"
                  color="primary"
                />
              }
              label="You Accept Our Terms of Use & Privacy Policy Agreements"
            />
            <FormHelperText>{validation?.acceptTerms || ""}</FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProfileForm;
