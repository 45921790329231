import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, TextField, Typography } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import Heading from "../../components/Heading/Heading";
import ReviewsTable from "../../components/ReviewsTable/ReviewsTable";
import Loading from "../../components/Loading/Loading";
import reviewsEmpty from "../../assets/images/empty/reviews-empty@2x.png";

// Icons
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

// Recoil
import { useRecoilState, useRecoilValue } from "recoil";
import { reviewSearchState, authUserIdState } from "../../recoil/atoms";

// Amplify
import { API, Storage } from "aws-amplify";
import { listReviewsByCreatedAt } from "../../graphql/queries";

const useStyles = makeStyles((theme) => ({
  headingContainer: {
    paddingLeft: "35px",
  },
  subtitle: {
    marginBottom: 25,
  },
  emptyContainer: {
    padding: theme.spacing(7),
  },
  emptyText: {
    textAlign: "center",
  },
  emptyImage: {
    height: 125,
  },
  link: {
    color: "#DFC6BF",
  },
}));

const ReviewList = () => {
  const classes = useStyles();
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(false);
  const authUserId = useRecoilValue(authUserIdState);
  const [search, setSearch] = useRecoilState(reviewSearchState);
  const [debouncedSearch, setDebouncedSearch] = useState(search);

  const [nextToken, setNextToken] = useState(undefined);
  const [nextNextToken, setNextNextToken] = useState();
  const [previousTokens, setPreviousTokens] = useState([]);

  const handleNext = () => {
    setPreviousTokens((prev) => [...prev, nextToken]);
    setNextToken(nextNextToken);
    setNextNextToken(null);
  };

  const handlePrev = () => {
    setNextToken(previousTokens.pop());
    setPreviousTokens([...previousTokens]);
    setNextNextToken(null);
  };

  useEffect(() => {
    const timerId = setTimeout(() => {
      setNextToken(undefined);
      setNextNextToken(null);
      setPreviousTokens([]);
      setDebouncedSearch(search);
    }, 700);
    return () => {
      clearTimeout(timerId);
    };
  }, [search]);

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        setLoading(true);

        let variables = {
          type: "Review",
          limit: 40,
          sortDirection: "DESC",
          nextToken,
        };

        if (debouncedSearch) {
          variables.filter = {
            searchField: { contains: debouncedSearch.toLowerCase() },
          };
        }
        const reviewData = await API.graphql({
          query: listReviewsByCreatedAt,
          authMode: "API_KEY",
          variables,
        });
        setNextNextToken(reviewData.data.listReviewsByCreatedAt.nextToken);
        const reviewsArray = reviewData.data.listReviewsByCreatedAt.items;
        const signedReviewsArray = await Promise.all(
          reviewsArray.map(async (item) => {
            const signedUrls = await Promise.all(
              item.garmentImages.map(async (image) => {
                const signedUrl = await Storage.get(image);
                return signedUrl;
              })
            );
            item.imageUrls = signedUrls;
            if (item.author.profilePicture) {
              const signedProfilePicture = await Storage.get(
                item.author.profilePicture
              );
              item.author.profilePictureUrl = signedProfilePicture;
            }
            return item;
          })
        );

        setReviews(signedReviewsArray);
        setLoading(false);
      } catch (error) {
        console.log("error fetching reviews:", error);
        setLoading(false);
      }
    };
    fetchReviews();
  }, [nextToken, debouncedSearch]);

  return (
    <div>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="flex-start"
        className={classes.headingContainer}
      >
        <Grid item xs={12}>
          <Heading>Browse Reviews</Heading>
        </Grid>
        {!authUserId && (
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              Want to see reviews personalized to you? Click{" "}
              <span>
                <Link
                  to={{
                    pathname: "/login",
                    state: {
                      formType: "signUp",
                    },
                  }}
                  className={classes.link}
                >
                  here
                </Link>
              </span>{" "}
              to create a profile!
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} style={{ paddingTop: 10, paddingBottom: 20 }}>
          <TextField
            id="search"
            InputProps={{
              startAdornment: <SearchIcon style={{ color: "#D1D1D6" }} />,
            }}
            variant="outlined"
            margin="dense"
            placeholder="Search for your item"
            autoComplete="off"
            value={search}
            onChange={(event) => setSearch(event.target.value)}
          />
        </Grid>
      </Grid>
      {loading ? (
        <Loading />
      ) : reviews?.length > 0 ? (
        <ReviewsTable
          reviews={reviews}
          browse={true}
          handleNext={handleNext}
          nextNextToken={nextNextToken}
        />
      ) : (
        <Grid
          className={classes.emptyContainer}
          container
          direction="column"
          justify="center"
          alignItems="center"
        >
          <Grid item xs={12}>
            <img
              className={classes.emptyImage}
              src={reviewsEmpty}
              alt="Empty Reviews"
            />
          </Grid>
          <Grid item xs={12} style={{ paddingTop: 15 }}>
            <Typography className={classes.emptyText} variant="h5">
              No Reviews Found.
            </Typography>
          </Grid>
        </Grid>
      )}
      <Grid
        container
        justify="center"
        alignItems="center"
        style={{ padding: 16 }}
      >
        <Button
          disabled={previousTokens.length < 1 || loading}
          onClick={handlePrev}
        >
          <ArrowBackIosIcon />
        </Button>
        <Button
          disabled={nextNextToken === null || loading}
          onClick={handleNext}
        >
          <ArrowForwardIosIcon />
        </Button>
      </Grid>
    </div>
  );
};

export default ReviewList;
