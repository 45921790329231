import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Container,
  Grid,
  FormLabel,
  TextField,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    paddingLeft: 50,
    paddingRight: 50,
  },
}));

const ForgotPasswordSubmit = ({
  email,
  password,
  authCode,
  setPassword,
  setAuthCode,
  handleForgotPasswordSubmit,
  validation,
}) => {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Typography variant="h3">Forgot Password Reset</Typography>
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormLabel component="legend">Email Address</FormLabel>
              <TextField
                disabled
                fullWidth
                variant="outlined"
                margin="normal"
                name="email"
                id="email"
                autoFocus
                value={email}
                error={validation?.email?.length > 0 ? true : false}
                helperText={validation?.email || ""}
              />
            </Grid>
            <Grid item xs={12}>
              <FormLabel component="legend">Code</FormLabel>
              <TextField
                required
                fullWidth
                variant="outlined"
                margin="normal"
                name="authCode"
                id="authCode"
                autoFocus
                value={authCode}
                onChange={(event) => setAuthCode(event.target.value)}
                error={validation?.authCode?.length > 0 ? true : false}
                helperText={validation?.authCode || ""}
              />
            </Grid>
            <Grid item xs={12}>
              <FormLabel component="legend">New Password</FormLabel>
              <TextField
                required
                fullWidth
                variant="outlined"
                margin="normal"
                name="password"
                type="password"
                id="password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                error={validation?.password?.length > 0 ? true : false}
                helperText={validation?.password || ""}
              />
            </Grid>
            <Button
              type="submit"
              className={classes.submit}
              variant="outlined"
              onClick={handleForgotPasswordSubmit}
            >
              Reset Password
            </Button>
          </Grid>
        </form>
      </div>
    </Container>
  );
};

export default ForgotPasswordSubmit;
