import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import UserCard from "../../components/UserCard/UserCard";

const useStyles = makeStyles(() => ({
  userArea: {
    display: "flex",
    justifyContent: "space-around",
    flexWrap: "wrap",
  },
}));

const UsersTable = ({ users }) => {
  const classes = useStyles();

  return (
    <div className={classes.userArea}>
      {users?.map((user) => {
        return (
          <div key={user.id}>
            <UserCard user={user} />
          </div>
        );
      })}
    </div>
  );
};

export default UsersTable;
