const clothingTypeOptions = [
  { id: 1, name: "Dress" },
  { id: 2, name: "Pants" },
  { id: 3, name: "Tops" },
  { id: 4, name: "Activewear" },
  { id: 5, name: "Sweaters" },
  { id: 6, name: "Outerwear" },
  { id: 7, name: "Skirts" },
  { id: 8, name: "Gowns" },
  { id: 9, name: "Jumpsuits" },
  { id: 10, name: "Maternity" },
  { id: 11, name: "Shorts" },
  { id: 12, name: "Skirt" },
  { id: 13, name: "Swimwear" },
  { id: 14, name: "T-Shirt" },
  { id: 15, name: "Sweatshirt" },
  { id: 16, name: "Leggings" },
  { id: 17, name: "Bodysuit" },
  { id: 18, name: "Shirt" },
];

export default clothingTypeOptions;
