import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";

import CloudUploadIcon from "@material-ui/icons/CloudUpload";

import { useSnackbar } from "notistack";
import { useDropzone } from "react-dropzone";
import { v4 as uuid } from "uuid";

import Loading from "../../components/Loading/Loading";

import { API, graphqlOperation, Storage } from "aws-amplify";
import { createBrand } from "../../graphql/mutations";

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: "#F5F5F5",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },

  button: {
    margin: theme.spacing(3),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    fontWeight: "bold",
    fontSize: 16,
  },

  navItem: { fontWeight: 600, fontSize: "16px", cursor: "pointer" },
  error: {
    border: "1px red solid",
    borderRadius: "10px",
  },
  errorText: {
    color: "red",
  },
}));

const BrandForm = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);

  const [loading, setLoading] = useState(false);
  const [validation, setValidation] = useState({});

  const [name, setName] = useState("");
  const [image, setImage] = useState({});

  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setImage(acceptedFiles[0]);
      if (acceptedFiles.length > 1) {
        enqueueSnackbar(
          "Only one image can be used for a brand.  Please choose one image and try again.",
          {
            variant: "error",
          }
        );
        return;
      }
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setName("");
    setImage("");
    setValidation({});
    setFiles([]);
  };

  // Validate
  //==============================================================================

  const validate = () => {
    const validationErrors = {};

    if (!name) {
      validationErrors.name = "The brand name is required";
    }

    if (!image) {
      validationErrors.image = "An image is required";
    }

    if (Object.keys(validationErrors).length > 0) {
      setValidation(validationErrors);
      return false;
    }

    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!validate()) {
      setLoading(false);
      return;
    }

    try {
      const imageKey = uuid() + image.name.replace(/\s/g, "-").toLowerCase();
      await Storage.put(imageKey, image);
      const brandData = {
        name,
        image: imageKey,
        type: "Brand",
      };
      await API.graphql(graphqlOperation(createBrand, { input: brandData }));

      enqueueSnackbar("Beautiful!  A new brand has been created.", {
        variant: "success",
      });
      setValidation({});
      setLoading(false);
      handleClose();
    } catch (error) {
      setLoading(false);
      console.warn(error);
      setValidation({ error: error.message });
      enqueueSnackbar(
        "There was an error, please review your form and try again.",
        {
          variant: "error",
        }
      );
    }
  };

  return (
    <div>
      <Button
        style={{ margin: 8 }}
        variant="contained"
        color="primary"
        onClick={handleClickOpen}
      >
        + New
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Create A New Brand</DialogTitle>
        <DialogContent>
          <Grid
            item
            container
            direction="column"
            justify="center"
            alignItems="center"
            style={{
              height: "95%",
              alignItems: "center",
              borderRadius: "20px",
              border: "1px dashed #bdbdbd",
              cursor: "pointer",
            }}
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            <Grid item container justify="center" alignItems="center">
              {files.map((file) => (
                <Grid
                  item
                  style={{
                    margin: 4,
                    width: 100,
                    height: 100,
                    padding: 4,
                  }}
                  key={file.name}
                >
                  <img
                    alt="preview"
                    src={file.preview}
                    style={{
                      display: "block",
                      maxWidth: 100,
                      maxHeight: 100,
                      borderRadius: "5px",
                    }}
                  />
                </Grid>
              ))}
            </Grid>
            <Grid item container justify="center" alignItems="center">
              <CloudUploadIcon />
              <Typography variant="subtitle2">
                You can upload or drop your image here.
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              name="name"
              label="Brand Name"
              margin="normal"
              autoComplete="off"
              value={name}
              onChange={(event) => setName(event.target.value)}
              error={validation?.name?.length > 0 ? true : false}
              helperText={validation?.name || ""}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container justify="center">
            {loading ? (
              <Loading />
            ) : (
              <Button
                variant="outlined"
                onClick={handleSubmit}
                className={classes.button}
              >
                Create Brand
              </Button>
            )}
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default BrandForm;
