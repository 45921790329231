import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  title: { padding: theme.spacing(2) },
  paragraph: { padding: "20px 0" },
}));

const PrivacyPage = () => {
  const classes = useStyles();

  return (
    <div>
      <Grid container>
        <Grid item xs md></Grid>
        <Grid container direction="column" justify="center" item xs={10}>
          <Grid
            item
            container
            justify="center"
            alignItems="center"
            className={classes.title}
          >
            <Typography variant="h3" className={classes.h3}>
              Privacy Policy
            </Typography>
          </Grid>

          <p>
            <strong>Privacy and Cookie Statement</strong>
          </p>
          <p>Effective: 1 February 2021</p>
          <p>
            We know that sharing your personal information with us is based on
            trust. We take this seriously and are committed to ensuring that we
            respect your privacy when you visit our website or use our services.
            Please review this Statement carefully to learn about our privacy
            practices.
          </p>
          <p>
            We operate an online service that provides users with clothing item
            and clothing accessory reviews. In this Statement, these are
            collectively referred to as our Services.
          </p>
          <p>
            Our Services are owned and operated by WeWear LLC. By visiting our
            websites and related applications, whether on a computer, phone,
            tablet, or similar device (these are all referred to as a
            &ldquo;Device&rdquo;), you are accepting the practices described
            below. If you do not agree with this Policy, you must delete all
            cookies from your browser cache after visiting the Site and refrain
            from visiting or using the Site.
          </p>
          <p>Information Collected</p>
          <p>
            When you access or use our Services, we collect information from and
            about you to provide a more personalized and relevant experience.
            Some information we collect automatically. Other information we
            collect from different sources, including affiliated entities,
            business partners, and other independent third-party sources. When
            you use our Services by &ldquo;clicking-through&rdquo; from a
            third-party site or when you visit third-party sites via our
            Services, those third-party sites may share information with us
            about your use of their service. Information collected may include
            the following:
          </p>
          <ul>
            <li>
              Contact information, including name, phone number and postal and
              email addresses;
            </li>
            <li>User name and password;</li>
            <li>
              Profile information including sizes, favorite brands and styles;
            </li>
            <li>
              Photos, reviews, forum and social posts, and videos you may have
              provided to us
            </li>
            <li>Geolocation information;</li>
            <li>
              Device information, such as when you accessed our Services and
              information about the Device used (for example, IP address,
              software or internet browser used, preferred languages, unique
              Device identifiers and advertising identifiers);
            </li>
            <li>
              Online activity, including pages you have visited, content
              reviewed, and apps reviewed;
            </li>
            <li>Purchasing history; and</li>
            <li>
              Information about your clothing and style preferences and clothing
              items you may own
            </li>
          </ul>
          <p>
            We may also collect, in instances where you have provided it,
            information about other consumers, including their email address and
            other review-related information. If you are sharing information
            with us about other individuals, you must obtain their consent. You
            must obtain the consent of any other individuals if you are
            providing their data to us.
          </p>
          <p>
            In addition to the categories noted above, we may also collect
            location information if you have instructed your Device to send such
            information via the privacy settings on that Device, or, for
            example, if you have uploaded photos tagged with location
            information. We may use your location information directly and/or
            share your location with third parties. We collect and share
            location information in order to provide you with relevant content,
            to analyze your use of our Services, to improve our Services and to
            provide contextual advertising or recommendations. For example, we
            may use your location to show you reviews of clothing brands and
            clothing items.
          </p>
          <p>
            You can change the privacy settings of your Device at any time in
            order to turn off the functionality that collects and shares
            location information and/or the functionality to tag your photos
            with location information. However, turning off location-sharing may
            affect certain features we offer. If you have any questions about
            the privacy settings of your Device, we suggest you contact the
            manufacturer of your Device or your mobile service provider for
            help.
          </p>
          <p>
            Please see the section on cookies below for more information
            regarding the use of cookies and other technology described in this
            section, including regarding your choices relating to such
            technologies.
          </p>
          <p>Information Uses</p>
          <p>
            We are committed to providing you with relevant content on our
            Services and respect the data privacy laws of the different
            jurisdictions which we operate in. We use information about you so
            we can help you enjoy and use our Services, including in the
            following ways:
          </p>
          <ul>
            <li>
              Register and manage your account, including to allow you access to
              and use of our Services;
            </li>
            <li>Measure interest in and improve our Services;</li>
            <li>
              Notify you about special offers and products or services available
              from us, our affiliates, or our partners that may be of interest
              to you;
            </li>
            <li>Communicate with you;</li>
            <li>
              Enable communication between registered users and
              owners/representatives of listings on our Services;
            </li>
            <li>
              Enable us to publish your reviews, forum posts, photos, videos and
              other content;
            </li>
            <li>
              Customize your experience, including customizing the ads shown to
              you on our Services and across the internet;
            </li>
            <li>Make inferences about your interests or preferences;</li>
            <li>
              Provide you with an optimized experience and to group users of our
              Services based on, for example, usage and demographics;
            </li>
            <li>Respond to your questions and comments;</li>
            <li>Resolve disputes or troubleshoot problems;</li>
            <li>Prevent potentially prohibited or unlawful activities; and</li>
            <li>
              Enforce our terms of use and notices; and as otherwise described
              to you at the point of collection.
            </li>
          </ul>
          <p>
            Please note some information about you is required in order for us
            to provide you with relevant offerings from us, our affiliates, and
            our partners and to enable you to enjoy other benefits of being a
            registered user. Please review the &ldquo;Information Choices&rdquo;
            section below with respect to your choices related to the collection
            and use of your information.
          </p>
          <p>
            WeWear may aggregate collected information about our users in a form
            that does not allow users to be personally identified for the
            purpose of understanding our customer base and enhancing the
            services that we and our strategic partners and customers can
            provide you.
          </p>
          <p>
            WeWear will use personal information only in ways that are
            compatible with the purposes for which it was collected or
            subsequently authorized by the individual about whom the information
            pertains. WeWear will take reasonable steps to ensure that personal
            information is relevant to its intended use, accurate, complete, and
            current.
          </p>
          <p>&nbsp;</p>
          <p>Information Sharing</p>
          <p>
            In order to provide some of our Services, we may need to share
            information with certain other third parties, including our group of
            companies, in the following circumstances:
          </p>
          <ul>
            <li>
              <u>Business Partners</u>. We may share information about you, your
              Devices, and your use of our Services with our trusted business
              partners. For example, we may share information so that we can
              provide you with relevant content or in order to facilitate
              payment for products or services you may purchase on the websites
              of our partners. This sharing is generally pursuant to written
              agreements which include confidentiality, privacy, and security
              obligations; however, note that we do not control the privacy
              practices of these third-party business partners.
            </li>
            <li>
              <u>Social Media Sites</u>. When you use our Services and elect to
              share information with third-party social media sites, the
              information you share will be governed by the privacy policies of
              those social media sites and the privacy settings you have set
              with those social media websites.
            </li>
            <li>
              <u>Advertising Networks</u>. We sometimes collect and share
              information about your interests with advertising networks. Some
              of these companies are members of the&nbsp;
              <u>Network Advertising Initiative</u>, or the{" "}
              <u>Digital Advertising Alliance</u>, which offer a single location
              to opt-out of ad targeting from member companies.
            </li>
            <li>
              <u>Other third parties, such as referring websites.</u> Third
              parties may also assist us in collecting information by, for
              example, operating features of our website or facilitating the
              delivery of online advertising tailored to your interests. We may
              share audience segments and other information with third parties
              that use that information for tailored advertising to you.
            </li>
          </ul>
          <p>
            Third parties may only collect or access information as needed to
            perform their permitted functions.
          </p>
          <p>
            Certain Device operating system versions permit you to opt out of
            certain types of information sharing, including to certain
            advertising networks. Please check your Device settings if you want
            to limit such tracking.
          </p>
          <p>Information Choices</p>
          <p>
            You have options with respect to the collection and use of your
            information. You can access, update, and even close your account by
            visiting your account page on our website.
          </p>
          <p>Your options:</p>
          <ul>
            <li>
              You may choose the way we communicate with you. You can choose not
              to provide us with certain information, although it may be needed
              to take advantage of certain features offered on our websites.
            </li>
            <li>
              You can prevent the collection of geolocations information but
              note that turning off location sharing may affect certain features
              of our Services.
            </li>
            <li>
              You also can add or update information and close your account. If
              you close your account, we will deactivate your account and remove
              your profile information from active view. We may retain some
              information associated with your account (including past
              transactions) for internal purposes including backups, fraud
              prevention, dispute resolution, investigations, and legal
              compliance for the period necessary to fulfill the purposes
              outlined in this Statement.
            </li>
          </ul>
          <p>Information Security</p>
          <p>
            We have implemented appropriate administrative, technical, and
            physical security procedures to help protect your information. We
            only authorize specific employees to access personal information and
            they may do so only for permitted business functions. However, we
            cannot guarantee the security of information from unauthorized entry
            or use, hardware or software failure, or other circumstances outside
            of our control.
          </p>
          <p>Information Retention</p>
          <p>
            We will retain copies of your information for as long as you
            maintain your account or as necessary in connection with the
            purposes set out in this Statement unless applicable law requires a
            longer retention period. In addition, we may retain your information
            for the duration of any period necessary to establish, exercise or
            defend any legal rights.
          </p>
          <p>Cookies</p>
          <p>
            We want your access to our Services to be as easy, efficient, and
            useful as possible. To help us do this, we use cookies and similar
            technologies to improve your experience, to enhance site security
            and to show you relevant advertising.
          </p>
          <p>What are cookies?</p>
          <p>
            Cookies are small text files that are automatically placed on your
            Device when you visit almost any website. They are stored by your
            internet browser and contain basic information about your internet
            use. Your browser sends these cookies back to a website every time
            you revisit it, so it can recognize your Device and improve your
            experience by, among other things, providing you with personalized
            content. We also use cookies to remember your login details, so you
            do not have to re-enter them repeatedly when you use our Services.
            Other cookies help us understand your preferences.
          </p>
          <p>The cookies we use fall into three categories.</p>
          <p>Cookie Consent Tool</p>
          <p>
            To give you control, we provide you with a Cookie Consent Tool,
            which allows you to review the first party and third-party cookies
            placed through our website and adjust your cookie settings,
            including whether to accept such cookies or not.
          </p>
          <p>
            You can manage cookies through the settings of your internet
            browser. You can have the browser notify you when you receive a new
            cookie, delete individual cookies, or delete all cookies. Most
            advertising networks offer you a way to opt out of advertising
            cookies. If you delete your cookies, your access to some
            functionality and areas of our Services might be degraded or
            restricted.
          </p>
          <p>Statement Changes and Notification</p>
          <p>
            We may update this Statement in the future. If we believe any
            changes are material, we will let you know by doing one or more of
            the following: sending you a communication about the changes,
            placing a notice on the website and/or posting an updated Statement
            on the website. We will note at the top of this Statement when it
            was most recently updated. We encourage you to check back from time
            to time to review the most current version and to periodically
            review this Statement for the latest information on our privacy
            practices.
          </p>
          <p>
            <strong>California</strong>
          </p>
          <p>
            California Consumer Privacy Act Privacy Statement (&ldquo;CCPA
            Statement&rdquo;)
          </p>
          <p>
            This CCPA Statement is provided pursuant to the California Consumer
            Privacy Act (&ldquo;CCPA&rdquo;) and applies to California residents
            and supplements our overall Statement with additional disclosures
            and rights.
          </p>
          <p>Your rights under CCPA</p>
          <p>
            As of January 1, 2020, California law permits residents of
            California to request certain details about how their personal
            information is shared with third-parties or affiliated companies for
            direct marketing purposes.
          </p>
          <p>
            California residents may also take advantage of the following
            rights:
          </p>
          <ul>
            <li>
              You may request, up to two times each year, that we disclose to
              you the categories and specific pieces of personal information
              that we have collected about you, the categories of sources from
              which your personal information is collected, the business or
              commercial purpose for collecting your personal information, the
              categories of personal information that we disclosed for a
              business purpose, any categories of personal information about you
              that we sold, the categories of third-parties with whom we have
              shared your personal information, and the business or commercial
              purpose for selling your personal information, if applicable.
            </li>
            <li>
              You may request that we delete any personal information that we
              have collected from or about you. As described in more detail in
              our Statement, there are some reasons we will not be able to fully
              address your deletion request, such as if we need to complete a
              transaction for you, to detect and protect against fraudulent and
              illegal activity, to exercise our rights, or to comply with a
              legal obligation. You can also access, update, and remove your
              information by visiting your account page on our website.
            </li>
            <li>
              You may request to opt out of our sale of your personal
              information to third parties for their direct marketing purposes.
              This means that, if you opt out, going forward, we will not share
              your information with such third parties to use for their purposes
              unless you later direct us to do so.
            </li>
          </ul>
          <p>
            To take advantage of your disclosure and deletion rights, please
            contact us. We may need to verify your identity to enable us to
            process your request. We value your privacy and will not
            discriminate in response to your exercise of privacy rights. We will
            respond to your request within 45 days of receipt of your request,
            after proper verification, unless we need additional time, in which
            case we will let you know.
          </p>
          <p>Disclosures</p>
          <p>
            For purposes of compliance with the CCPA, in addition to the further
            details as described in the Statement, we make the following
            disclosures:
          </p>
          <ul>
            <li>
              We collect the following categories of personal information:&nbsp;{" "}
              <em>
                Identifiers/Contact Information, profile information, Internet
                or other electronic network activity information, geolocation,
                visual and audio information, and inferences drawn from the
                above.
              </em>
            </li>
            <li>
              We sell the following categories of personal information:&nbsp;{" "}
              <em>
                Identifiers/Contact Information, items reviewed, profile
                information, Internet or other electronic network activity
                information, and inferences drawn from the above.
              </em>
            </li>
            <li>
              We disclose the following categories of personal information for a
              business purpose:&nbsp;{" "}
              <em>
                Identifiers/Contact Information, commercial information,
                Internet or other electronic network activity information,
                geolocation, visual and audio information, and inferences drawn
                from the above.
              </em>
              &nbsp;
            </li>
          </ul>
        </Grid>
        <Grid item xs md></Grid>
      </Grid>
    </div>
  );
};

export default PrivacyPage;
