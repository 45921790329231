const styleOptions = [
  {
    id: 1,
    name: "Sophisticated",
    img: require("../assets/images/styleTypes/Sophisticated_Girl@2x.png"),
  },
  {
    id: 2,
    name: "Trendy",
    img: require("../assets/images/styleTypes/Trendy_Girl@2x.png"),
  },
  {
    id: 3,
    name: "Tomboy",
    img: require("../assets/images/styleTypes/Tomboy_Girl@2x.png"),
  },
  {
    id: 4,
    name: "Chic",
    img: require("../assets/images/styleTypes/Chic_Girl@2x.png"),
  },
  {
    id: 5,
    name: "Preppy",
    img: require("../assets/images/styleTypes/Preppy_Girl@2x.png"),
  },
  {
    id: 6,
    name: "Rocker",
    img: require("../assets/images/styleTypes/Rocker_Girl@2x.png"),
  },
  {
    id: 7,
    name: "Comfy",
    img: require("../assets/images/styleTypes/Comfy_Girl@2x.png"),
  },
  {
    id: 8,
    name: "Bohemian",
    img: require("../assets/images/styleTypes/Bohemian_Girl@2x.png"),
  },
  {
    id: 9,
    name: "Casual",
    img: require("../assets/images/styleTypes/Casual_Girl@2x.png"),
  },
];

export default styleOptions;
